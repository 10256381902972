/* 工作台 */
import React, {Component} from "react";
import {message, Button, Avatar, Form, Modal, Input, Checkbox} from "antd";
import {API, IconFont, imgRoot} from "../../../static/js/common";
import {UserOutlined} from '@ant-design/icons';
import {CopyToClipboard} from "react-copy-to-clipboard";
import moment from "moment";
import domtoimage from "dom-to-image";
import {upload} from "../../../static/js/upload";
import Auth from './auth'

const FormItem = Form.Item;

class Home extends Component {
  constructor(props) {
    super(props);
    this.closeAuth=this.closeAuth.bind(this);
    this.state = {
      inviteUrl: '',
      mobile: '',
      invite_data: {},
      remainAmount: 0,
      remainCommission: 0,
      showSettingVisible: false,
      phone: "",
      is_send: false,
      getcode: "获取验证码",
      isShowModal: false,
      notice: null,
      article_data: {},
      orderRights: 0,
      isShowOpen: false,
      loading: false,
      checkValue: [],

      isShowAuth: false,
      authInfo: {},
      authModalInfo: {},
      btnName: '',
      showAuthInfo: false,

      tName: '',
      tUrl: '',
      pName: '',
      pUrl: '',
      jName: '',
      jUrl: '',
      oName: '',
      oUrl: '',
      mName: '',
      mUrl: '',
      dName: '',
      dUrl: '',
      canUse: 2,
      isAuth: 1
    };
  }

  componentDidMount() {
    //每天只弹出一次
    let isShowModal = false;
    const todayDate = window.localStorage.getItem("TODAY-DATE");
    const todayNowDate = moment().format("YYYY-MM-DD");
    if (todayDate !== todayNowDate) {
      isShowModal = true;
      window.localStorage.setItem("TODAY-DATE", todayNowDate);
    }
    this.setState({ isShowModal });

    this.getUserNotice();
    this.getArticleInfo();
    this.getOrderLimits();

    API.getUserInvite({}).then((res) => {
      if (res.status * 1 === 0) {
        this.setState({inviteUrl: res.extData.inviteUrl});
      } else {
        message.error(res.message || "获取信息失败！");
      }
    });
    this.getUserAccount();
    this.getUserAuthInfo()

    if(this.getUrlParams('status')){
      this.setState({isShowAuth: !!parseInt(this.getUrlParams('status'))});
    }
  }

  getUrlParams = (name) => {
    const reg = new RegExp(`(^|&)${ name}=([^&]*)(&|$)`);
    const r = this.props.location.search.substr(1).match(reg);
    if (r != null) return  decodeURIComponent(r[2]); return null;
  };

  //获取用户下单权限
  getOrderLimits = () => {
    API.postUserLimit({}).then((res) => {
      if (res.status * 1 === 0) {
        this.setState({orderRights: parseInt(res.extData.orderRights)})
      } else {
        message.error(res.message);
      }
    });
  };

  //公告
  getUserNotice = () => {
    API.postUserNotice({}).then((res) => {
      if (res.status * 1 === 0) {
        if(res.extData && res.extData.length){
          this.setState({ notice: res.extData[0] });
        }
      } else {
        message.error(res.message);
      }
    });
  };

  getArticleInfo = () => {
    API.getHomeArticle({}).then((res) => {
      if (res.status * 1 === 0) {
        this.setState({ article_data: res.extData.length && res.extData[0] || {} });
      } else {
        message.error(res.message || "数据加载错误");
      }
    });
  };

  waitingVefiry = () => {
    // 获取验证码倒计时
    let countdown = 60;
    if (this.state.is_send) return;
    clearInterval(window._verify_timer);
    window._verify_timer = null;
    this.setState({
      is_send: true,
      getcode: `${countdown}s后重新获取`,
    });
    window._verify_timer = setInterval(() => {
      countdown--;
      let tips = `${countdown}s后重新获取`,
        send = true;
      if (countdown === 0) {
        clearInterval(window._verify_timer);
        tips = "获取验证码";
        send = false;
      }
      this.setState({getcode: tips, is_send: send});
    }, 1000);
  };

  getVerifyCode = () => {
    // 获取验证码
    const {phone, is_send} = this.state;
    if (is_send) return;
    const mobile = phone.replace(/\s/g, "");
    if (
      !/^(13[0-9]|14[0-9]|15[0-9]|16[0-9]|17[0-9]|18[0-9]|19[0-9])\d{8}$/.test(
        mobile
      )
    ) {
      message.error("手机号码输入有误！", 1);
      return;
    }
    API.getAuthSmsCode({
      mobile,
      sendType: "userForgotPwd"
    }).then((res) => {
      if (res.status * 1 === 0) {
        message.success("发送成功", 1.2);
        this.waitingVefiry();
      } else {
        message.error(res.message || "验证码发送失败！", 1.2);
        this.setState({
          is_send: false,
        });
      }
    });
  };

  onSubmit = (values) => {
    const {mobile, password, password2, verifyCode} = values;
    if (
      !/^(13[0-9]|14[0-9]|15[0-9]|16[0-9]|17[0-9]|18[0-9]|19[0-9])\d{8}$/.test(
        mobile
      )
    ) {
      message.error("请输入正确的手机号");
      return;
    }
    if (!/^([0-9A-Za-z]){6,20}$/.test(password)) {
      message.error("密码需为6-20位由字母数字组合");
      return;
    }
    if (password !== password2) {
      message.error("两次密码不一致");
      return;
    }
    API.getForget({mobile, password, password2, verifyCode}).then((res) => {
      if (res.status * 1 === 0) {
        message.success("修改成功", 1);
        this.setState({showSettingVisible: false, phone: ''})
      } else {
        message.error(res.message || "更改密码错误");
      }
    });
  };

  getUserAccount = () => {
    API.getRechargeAmount({}).then((res) => {
      if (res.status * 1 === 0) {
        const {mobile} = res.extData;
        this.setState({
          mobile,
          remainAmount: res.extData.remainAmount
            ? (res.extData.remainAmount / 100).toFixed(2)
            : (0).toFixed(2),
          remainCommission: res.extData.remainCommission
            ? (res.extData.remainCommission / 100).toFixed(2)
            : (0).toFixed(2),
        });
      } else {
        message.error(res.message || "数据加载错误");
      }
    });
  };
  // 342201199710269329
  // 15855307798
  getUserAuthInfo = () => {
    API.getAuthInfo({}).then((res) => {
      if (res.status * 1 === 0) {
        const data = res.extData;
        let name = "实名认证";
        if(!data.authPerson && !data.authStore && (data.enrollInfo && !data.enrollInfo.enrollId) && (data.storeInfo && !data.storeInfo.storeId)) {
          name = '实名认证'
        }
        else if(data.authPerson || data.authStore) {
          name =  '已实名'
        }
        else if(data.enrollInfo && data.enrollInfo.infoVerify === 1 && data.authType === 'person') {
          name =  '实名未完成'
        }
        else if(data.storeInfo && data.storeInfo.infoVerify === 1 && data.authType === 'store') {
          name =  '实名待审核'
        }
        else if(data.enrollInfo && data.enrollInfo.infoVerify === 3 && data.authType === 'person') {
          name =  '实名失败'
        }
        else if(data.storeInfo && data.storeInfo.infoVerify === 3 && data.authType === 'store') {
          name =  '实名失败'
        }
        this.setState({
          authInfo: data,
          btnName: name,
          canUse: parseInt(data.canUse),
          isAuth: parseInt(data.needRealName)
        });
      } else {
        message.error(res.message || "数据加载错误");
      }
    });
  };

  showOpenModal = () => {
    const { orderRights, authInfo } = this.state;
    if(parseInt(orderRights)) {
      message.warning("已开通权限")
    } else {
      if(!authInfo.authPerson && !authInfo.authStore) {
        message.warning("请先完成实名认证")
      }else{
        this.setState({isShowOpen: true})
      }
    }
  };

  showOpenAuth = () => {
    if (this.state.btnName === '实名认证') {
      this.setState({isShowAuth: true})
    } else {
      if(this.state.authInfo.authType === 'person') {
        this.setState({
          authModalInfo: this.state.authInfo.enrollInfo,
          showAuthInfo: true
        })
      } else if (this.state.authInfo.authType === 'store') {
        this.setState({
          authModalInfo: this.state.authInfo.storeInfo,
          showAuthInfo: true
        })
      }
    }
  };

  closeAuth = () => {
    this.getUserAuthInfo();
    this.setState({isShowAuth: false, showAuthInfo: false})
  };

  validateFields = () => {
    const {checkValue} = this.state;
    if(!checkValue.length) {
      message.warning("请勾选店铺类型");
      return false
    }
    // if(checkValue.findIndex(item => item==1)>-1) {
    //   if(!tName.trim() || !tUrl.trim()) {
    //     message.warning("请完善淘宝店铺信息");
    //     return false
    //   }
    // }
    // if(checkValue.findIndex(item => item==2)>-1) {
    //   if(!mName.trim() || !mUrl.trim()) {
    //     message.warning("请完善天猫店铺信息");
    //     return false
    //   }
    // }
    // if(checkValue.findIndex(item => item==3)>-1) {
    //   if(!jName.trim() || !jUrl.trim()) {
    //     message.warning("请完善京东店铺信息");
    //     return false
    //   }
    // }
    // if(checkValue.findIndex(item => item==4)>-1) {
    //   if(!pName.trim() || !pUrl.trim()) {
    //     message.warning("请完善拼多多店铺信息");
    //     return false
    //   }
    // }
    // if(checkValue.findIndex(item => item==5)>-1) {
    //   if(!dName.trim() || !dUrl.trim()) {
    //     message.warning("请完善抖店店铺信息");
    //     return false
    //   }
    // }
    // if(checkValue.findIndex(item => item==6)>-1) {
    //   if(!oName.trim() || !oUrl.trim()) {
    //     message.warning("请完善其他店铺信息");
    //     return false
    //   }
    // }
    return true
  };

  confirmOpenHandler = () => {
    const _this = this;
    const {checkValue} = this.state;
    if(!this.validateFields()){
      return;
    }
    if (window._hmt) {
      window._hmt.push(['_trackEvent', 'goodsconfirm', 'button', 'screenshot'])
    }
    this.setState({loading: true});
    let node = document.getElementById('root');
    domtoimage.toBlob(node, { bgColor: 'rgba(250,250,250)', width: 1800 })
      .then(function (blob) {
        const file = new window.File([blob], 'image', {type: 'png'});
        if (file) {
          upload(file, 'screenshot').then(res => {
            console.log(res, checkValue.join(','));
            _this.onSubmitShot(res.imgUrl);
          }, ()=>{
            message.error("开通失败，请联系客服");
            _this.setState({loading: false});
          });
        }else{
          message.error("开通失败，请联系客服");
          _this.setState({loading: false});
        }
      }, function () {
        message.error("开通失败，请联系客服");
        _this.setState({loading: false});
      });
  };

  onSubmitShot = (url) => {
    const {checkValue, tName, tUrl, pName, pUrl, jName, jUrl, oName, oUrl, mName, mUrl, dName, dUrl} = this.state;
    let shopName = [];
    let shopUrl = [];
    checkValue.forEach(item => {
      if(parseInt(item)===1) {
        shopName.push(tName)
        shopUrl.push(encodeURIComponent(tUrl))
      }else if(parseInt(item)===2) {
        shopName.push(mName)
        shopUrl.push(encodeURIComponent(mUrl))
      }else if(parseInt(item)===3) {
        shopName.push(jName)
        shopUrl.push(encodeURIComponent(jUrl))
      }else if(parseInt(item)===4) {
        shopName.push(pName)
        shopUrl.push(encodeURIComponent(pUrl))
      }else if(parseInt(item)===5) {
        shopName.push(dName)
        shopUrl.push(encodeURIComponent(dUrl))
      }else if(parseInt(item)===6) {
        shopName.push(oName)
        shopUrl.push(encodeURIComponent(oUrl))
      }
    })
    let params = {
      shopType: checkValue.join(','),
      screenshotUrl: url,
      shopName: '',
      shopUrl: ''
    }
    console.log(params)
    API.screenshot(params).then((res) => {
      if (res.status * 1 === 0) {
        this.setState({loading: false, isShowOpen: false});
        message.success('开通成功');
        this.getOrderLimits();
      } else {
        message.error(res.message);
        this.setState({loading: false});
      }
    });
  };

  onOrderDetail = (status) => {
    window.open('#/seller/order/orderlist?status=' + status, '_blank');
  };

  onParselDetail = (status) => {
    window.open('#/seller/order/parcellist?status=' + status, '_blank');
  };

  getContainer = () => {
    return this.container;
  };
  saveContainer = container => {
    this.container = container;
  };
  onCheckboxChange = (value) => {
    this.setState({checkValue: value});
  };

  changePddUrl = (val) => {
    let value = val;
    if(val.indexOf('%') > 0) {
      value = val.split('%')[0]
    }
    this.setState({pUrl: value})
  };

  render() {
    const {
      mobile,
      inviteUrl,
      remainAmount,
      remainCommission,
      showSettingVisible,
      getcode,
      phone,
      isShowModal,
      notice,
      article_data,
      isShowOpen,
      loading,
      checkValue,
      orderRights,
      isShowAuth,
      btnName,
      showAuthInfo,
      authModalInfo,
      authInfo,
      pUrl,
      canUse,
      isAuth
    } = this.state;

    const optionsShop = [
      {label: '淘宝', value: 1},
      {label: '天猫', value: 2},
      {label: '京东', value: 3},
      {label: '拼多多', value: 4},
      {label: '抖店', value: 5},
      {label: '其他', value: 6},
    ];

    const nowDate = moment().format("YYYY-MM-DD HH:mm:ss");

    const prefix_login = (
      <div className="prefix">
        <img alt="" src={`${imgRoot}/username.png`}/>
      </div>
    );
    const prefix_pwd = (
      <div className="prefix">
        <img alt="" src={`${imgRoot}/pwd.png`}/>
      </div>
    );
    return (
      <div ref={this.saveContainer}>
        {isShowAuth ? (
          <Auth closeAuth={this.closeAuth}/>
        ) : (
          <div>
        {article_data && article_data.articleId && (
          <div
            style={{marginBottom: '15px', color: 'red', fontSize: 16, cursor: 'pointer'}}
            onClick={()=>{this.setState({isShowModal: true, notice: article_data})}}>
            <span style={{color: '#262a33', fontWeight: 'bold'}}>
              <IconFont type="icon-notice" style={{marginRight: 3}}/>温馨提示：
            </span>
            <span style={{textDecoration: 'underline'}}>{article_data.title}</span>
          </div>
        )}

        <div style={{display: 'flex'}}>
          <div style={{width: '38%'}}>
            <div className="center_title">
              <div className="title_border"></div>
              个人信息
            </div>
            <div className="flex-start" style={{paddingLeft: 20}}>
              <Avatar size={55} icon={<UserOutlined/>}/>
              <div style={{marginLeft: 30, textAlign: "left"}}>
                <div style={{fontSize: 16, fontWeight: "bold"}}>{mobile}</div>
                <Button
                  type="primary"
                  size="small"
                  onClick={() => {
                    this.setState({showSettingVisible: true, phone: mobile})
                  }}
                  style={{marginTop: 20}}>
                  设置密码
                </Button>
                {!orderRights && (
                  <Button
                    type="primary"
                    size="small"
                    onClick={this.showOpenModal}
                    style={{marginTop: 20, marginLeft: 10}}>
                    自助开通
                  </Button>
                )}
                <Button
                  type="primary"
                  size="small"
                  onClick={this.showOpenAuth}
                  style={{marginTop: 20, marginLeft: 10}}>
                  {btnName}
                </Button>
              </div>
            </div>
          </div>
          <div style={{width: '27%'}}>
            <div className="center_title">
              <div className="title_border"></div>
              我的钱包
            </div>
            <div className="flex-space">
              <div>
                <div style={{fontSize: 20, fontWeight: "bold", marginBottom: 10}}>{remainAmount}</div>
                <span style={{opacity: '0.6'}}>账户余额(元)</span>
              </div>
              <div>
                <div style={{fontSize: 20, fontWeight: "bold", marginBottom: 10}}>{remainCommission}</div>
                <span style={{opacity: '0.6'}}>推广赚钱(元)</span>
              </div>
            </div>
            {canUse == 2 ? '' : (
              <Button
                type="primary"
                shape="round"
                onClick={() => {
                  if (window._hmt) {
                    window._hmt.push(['_trackEvent', 'center-home', 'button', 'recharge'])
                  }
                  window.open("/#/seller/finance/recharge", '_self');
                }}
                style={{marginTop: 30, marginRight: 60}}>
                充值
              </Button>
            )}
            <Button
              type="primary"
              shape="round"
              onClick={() => {
                if (window._hmt) {
                  window._hmt.push(['_trackEvent', 'center-home', 'button', 'withdraw'])
                }
                window.open("/#/seller/finance/withdraw", '_self');
              }}
              style={{marginTop: 30}}>
              提现
            </Button>
          </div>
        </div>

        <div className="center_title border-top">
          <div className="title_border"></div>
          订单信息
        </div>
        <div style={{display: 'flex', paddingLeft: 40}}>
          <div className="icon-wrap" onClick={() => this.onOrderDetail(1)}>
            <IconFont type="icon-daizhifu" style={{fontSize: 38, marginBottom: 14, marginTop: 3}}/>
            <div>待支付</div>
          </div>
          <div className="icon-wrap" style={{marginLeft: 80}} onClick={() => this.onOrderDetail(2)}>
            <IconFont type="icon-xinyongka" style={{fontSize: 40, marginBottom: 15}}/>
            <div>已支付</div>
          </div>
          <div className="icon-wrap" style={{marginLeft: 80}} onClick={() => this.onOrderDetail(9)}>
            <IconFont type="icon-yiquxiao1" style={{fontSize: 35, marginBottom: 15, marginTop: 6}}/>
            <div>已取消</div>
          </div>
          <div className="icon-wrap" style={{marginLeft: 80}} onClick={() => this.onOrderDetail(0)}>
            <IconFont type="icon-dingdan" style={{fontSize: 38, marginBottom: 14, marginTop: 4}}/>
            <div>全部订单</div>
          </div>
        </div>

        <div className="center_title border-top">
          <div className="title_border"></div>
          包裹信息
        </div>
        <div style={{display: 'flex', paddingLeft: 40}}>
          <div className="icon-wrap" onClick={() => this.onParselDetail(2)}>
            <IconFont type="icon-shouhuo" style={{fontSize: 35, marginBottom: 15, marginTop: 6}}/>
            <div>待发货</div>
          </div>
          <div className="icon-wrap" style={{marginLeft: 80}} onClick={() => this.onParselDetail(3)}>
            <IconFont type="icon-yifahuo" style={{fontSize: 38, marginBottom: 14, marginTop: 3}}/>
            <div>已发货</div>
          </div>
          <div className="icon-wrap" style={{marginLeft: 80}} onClick={() => this.onParselDetail(9)}>
            <IconFont type="icon-yiquxiao" style={{fontSize: 35, marginBottom: 15, marginTop: 6}}/>
            <div>已取消</div>
          </div>
          <div className="icon-wrap" style={{marginLeft: 80}} onClick={() => this.onParselDetail(0)}>
            <IconFont type="icon-dingdan" style={{fontSize: 38, marginBottom: 14, marginTop: 4}}/>
            <div>全部包裹</div>
          </div>
        </div>

            {isAuth == 1 ? '' : (
              <div>
                <div className="center_title border-top">
                  <div className="title_border"></div>
                  推广链接
                </div>
                <div>
                  通过您的邀请链接注册的商家用户，每支付一单包裹，您可得到0.1元现金奖励，长期有效。
                </div>
                <div className="invite_url">
                  礼品批发商城，一件起发，多仓可选，发货成本低至1.75元/单
                  <span style={{margin: "0 20px"}}>{inviteUrl}</span>
                  <CopyToClipboard
                    text={`礼品批发商城，一件起发，多仓可选，发货成本低至1.75元/单 ${inviteUrl}`}
                    onCopy={() => message.success("成功复制邀请链接")}>
                    <Button>复制</Button>
                  </CopyToClipboard>
                </div>
              </div>
            )}
        <Modal
          title="设置密码"
          visible={showSettingVisible}
          centered
          width="400px"
          onCancel={() => this.setState({showSettingVisible: false, phone: ''})}
          footer={null}
          destroyOnClose>
          <div className="center_setting">
            <Form onFinish={this.onSubmit} initialValues={{ mobile: phone }}>
              <FormItem
                name="mobile"
                rules={[
                  {
                    required: true,
                    message: "请输入手机号",
                  },
                ]}>
                <Input
                  prefix={prefix_login}
                  placeholder="请输入手机号"
                  onChange={(e) =>
                    this.setState({phone: e.target.value})
                  }/>
              </FormItem>
              <FormItem
                name="verifyCode"
                rules={[
                  {
                    required: true,
                    message: "请输入验证码",
                  },
                ]}>
                <Input
                  placeholder="请输入验证码"
                  addonAfter={
                    <div
                      className="get_code"
                      onClick={this.getVerifyCode}>
                      {getcode}
                    </div>
                  }/>
              </FormItem>
              <FormItem
                name="password"
                rules={[
                  {
                    required: true,
                    message: "请输入密码",
                  },
                ]}>
                <Input
                  prefix={prefix_pwd}
                  type="password"
                  placeholder="请输入密码"/>
              </FormItem>
              <FormItem
                name="password2"
                rules={[
                  {
                    required: true,
                    message: "请输入密码",
                  },
                ]}>
                <Input
                  prefix={prefix_pwd}
                  type="password"
                  placeholder="再次输入密码"/>
              </FormItem>
              <FormItem style={{textAlign: 'center'}}>
                <Button className="btn" htmlType="submit">
                  保存
                </Button>
              </FormItem>
            </Form>
          </div>
        </Modal>
        <Modal
          className="modalNotice"
          title="公告提醒"
          visible={isShowModal && notice}
          centered
          width="1000px"
          onCancel={() => this.setState({ isShowModal: false})}
          footer={null}
          destroyOnClose>
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <div style={{fontSize: '18px', fontWeight: 600, flex: 1}}>{notice && notice.title}</div>
            <div style={{fontSize: '18px'}}>{notice && notice.publishTime.split('.')[0]}</div>
          </div>
          <div style={{height: '500px', overflow: 'auto', marginTop: '20px'}} className="notice_wrapper">
            <div
              dangerouslySetInnerHTML={{ __html: notice && notice.content }}
            />
          </div>
        </Modal>
        {/* 自动开通 */}
        <Modal
          width="600px"
          title="自助开通权限申请"
          getContainer={this.getContainer}
          visible={isShowOpen}
          confirmLoading={loading}
          onOk={(_) => this.confirmOpenHandler()}
          onCancel={(_) => this.setState({ isShowOpen: false })}
          okText="我已知晓并确认"
          cancelText="不同意"
        >
          <div style={{ textAlign: "left", fontSize: 15 }} id="my-modal">
            <div style={{ fontWeight: 600, marginBottom: 10 }}>
              水滴礼品平台用户使用须知：
            </div>
            <div>
              1.本站为正规礼品批发商城，类似1688平台，<span style={{color: 'red'}}>不提供信封、空包等违法服务，严禁用于刷单、洗钱行为！</span><br/>
              2.严禁张冠李戴，非法使用他人个人信息用于犯罪，<span style={{color: 'red'}}>一经发现，直接封号，</span>并保存记录协助公安机关调查！<br/>
              3.聊天时文明沟通，理性互动。<span style={{color: 'red'}}>请勿发"刷单/补单"等违法违禁词。一经发现直接清退。</span><br/>
              4.平台账户仅限于自己使用，<span style={{color: 'red'}}>严禁非法牟利注册/倒卖账号行为</span>，违规者双方均须承担相应法律责任！<br/>
              5.严禁通过平台获取快递单号后到第三方电商平台店铺进行退货退款行为，违规者直接封号且须承担相应法律责任！
            </div>
            <div style={{marginTop: 20}}>
              <p style={{fontWeight: 600}}>勾选店铺类型(可多选)：</p>
              <Checkbox.Group
                options={optionsShop}
                value={checkValue}
                onChange={this.onCheckboxChange}
              />
            </div>
            <div className="shop-box" style={{display: 'none'}}>
              {(checkValue.findIndex(item=>item==1)>-1) && (
                <div
                  className="shop_item flex-space"
                  style={{marginTop: '13px'}}>
                  <div>
                    <label style={{marginRight: '10px'}}>淘宝店铺名称:</label>
                    <Input
                      style={{width: '140px'}}
                      placeholder="请输入店铺名称"
                      onChange={(e) =>
                        this.setState({tName: e.target.value})
                      }/>
                  </div>
                  <div>
                    <label style={{marginRight: '10px'}}>淘宝店铺链接:</label>
                    <Input
                      style={{width: '180px'}}
                      placeholder="请输入店铺链接"
                      onChange={(e) =>
                        this.setState({tUrl: e.target.value})
                      }/>
                  </div>
                </div>
              )}
              {(checkValue.findIndex(item=>item==2)>-1) && (
                <div
                  className="shop_item flex-space"
                  style={{marginTop: '13px'}}>
                  <div>
                    <label style={{marginRight: '10px'}}>天猫店铺名称:</label>
                    <Input
                      style={{width: '140px'}}
                      placeholder="请输入店铺名称"
                      onChange={(e) =>
                        this.setState({mName: e.target.value})
                      }/>
                  </div>
                  <div>
                    <label style={{marginRight: '10px'}}>天猫店铺链接:</label>
                    <Input
                      style={{width: '180px'}}
                      placeholder="请输入店铺链接"
                      onChange={(e) =>
                        this.setState({mUrl: e.target.value})
                      }/>
                  </div>
                </div>
              )}
              {(checkValue.findIndex(item=>item==3)>-1) && (
                <div
                  className="shop_item flex-space"
                  style={{marginTop: '13px'}}>
                  <div>
                    <label style={{marginRight: '10px'}}>京东店铺名称:</label>
                    <Input
                      style={{width: '140px'}}
                      placeholder="请输入店铺名称"
                      onChange={(e) =>
                        this.setState({jName: e.target.value})
                      }/>
                  </div>
                  <div>
                    <label style={{marginRight: '10px'}}>京东店铺链接:</label>
                    <Input
                      style={{width: '180px'}}
                      placeholder="请输入店铺链接"
                      onChange={(e) =>
                        this.setState({jUrl: e.target.value})
                      }/>
                  </div>
                </div>
              )}
              {(checkValue.findIndex(item=>item==4)>-1) && (
                <div
                  className="shop_item flex-space"
                  style={{marginTop: '13px'}}>
                  <div>
                    <label style={{marginRight: '10px'}}>拼多多店铺名称:</label>
                    <Input
                      style={{width: '124px'}}
                      placeholder="请输入店铺名称"
                      onChange={(e) =>
                        this.setState({pName: e.target.value})
                      }/>
                  </div>
                  <div>
                    <label style={{marginRight: '10px'}}>拼多多店铺链接:</label>
                    <Input
                      value={pUrl}
                      style={{width: '180px'}}
                      placeholder="请输入店铺链接"
                      onChange={(e) =>
                        this.changePddUrl(e.target.value)
                      }/>
                  </div>
                </div>
              )}
              {(checkValue.findIndex(item=>item==5)>-1) && (
                <div
                  className="shop_item flex-space"
                  style={{marginTop: '13px'}}>
                  <div>
                    <label style={{marginRight: '10px'}}>抖店店铺名称:</label>
                    <Input
                      style={{width: '140px'}}
                      placeholder="请输入店铺名称"
                      onChange={(e) =>
                        this.setState({dName: e.target.value})
                      }/>
                  </div>
                  <div>
                    <label style={{marginRight: '10px'}}>抖店店铺链接:</label>
                    <Input
                      style={{width: '180px'}}
                      placeholder="请输入店铺链接"
                      onChange={(e) =>
                        this.setState({dUrl: e.target.value})
                      }/>
                  </div>
                </div>
              )}
              {(checkValue.findIndex(item=>item==6)>-1) && (
                <div
                  className="shop_item flex-space"
                  style={{marginTop: '13px'}}>
                  <div>
                    <label style={{marginRight: '10px'}}>其他店铺名称:</label>
                    <Input
                      style={{width: '140px'}}
                      placeholder="请输入店铺名称"
                      onChange={(e) =>
                        this.setState({oName: e.target.value})
                      }/>
                  </div>
                  <div>
                    <label style={{marginRight: '10px'}}>其他店铺链接:</label>
                    <Input
                      style={{width: '180px'}}
                      placeholder="请输入店铺链接"
                      onChange={(e) =>
                        this.setState({oUrl: e.target.value})
                      }/>
                  </div>
                </div>
              )}
            </div>
            <div style={{marginTop: 20}} className="flex-start">
              <div>要开通权限的账号：<span style={{color: 'red'}}>{mobile}</span></div> <div style={{marginLeft: 12}}>开通时间：<span style={{color: 'red'}}>{nowDate}</span></div>
            </div>
            <div style={{marginTop: 20}}>
              客户您好， 请问您是否可以遵守以上用户须知？(如果可以，我们将给您审核通过账户；如果不可以，我们将无法通过审核)
            </div>
          </div>
        </Modal>
            <Modal
              className="modalNotice"
              title="实名认证信息"
              visible={showAuthInfo}
              centered
              width="400px"
              onCancel={() => this.setState({ showAuthInfo: false})}
              footer={null}
              destroyOnClose>
              <div className="modal-bd">
                {authInfo.authType === 'person' && (
                  <div>
                    <div className="rename-item">
                      <label>姓名：</label>
                      <span>{authModalInfo.realName}</span>
                    </div>
                    <div className="rename-item">
                      <label>实名状态：</label>
                      <span>{authModalInfo.infoVerify === 2 ? '已实名' : (authModalInfo.infoVerify === 1 ? '未完成' : '实名失败')}</span>
                      {authModalInfo.infoVerify === 1 && (
                        <span style={{marginLeft: '6px',color: '#FE3459',cursor: 'pointer'}} onClick={() => {this.setState({isShowAuth: true})
                        }}>重新实名</span>)}
                    </div>
                    {authModalInfo.infoVerify === 3 && (
                      <div className="rename-item">
                        <label>失败原因：</label>
                        <span>{authModalInfo.remark}</span>
                        <span style={{marginLeft: '6px',color: '#FE3459',cursor: 'pointer'}} onClick={() => {this.setState({isShowAuth: true})
                        }}>重新实名</span>
                      </div>
                    )}
                    <div className="rename-item">
                      <label>实名时间：</label>
                      <span>{authModalInfo.updateTime}</span>
                    </div>
                  </div>
                )}
                {authInfo.authType === 'store' && (
                  <div>
                    <div className="rename-item">
                      <label>店铺名称：</label>
                      <span>{authModalInfo.shopNick}</span>
                    </div>
                    <div className="rename-item">
                      <label>店铺链接：</label>
                      {authModalInfo.shopUrl && (<a href={authModalInfo.shopUrl} target="_blank">查看</a>)}
                      {!authModalInfo.shopUrl && (<span>无</span>)}
                    </div>
                    <div className="rename-item">
                      <label>实名状态：</label>
                      <span>{authModalInfo.infoVerify === 2 ? '已实名' : (authModalInfo.infoVerify === 1 ? '待审核' : '实名失败')}</span>
                      {authInfo.realSms == 1 && (
                      <span style={{marginLeft: '10px',color: '#FE3459',cursor: 'pointer'}} onClick={() => {this.setState({isShowAuth: true})
                      }}>去个人实名</span>
                      )}
                    </div>
                    {authModalInfo.infoVerify === 3 && (
                      <div className="rename-item">
                        <label>失败原因：</label>
                        <span>{authModalInfo.remark}</span>
                        <span style={{marginLeft: '6px',color: '#FE3459',cursor: 'pointer'}} onClick={() => {this.setState({isShowAuth: true})
                        }}>重新实名</span>
                      </div>
                    )}
                    <div className="rename-item">
                      <label>实名时间：</label>
                      <span>{authModalInfo.updateTime}</span>
                    </div>
                  </div>
                )}
              </div>
            </Modal>
          </div>
          )}
      </div>
    );
  }
}

export default Home;
