/* 帮助中心-系统公告 */
import React, { Component } from "react";
import { message, Table, Button } from "antd";
import { API, history } from "../../../static/js/common";

const columns = [
  {
    title: "公告标题",
    dataIndex: "title",
    align: "center",
  },
  {
    title: "发布时间",
    dataIndex: "publishTime",
    align: "center",
  },
  {
    title: "操作",
    dataIndex: "articleId",
    align: "center",
    render: (e) => {
      return (
        <Button
          type="primary"
          onClick={(_) =>
            history.push({
              pathname: `/seller/help/noticeinfo?${e}`,
            })
          }
        >
          查看
        </Button>
      );
    },
  },
];

class Notice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      pageSize: 10,
      article_data: {},
    };
  }

  componentDidMount() {
    this.getArticleList();
  }

  getArticleList = () => {
    const { page, pageSize } = this.state;
    const reqData = { page, pageSize, type: 2 };
    API.postArticleList(reqData).then((res) => {
      if (res.status * 1 === 0) {
        this.setState({ article_data: res.extData || {} });
      } else {
        message.error(res.message || "数据加载错误");
      }
    });
  };

  render() {
    const { page, pageSize, article_data } = this.state;
    return (
      <div>
        <div className="recharge">发货公告</div>
        <div>
          <Table
            columns={columns}
            dataSource={article_data.list || []}
            rowKey="articleId"
            pagination={{
              current: page,
              pageSize,
              total: article_data.total * 1 || 0,
              showSizeChanger: false,
              onChange: (page) => {
                this.setState({ page }, (_) => {
                  this.getArticleList();
                });
              },
            }}
          />
        </div>
      </div>
    );
  }
}
export default Notice;
