/* 财务管理-赔付明细 */
import React, { Component } from "react";
import { Table, message, DatePicker, Select } from "antd";
import { API } from "../../../static/js/common";
import moment from "moment";

const { RangePicker } = DatePicker;

const { Option } = Select;

class Paylist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      pageSize: 10,
      type: 0, // 0全部 1打单超时 2揽收超时
      createStart: "",
      createEnd: "",
      pay_data: {},
    };
  }

  componentDidMount() {
    this.getPayList();
  }

  // 资金记录
  getPayList = () => {
    const { page, pageSize, type, createStart, createEnd } = this.state;
    const reqData = { page, pageSize, type };
    if (createStart && createEnd) {
      reqData.createStart = createStart;
      reqData.createEnd = createEnd;
    }
    API.postPaidList(reqData).then((res) => {
      if (res.status * 1 === 0) {
        this.setState({ pay_data: res.extData || {} });
      } else {
        message.error(res.message);
      }
    });
  };

  // 选择日期
  changeTime = (e) => {
    let { createStart, createEnd } = this.state;
    if (e) {
      createStart = moment(e[0]).format("YYYY-MM-DD HH:mm");
      createEnd = moment(e[1]).format("YYYY-MM-DD HH:mm");
    } else {
      createStart = "";
      createEnd = "";
    }
    this.setState({ createStart, createEnd }, (_) => this.getPayList());
  };

  render() {
    const { page, pageSize, type, pay_data } = this.state;

    const columns = [
      {
        title: "序号",
        dataIndex: "parcelId",
        align: "center",
      },
      {
        title: "平台编号",
        dataIndex: "parcelSn",
        align: "center",
      },
      {
        title: "包裹状态",
        dataIndex: "parcelStatus",
        align: "center",
        render: (e) => <div>{["", "", "待发货", "已发货"][e]}</div>,
      },
      {
        title: "快递公司",
        dataIndex: "courierName",
        align: "center",
      },
      {
        title: "物流单号",
        dataIndex: "courierSn",
        align: "center",
      },
      {
        title: "礼品名称",
        dataIndex: "goodsName",
        align: "center",
      },
      {
        title: "数量",
        dataIndex: "goodsNum",
        align: "center",
      },
      {
        title: "费用",
        dataIndex: "parcelFee",
        align: "center",
        render: (e) => {
          return <div>￥{(e / 100).toFixed(2)}</div>;
        },
      },
      {
        title: "下单时间",
        dataIndex: "createTime",
        align: "center",
      },
      {
        title: "赔付原因",
        dataIndex: "paidTypeStr",
        align: "center",
      },
      {
        title: "赔付金额",
        dataIndex: "paidAmount",
        align: "center",
        render: (e) => {
          return <div>￥{(e / 100).toFixed(2)}</div>;
        },
      },
    ];

    return (
      <div>
        <div style={{ fontSize: 16, fontWeight: 600, marginBottom: 20 }}>
          赔付明细
        </div>
        <div style={{ marginBottom: 20 }} className="flex-start">
          <div>
            <span style={{ marginRight: 10 }}>下单时间</span>
            <RangePicker onChange={(e) => this.changeTime(e)} showTime={{ format: 'HH:mm' }} format="YYYY-MM-DD HH:mm"/>
          </div>
          <div style={{ marginLeft: 20 }}>
            <span style={{ marginRight: 10 }}>赔付类型</span>
            <Select
              value={type}
              style={{ width: 150 }}
              onChange={(e) =>
                this.setState({ type: e }, (_) => this.getPayList())
              }
            >
              <Option value={0}>全部</Option>
              <Option value={1}>打单超时</Option>
              <Option value={2}>揽收超时</Option>
            </Select>
          </div>
        </div>
        <Table
          columns={columns}
          dataSource={pay_data.list || []}
          rowKey="parcelId"
          pagination={{
            current: page,
            pageSize,
            total: pay_data.total * 1 || 0,
            showSizeChanger: false,
            onChange: (page) => {
              this.setState({ page }, (_) => {
                this.getPayList();
              });
            },
          }}
        />
      </div>
    );
  }
}
export default Paylist;
