/* 帮助中心-我的反馈 */
import React, { Component } from "react";
import { message, Table, Modal } from "antd";
import { API } from "../../../static/js/common";
const { confirm } = Modal;

class Notice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      pageSize: 10,
      article_data: {},
    };
  }

  componentDidMount() {
    this.getArticleList();
  }

  getArticleList = () => {
    const { page, pageSize } = this.state;
    const reqData = { page, pageSize, status: 0 };
    API.getFeedList(reqData).then((res) => {
      if (res.status * 1 === 0) {
        this.setState({ article_data: res.extData || {} });
      } else {
        message.error(res.message || "数据加载错误");
      }
    });
  };

  showDelConfirm = (info) => {
    const self = this;
    confirm({
      content: '确认删除该条反馈申请记录吗？',
      onOk() {
        let reqData = {
          fid: info.id,
        };
        API.getDelFeedRequest(reqData).then((res) => {
          if (res.status * 1 === 0) {
            message.success("删除成功");
            self.getArticleList();
          } else {
            message.error(res.message || "数据加载错误");
          }
        });
      },
      onCancel() {
        //
      },
    });
  };

  render() {
    const { page, pageSize, article_data } = this.state;
    const columns = [
      {
        title: "序号",
        dataIndex: "id",
      },
      {
        title: "状态",
        dataIndex: "fkStatus",
        render: (e) => (
          <div style={{color: e === 1 ? '#f94a3d' : (e === 2 ? '#4BC600' : 'red')}}>
            {
              ["", "待审核", "已处理"][e]
            }
          </div>
        ),
      },
      {
        title: "反馈类型",
        dataIndex: "type",
        render: (e) => (
          <div>
            {
              ["", "bug反馈", "功能建议", "投诉客服", "订单问题", "其它"][e]
            }
          </div>
        ),
      },
      {
        title: "反馈描述",
        dataIndex: "content",
        width: 180,
        ellipsis: true,
      },
      {
        title: "附件",
        dataIndex: "attach",
        align: 'center',
        render: (e) => {
          return <div>
            {e ? (
              <a href={e} target="_blank">
                <img src={e.split(',')[0]}
                     style={{width: '60px', height: '60px', cursor: 'pointer', objectFit: 'cover'}}/>
              </a>
            ) : ""}
          </div>;
        },
      },
      {
        title: "申请时间",
        dataIndex: "createTime",
        align: 'center',
        width: 120
      },
      {
        title: "处理结果",
        dataIndex: "deal",
        width: 180,
        ellipsis: true,
      },
      {
        title: "处理时间",
        dataIndex: "updateTime",
        align: 'center',
      },
      {
        title: "操作",
        dataIndex: "articleId",
        align: "center",
        render: (e, record) => {
          return (
            <span style={{color:'#1890FF', cursor: 'pointer'}} onClick={() => this.showDelConfirm(record)}>删除</span>
          );
        },
      },
    ];
    return (
      <div>
        <div className="recharge">我的反馈</div>
        <div>
          <Table
            columns={columns}
            dataSource={article_data.list || []}
            rowKey="id"
            pagination={{
              current: page,
              pageSize,
              total: article_data.total * 1 || 0,
              showSizeChanger: false,
              onChange: (page) => {
                this.setState({ page }, (_) => {
                  this.getArticleList();
                });
              },
            }}
          />
        </div>
      </div>
    );
  }
}
export default Notice;
