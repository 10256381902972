/* 左边菜单栏 */
import React, {Component} from "react";
import {Link} from "react-router-dom";
import {IconFont} from "../static/js/common";

const tabs = [
  {
    title: "工作台",
    path: "/seller/account/home",
    icon: "icon-gongzuotai",
  },
  {
    title: "订单管理",
    sub_tab: [
      {sub_title: "订单列表", path: "/seller/order/orderlist"},
      {sub_title: "包裹列表", path: "/seller/order/parcellist"},
      {sub_title: "退单列表", path: "/seller/order/cancellist"},
    ],
    icon: "icon-logooo_huabanfuben",
  },
  {
    title: "财务管理",
    sub_tab: [
      {sub_title: "我要充值", path: "/seller/finance/recharge"},
      {sub_title: "我要提现", path: "/seller/finance/withdraw"},
      {sub_title: "资金明细", path: "/seller/finance/detail"},
      {sub_title: "赔付明细", path: "/seller/finance/paylist"},
    ],
    icon: "icon-caiwuguanli",
  },
  {
    title: "推广赚钱",
    sub_tab: [{sub_title: "推广赚钱", path: "/seller/promote/invite"}],
    icon: "icon-fenxiang",
  },
  {
    title: "售后服务",
    sub_tab: [
      {sub_title: "售后查件", path: "/seller/service/search"},
      {sub_title: "底单申请", path: "/seller/service/request"},
    ],
    icon: "icon-a-setting",
  },
  {
    title: "帮助中心",
    sub_tab: [
      {sub_title: "发货公告", path: "/seller/help/notice"},
      {sub_title: "我的反馈", path: "/seller/help/feedback"},
      // {sub_title: "常见问题", path: "/seller/help/question"},
    ],
    icon: "icon-bangzhu",
  },
];

const sellerSystemHoc = (WrappedComponent, path) => {
  return class SellerSystem extends Component {
    render() {
      // const pathname = this.props.location.pathname;
      return (
        <div className="seller">
          <div className="seller_tab">
            {/*
            <div className="seller_tab_header">
              <div style={{ margin: "20px 0 10px" }}>商家后台</div>
               <div
                className="seller_tab_button"
                onClick={(_) =>
                  pathname !== "/seller/goods/goodsinfo" &&
                  this.props.history.push("/seller/goods/goodsinfo")
                }
              >
                一键发货
              </div>
            </div>*/}
            {tabs.map((val, key) => (
              <div key={key} className="seller_tab_sub">
                <div className="flex-start seller_tab_title">
                  <IconFont
                    type={val.icon}
                    style={{fontSize: 20, color: "#333333", marginRight: 5}}
                  />
                  {key === 0 ? (
                      <Link to={val.path}>
                        <div
                          className={`seller_title ${
                            path === val.path ? "seller_active_tab" : ""
                            }`}>
                          {val.title}
                        </div>
                      </Link>
                    )
                    : <span>{val.title}</span>}
                </div>
                {val.sub_tab && val.sub_tab.length > 0 &&
                val.sub_tab.map((item) => (
                  <Link to={item.path} key={item.path}>
                    <div
                      className={`seller_sub_title ${
                        path === item.path ? "seller_active_tab" : ""
                        }`}
                    >
                      {item.sub_title}
                    </div>
                  </Link>
                ))}
              </div>
            ))}
          </div>
          <div className="seller_box">
            <WrappedComponent {...this.props} />
          </div>
        </div>
      );
    }
  };
};

export default sellerSystemHoc;
