import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Form, Button, Input, message } from "antd";
import { API, history, setCookie, imgRoot } from "../../static/js/common";

const FormItem = Form.Item;
class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      phone: "",
      is_send: false,
      getcode: "获取验证码",
      inviteCode: "",
    };
  }

  componentDidMount() {
    const str = window.location.search;
    if (str.indexOf("inviteCode") !== -1) {
      const inviteCode = str.split("=")[1];
      this.setState({ inviteCode });
      setCookie("dtsCode", inviteCode, ".waxiang.com");
    }
  }

  waitingVefiry = () => {
    // 获取验证码倒计时
    let countdown = 60;
    if (this.state.is_send) return;
    clearInterval(window._verify_timer);
    window._verify_timer = null;
    this.setState({
      is_send: true,
      getcode: `${countdown}s后重新获取`,
    });
    window._verify_timer = setInterval(() => {
      countdown--;
      let tips = `${countdown}s后重新获取`,
        send = true;
      if (countdown === 0) {
        clearInterval(window._verify_timer);
        tips = "获取验证码";
        send = false;
      }
      this.setState({ getcode: tips, is_send: send });
    }, 1000);
  };

  getVerifyCode = () => {
    // 获取验证码
    const { phone, is_send } = this.state;
    if (is_send) return;
    const mobile = phone.replace(/\s/g, "");
    if (
      !/^(13[0-9]|14[0-9]|15[0-9]|16[0-9]|17[0-9]|18[0-9]|19[0-9])\d{8}$/.test(
        mobile
      )
    ) {
      message.error("手机号码输入有误！", 1.2);
      return;
    }
    API.getAuthSmsCode({
      mobile,
      sendType: "userRegister",
    }).then((res) => {
      if (res.status * 1 === 0) {
        message.success("发送成功", 1.2);
        this.waitingVefiry();
      } else {
        message.error(res.message || "验证码发送失败！", 1.2);
        this.setState({
          is_send: false,
        });
      }
    });
  };

  onSubmit = (values) => {
    const { mobile, password, password2, verifyCode } = values;
    const fr  = window.localStorage.getItem("from-origin");
    if (
      !/^(13[0-9]|14[0-9]|15[0-9]|16[0-9]|17[0-9]|18[0-9]|19[0-9])\d{8}$/.test(
        mobile
      )
    ) {
      message.error("请输入正确的手机号");
      return;
    }
    if (!/^([0-9A-Za-z]){6,20}$/.test(password)) {
      message.error("请输入正确的密码");
      return;
    }
    if (password !== password2) {
      message.error("两次密码不一致");
      return;
    }
    const reqData = { mobile, password, captcha: verifyCode, channel: 2 , fr};
    API.getRegister(reqData).then((res) => {
      if (res.status * 1 === 0) {
        history.push("/?tabKey=all");
        window.localStorage.setItem("slpToken", res.extData.token);
      } else {
        message.error(res.message || "注册错误");
      }
    });
  };

  render() {
    const { getcode } = this.state;
    const prefix_login = (
      <div className="prefix">
        <img alt="" src={`${imgRoot}/username.png`} />
      </div>
    );
    const prefix_pwd = (
      <div className="prefix">
        <img alt="" src={`${imgRoot}/pwd.png`} />
      </div>
    );
    return (
      <div className="login">
        <main className="login_bg">
          {/* <img
            alt=""
            width="100%"
            style={{ zIndex: -1 }}
            src={`${imgRoot}/background.jpg`}
          /> */}
          <div className="background_color"></div>
          <div className="log_box">
            <div style={{ position: "relative" }}>
              <div className="log_content">
                <img width="660" alt="" src={`${imgRoot}/login1.png`} />
                <div className="login_box1">
                  <p className="login_text">注册</p>
                  <Form onFinish={this.onSubmit}>
                    <FormItem
                      name="mobile"
                      rules={[
                        {
                          required: true,
                          message: "请输入手机号",
                        },
                      ]}
                    >
                      <Input
                        prefix={prefix_login}
                        placeholder="请输入手机号"
                        onChange={(e) =>
                          this.setState({ phone: e.target.value })
                        }
                      />
                    </FormItem>
                    <FormItem
                      name="verifyCode"
                      rules={[
                        {
                          required: true,
                          message: "请输入验证码",
                        },
                      ]}
                    >
                      <Input
                        placeholder="请输入验证码"
                        addonAfter={
                          <div
                            className="get_code"
                            onClick={this.getVerifyCode}
                          >
                            {getcode}
                          </div>
                        }
                      />
                    </FormItem>
                    <FormItem
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: "请输入密码",
                        },
                      ]}
                    >
                      <Input
                        prefix={prefix_pwd}
                        type="password"
                        placeholder="请输入密码"
                      />
                    </FormItem>
                    <FormItem
                      name="password2"
                      rules={[
                        {
                          required: true,
                          message: "请输入密码",
                        },
                      ]}
                    >
                      <Input
                        prefix={prefix_pwd}
                        type="password"
                        placeholder="再次输入密码"
                      />
                    </FormItem>
                    <FormItem>
                      <Button className="btn" htmlType="submit">
                        注册
                      </Button>
                    </FormItem>
                  </Form>
                  <div className="flex-space">
                    <div></div>
                    <Link to="/login?tabKey=login">
                      <div onClick={(_) => this.setState({ tabKey: "seller" })}>
                        已有账户，直接登录
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}
export default Register;
