/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import {Popover, Modal, Divider, Tabs, Radio, Input, Upload, message, Button} from "antd";
import {API, history, prefix, imgRoot, IconFont, ROOT} from "./static/js/common";
import {CloseOutlined, DoubleRightOutlined, DoubleLeftOutlined, FormOutlined, PlusOutlined} from "@ant-design/icons";
import moment from "moment";
import eventBus from "./static/js/events";

const { TextArea } = Input;
export const LoginContext = React.createContext();

const content = (
  <div style={{fontWeight: 'bold'}}>
    <div>
      <IconFont
        type="icon-kefu"
        style={{fontSize: 15, color: "#f94a3d",marginRight: 4}}
      />
      山治
      <span style={{fontSize: 12, color: "#b8becc"}}>（微信和电话同号）</span>
    </div>
    {/*<div>19005443903</div>*/}
  </div>
)

// 文件格式大小验证
function beforeUpload(file) {
  /*   const isFile = /csv|excel|sheet/g.test(file.type);
  if (!isFile) {
    message.error("仅支持CSV、XLSX、XLS格式的文件！");
  } */
  const isLt1M = file.size / 1024 / 1024 < 10;
  if (!isLt1M) {
    message.error("上传的文件不能大于10MB!");
  }
  return isLt1M;
}

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLogin: false, // 是否登录
      phone: "",
      tabKey: "",
      isShow: false,
      isShowPlugin: false,
      isShowWeb: false,
      isShowkf: false,
      isShowFeed: false,
      isShowwx: false,
      isShowVideo: false,
      isShowBanner: false,
      isShowDiv: true,
      video_url: '',
      video_title: '',
      plugin_url: 'https://dfcdn.shuidilipin.com/source/daifa/video/plugin_video.mp4',
      web_url: 'https://dfcdn.shuidilipin.com/source/daifa/video/webp_video.mp4',
      contextValue: { isLogin: 0 },
      logo: require('./static/img/bluelogo.svg'),
      isShowFeedModal: false,
      feedType: 1,
      feedContent: '',
      fileList: [],
      key: '1'
    };
  }

  componentDidMount() {
    //第一次进入网页弹窗
    /* let isShow = false;
    if (!window.localStorage.getItem("LP-ISFIRST")) {
      isShow = true;
      window.localStorage.setItem("LP-ISFIRST", 1);
    }
    this.setState({ isShow }); */
    this.getUserInfo();
    this.getTabKey();
    let fr = this.getUrlParams ('fr',window.location.search || window.location.hash);
    let inviteCode = this.getUrlParams ('inviteCode',window.location.search || window.location.hash);
    if(fr === 'baidu'){
      fr = 2;
    } else if(fr === 'dts'){
      fr = 0;
    } else{
      fr = 1;
    }
    window.localStorage.setItem("from-origin", fr);
    if(inviteCode) {
      window.localStorage.setItem("invite-code", inviteCode);
    }else{
      window.localStorage.removeItem('invite-code')
    }


    let params = window.location.search || window.location.hash;
    if(params.includes('#/')) {
      params = params.split('#/')[1]
    }
    let isShow = this.getUrlParams ('show',params);
    if(isShow) {
      this.setState({isShowVideo: true, video_url: "https://dfcdn.shuidilipin.com/source/daifa/video/pdd_daifa.mp4", video_title: '插件版教程', key: '2'})
    }else{
      const show = window.localStorage.getItem("showBanner");
      const todayDate = window.localStorage.getItem("showBannerDate");
      const nowDate = moment().format("YYYY-MM-DD");
      if(!show && !todayDate) {
        this.setState({isShowBanner: true});
        window.localStorage.setItem("showBanner", '1');
        window.localStorage.setItem("showBannerDate", nowDate);
      }
      if(parseInt(show) || (todayDate && (Date.parse(nowDate) - Date.parse(todayDate))/(24*60*60*1000) >= 3)){
        this.setState({isShowBanner: true});
        window.localStorage.setItem("showBanner", '1');
        window.localStorage.setItem("showBannerDate", nowDate);
      }
    }
  }

  getUrlParams = (name, str) => {
    const reg = new RegExp(`(^|&)${ name}=([^&]*)(&|$)`);
    const r = str.substr(1).match(reg);
    if (r != null) return  decodeURIComponent(r[2]); return null;
  };

  componentWillReceiveProps(props) {
    const path = props.location.pathname;
    if (path.indexOf("seller") !== -1) {
      if (this.state.isLogin || path.indexOf("help") !== -1) {
        this.setState({ tabKey: "seller" });
      } else {
        this.setState({ tabKey: "login" });
        history.push("/login?tabKey=login");
      }
    } else if (path === "/") {
      this.getUserInfo();
      this.getTabKey();
    } else {
      this.getTabKey();
    }
  }

  getUserInfo = () => {
    API.getAuthInfo({}).then((res) => {
      if (res.status * 1 === 0) {
        this.setState({
          isLogin: true,
          phone: res.extData.mobile,
          contextValue: { isLogin: 1 },
        });
        window.localStorage.setItem("phone", res.extData.mobile);
        // window.localStorage.setItem("slpToken", res.extData.token);
        // setCookie("slpToken", res.extData.token, ".shuidilipin.com");
      } else if (res.status * 1 === 1000) {
        this.setState({
          isLogin: false,
          contextValue: { isLogin: 0 },
        });
        window.localStorage.setItem("phone", "");
        if (
          this.props.location.pathname.indexOf("seller") !== -1 &&
          this.props.location.pathname.indexOf("help") === -1
        ) {
          history.push("/login?tabKey=login");
        }
      }
    });
  };

  getTabKey = () => {
    const params = window.location.hash;
    if (params.indexOf("seller") !== -1) {
      this.setState({ tabKey: "seller" });
    } else if (
      params.indexOf("login") !== -1 ||
      params.indexOf("forget") !== -1 ||
      params.indexOf("register") !== -1
    ) {
      this.setState({ tabKey: "login" });
    } else if(params.indexOf("collect") !== -1) {
      this.setState({ tabKey: "collect" });
    } else if(params.indexOf("help") !== -1) {
      this.setState({ tabKey: "help" });
    } else if(params.indexOf("all") !== -1) {
      this.setState({ tabKey: "all" });
    } else if(params.indexOf("settlement") !== -1) {
      this.setState({ tabKey: "settlement" });
    }
    else{
      this.setState({ tabKey: "index" });
    }
  };

  handleLogout = () => {
    API.getLogin({}).then((res) => {
      if (res.status * 1 === 0) {
        this.setState({ isLogin: false });
        window.localStorage.setItem("slpToken", "");
        history.push("/?tabKey=index");
        window.location.reload();
      }
    });
  };

  handleChangeTab = (url) => {
    if (this.state.isLogin) {
      this.setState({ tabKey: "seller" });
      history.push(url);
    } else {
      this.setState({ tabKey: "login" });
      window.location.href = '/#/login?tabKey=login';
      // history.push("/login?tabKey=login");
    }
  };

  handleCollectTab = (url) => {
    if (this.state.isLogin) {
      this.setState({ tabKey: "collect" });
      history.push(url);
    } else {
      this.setState({ tabKey: "login" });
      history.push("/login?tabKey=login");
    }
  };

  handleHelpTab = (url) => {
    this.setState({ tabKey: "help" });
    history.push(url);
  };

  linkWuliuPath = () => {
    if(this.props.location.pathname.indexOf("help") === -1){
      this.handleHelpTab("/help?path=express")
    }else{
      eventBus.emit('changeHelpType')
    }
  };

  content = (url) => {
    return (
      <div style={{minHeight: 128}}>
        <img src={`${imgRoot}/${url}`} alt="" width="100px" />
        <div style={{ textAlign: "center", marginTop: 6 }}>扫码联系客服</div>
      </div>
    );
  };

  title = (title) => {
    return (
      <span>
        {title}
        {title.includes('插件') ? (
            <span style={{marginLeft: 10, color: '#f94a3d',fontWeight: 'bold', cursor: 'pointer'}}
                  onClick={() => window.open('https://shuidilipin.com/help.html','_blank')}
            >插件下载</span>
        ) : ''}
      </span>
    );
  };

  closeBanncer = (e) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    this.setState({isShowBanner: false});
    window.localStorage.setItem("showBanner", '0');
  };

  hideDiv = () => {
    this.setState({isShowDiv: false})
  };
  showDiv = () => {
    this.setState({isShowDiv: true})
  };

  onTabChange = (e) => {
    this.setState({key: e})
    if(e == 2) {
      // if (window._hmt) {
      //   window._hmt.push(['_trackEvent', 'home', 'video_plugin', 'pdd'])
      // }
      this.setState({video_url: "https://dfcdn.shuidilipin.com/source/daifa/video/pdd_daifa.mp4"})
    } else if(e == 1) {
      // if (window._hmt) {
      //   window._hmt.push(['_trackEvent', 'home', 'video_plugin', 'taobao'])
      // }
      this.setState({video_url: "https://dfcdn.shuidilipin.com/source/daifa/video/plugin_video.mp4"})
    } else {
      // if (window._hmt) {
      //   window._hmt.push(['_trackEvent', 'home', 'video_plugin', 'dfbgfh'])
      // }
      this.setState({video_url: "https://dfcdn.shuidilipin.com/source/daifa/video/dfbgfh.mp4"})
    }
  }

  // 上传文件
  handleChange = ({ fileList }) => {
    const file = fileList.map(iterator => {
      if (iterator.status === 'done' && iterator.response.extData) {
        iterator.url = iterator.response.extData.fileUrl;
      }
      return iterator;
    });
    this.setState({fileList: file})
  };

  handleSubmit = () => {
    const { feedType, feedContent, fileList} = this.state;
    const self = this;
    if (!feedContent) {
      message.error("请输入问题描述");
      return;
    }
    const reqData = {
      type: feedType,
      content: feedContent,
      attach: fileList.length ? fileList.map(item => item.url).join(',') : ""
    };
    API.postSubmitFeed(reqData).then((res) => {
      if (res.status * 1 === 0) {
        message.success("提交成功");
        self.setState({
          feedType: 1,
          feedContent: "",
          fileList: [],
          isShowFeedModal: false
        });
      } else {
        message.error(res.message || "提交失败！");
      }
    });
  };

  render() {
    const {
      logo, isLogin, phone, tabKey, isShow, contextValue, isShowPlugin, isShowWeb,
      isShowkf,isShowwx, isShowVideo, video_url, plugin_url, web_url, video_title,
      isShowBanner, isShowDiv, isShowFeed, isShowFeedModal, feedType, feedContent,
      fileList, key
    } = this.state;

    const uploadButton = (
      <div>
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>上传图片</div>
      </div>
    );

    return (
      <div>
        {/* <div className="common_head">
          <div className="box flex-space">
            <ul className="flex-space">
              <li id="collect">
                <img
                  alt=""
                  src={`${imgRoot}/shoucang.png`}
                />
                收藏本站
                <div className="collect_show">Ctrl+D 收藏本站</div>
              </li>
              <li style={{ padding: 0 }}>
                <b>/</b>
              </li>
              <li>网站导航</li>
            </ul>
            <ul className="flex-space">
              {isLogin ? (
                <Fragment>
                  欢迎您：
                  <li id="is_login">
                    <span style={{ color: "#FF3333" }}>{phone}</span>
                    <span
                      style={{ marginLeft: 10 }}
                      onClick={this.handleLogout}
                    >
                      退出
                    </span>
                  </li>
                </Fragment>
              ) : (
                <li id="common_log" style={{ color: "#ff3333" }}>
                  欢迎访问
                </li>
              )}
              <li style={{ padding: 0 }}>
                <b>/</b>
              </li>
              <li>帮助中心</li>
              <li style={{ padding: 0 }}>
                <b>/</b>
              </li>
              <Popover
                placement="bottom"
                content={this.content}
                trigger="hover"
              >
                <li>联系客服</li>
              </Popover>
            </ul>
          </div>
        </div> */}
        {/*<div*/}
        {/*  className="home_banner_model"*/}
        {/*  onClick={() => {*/}
        {/*    window.open("https://www.jimeimadou.com/?fr=xtdf");*/}
        {/*  }}*/}
        {/*></div>*/}
        {isShowBanner && (
          <div className="home_header_top" onClick={(_) => this.linkWuliuPath()}>
            <span onClick={this.closeBanncer}><CloseOutlined /></span>
          </div>
        )}
        <div style={{ minWidth: 1200, background: "#ffffff" }}>
          <div className="home_header flex-space">
            <div className="home_header_name">
              <Link to="/?tabKey=index">
                <img
                  src={`${imgRoot}/shuidi_logo.svg`}
                  onClick={(_) => this.setState({tabKey: "index"})}
                />
              </Link>
            </div>
            <div className="flex-start">
              <Link to="/">
                <div
                  className={`home_header_tab ${
                    tabKey === "index" ? "active_tab" : ""
                  }`}
                >
                  首页
                </div>
              </Link> 
              <Link to="/all?tabKey=all">
                <div
                  className={`home_header_tab ${
                    tabKey === "all" ? "active_tab" : ""
                  }`}
                  onClick={(_) => this.setState({ tabKey: "all" })}
                >
                  所有商品
                </div>
              </Link>
              <div
                className={`home_header_tab ${
                  tabKey === "seller" ? "active_tab" : ""
                  }`}
                onClick={(_) => this.handleChangeTab("/seller/account/home")}
              >
                个人中心
              </div>
              <div
                className={`home_header_tab ${
                  tabKey === "collect" ? "active_tab" : ""
                  }`}
                onClick={(_) => this.handleCollectTab("/collect?tabKey=collect")}
              >
                收藏列表
              </div>
              <div
                className={`home_header_tab ${
                  tabKey === "help" ? "active_tab" : ""
                  }`}
                onClick={(_) => this.handleHelpTab("/help")}
              >
                帮助中心
              </div>
              {/*<Link to="/collect?tabKey=collect">*/}
              {/*  <div*/}
              {/*    className={`home_header_tab ${*/}
              {/*      tabKey === "collect" ? "active_tab" : ""*/}
              {/*      }`}*/}
              {/*    onClick={(_) => this.setState({tabKey: 'collect'})}>*/}
              {/*    收藏列表*/}
              {/*  </div>*/}
              {/*</Link>*/}
        
			   {/*<Link to="/settlement">
			      <div
			        className={`home_header_tab ${
			          tabKey === "settlement" ? "active_tab" : ""
			        }`}
			        onClick={(_) => this.setState({ tabKey: "settlement" })}>
			        供货商入驻
			      </div>
			   </Link>*/}
			   {/*
			  <Popover
			    placement="bottom"
			    content={() => this.content("kfqw.png")}
			    trigger="hover"
			  >
			    <div className="home_header_tab">联系客服</div>
			  </Popover>*/}
              {/*<Popover
                placement="bottom"
                content={() => this.content("kfqw.png")}
                trigger="hover"
              >
                <div
                  className="home_header_tab"
                  // style={{ marginRight: isLogin ? 120 : 250 }}
                >
                  供货商入驻
                </div>
              </Popover>*/}
            {/*</div>*/}
            {/*<div*/}
            {/*  className="jimei_banner"*/}
            {/*  style={{color: '#c99a0e', cursor: 'pointer'}}*/}
            {/*  onClick={() => {*/}
            {/*    window.open("https://www.jimeimadou.com/?fr=xtdf");*/}
            {/*  }}*/}
            {/*>*/}
            {/*  <img*/}
            {/*    style={{marginRight: '3px'}}*/}
            {/*    src='https://img.jimeimadou.com/common/logo/24.png'*/}
            {/*    alt=''/>*/}
            {/*  <span style={{verticalAlign: 'middle'}}>集美麻豆买家秀平台</span>*/}
            {/*</div>*/}
            {/*<div className="home_login">*/}
              {isLogin ? (
                <div style={{ fontSize: 14, padding: "6px 0" }}>
                  欢迎您：{phone}
                  <span
                    className="home_header_tab_logout"
                    onClick={this.handleLogout}
                  >
                    退出
                  </span>
                </div>
              ) : (
                <Link to="/login?tabKey=login">
                  <div
                    className={`home_header_tab ${
                      tabKey === "login" ? "active_tab" : ""
                      }`}
                    style={{ marginRight: 0 }}
                    onClick={(_) => this.setState({ tabKey: "login" })}
                  >
                    登录
                  </div>
                </Link>
              )}
            </div>
          </div>
        </div>
        <LoginContext.Provider value={contextValue}>
          {this.props.children}
        </LoginContext.Provider>
        {isShowDiv && (
          <div className="fix_icon_wrapper">
            {/*<div id="btnOnlineService" className="kefu_online">
              <Popover
                content="点击咨询客服"
                placement="left"
                trigger="hover">
                <img src="https://images.weserv.nl/?url=https://dfcdn.shuidilipin.com/source/daifa/service_online.png"/>
              </Popover>
            </div>*/}

            <div className="icon_bg">
              <div className="hide" title="收起" onClick={this.hideDiv}>
                <DoubleRightOutlined/>
              </div>
              {/*<div className="text">代发</div>*/}
              {/*<div className="text">教程</div>*/}
              {/*<div className="line" style={{marginTop: 6}}></div>*/}

              {/*{!isShowPlugin && (*/}
              {/*  <div className="plugin_icon"*/}
              {/*       style={{paddingTop: 8, paddingBottom: 5}}*/}
              {/*       onMouseEnter={(_) => this.setState({isShowPlugin: true})}>*/}
              {/*    <IconFont*/}
              {/*      type="icon-chajian"*/}
              {/*      style={{fontSize: 26, color: "#ffffff"}}*/}
              {/*    />*/}
              {/*  </div>)}*/}
              {/*{isShowPlugin && (*/}
              {/*  <div className="plugin"*/}
              {/*       style={{*/}
              {/*         paddingTop: 10,*/}
              {/*         paddingBottom: 10,*/}
              {/*         backgroundColor: "#ffffff",*/}
              {/*         cursor: 'pointer',*/}
              {/*         color: '#33AAFF'*/}
              {/*       }}*/}
              {/*       onClick={() => {*/}
              {/*         if (window._hmt) {*/}
              {/*           window._hmt.push(['_trackEvent', 'home', 'video_plugin', 'taobao'])*/}
              {/*         }*/}
              {/*         this.setState({isShowVideo: true, video_url: plugin_url, video_title: '插件版教程'})*/}
              {/*       }}*/}
              {/*       onMouseLeave={(_) => this.setState({isShowPlugin: false})}>*/}
              {/*    <Popover*/}
              {/*      placement="left"*/}
              {/*      content="点击查看视频"*/}
              {/*      trigger="hover">*/}
              {/*      插件*/}
              {/*    </Popover>*/}
              {/*  </div>)}*/}
              {/*<div className="line"></div>*/}
              {/*
              {!isShowWeb && (
                <div className="web_icon"
                     style={{paddingTop: 8}}
                     onMouseEnter={(_) => this.setState({isShowWeb: true})}>
                  <IconFont
                    type="icon-wangye1"
                    style={{fontSize: 26, color: "#ffffff"}}
                  />
                </div>)}
              {isShowWeb && (
                <div className="web"
                     style={{
                       paddingTop: 8,
                       paddingBottom: 13,
                       backgroundColor: "#ffffff",
                       cursor: 'pointer',
                       color: '#f94a3d'
                     }}
                     onClick={() => {
                       // if (window._hmt) {
                       //   window._hmt.push(['_trackEvent', 'home', 'video_web', 'web'])
                       // }
                       this.setState({isShowVideo: true, video_url: web_url, video_title: '官网版教程'})
                     }}
                     onMouseLeave={(_) => this.setState({isShowWeb: false})}>
                  <Popover
                    placement="left"
                    content="点击查看视频"
                    trigger="hover">
                    官网
                  </Popover>
                </div>)}*/}
            </div>

            <div className="icon_contact">
              {!isShowwx && (
                <div className="kf_wechat flex-center"
                     style={{marginTop: 4}}
                     onMouseEnter={(_) => this.setState({isShowwx: true})}>
                  <IconFont
                    type="icon-a-wx2x"
                    style={{fontSize: 26, color: "#ffffff"}}
                  />
                </div>)}
              {isShowwx && (
                <div className="kf_wechat flex-center"
                     style={{marginTop: 4, backgroundColor: "#ffffff", cursor: 'pointer', color: '#f94a3d'}}
                     onMouseLeave={(_) => this.setState({isShowwx: false})}>
                  <Popover
                    placement="left"
                    content={() => this.content("kfqw.png")}
                    trigger="hover">
                    客服
                  </Popover>
                </div>
              )}
              {/*<div className="line"></div>*/}
              {/*{!isShowkf && (*/}
              {/*  <div className="kf_icon flex-center"*/}
              {/*       onMouseEnter={(_) => this.setState({isShowkf: true})}>*/}
              {/*    <IconFont*/}
              {/*      type="icon-kefu"*/}
              {/*      style={{fontSize: 26, color: "#ffffff"}}*/}
              {/*    />*/}
              {/*  </div>)}*/}
              {/*{isShowkf && (*/}
              {/*  <div className="kf_icon flex-center"*/}
              {/*       style={{backgroundColor: "#ffffff", cursor: 'pointer', color: '#33AAFF'}}*/}
              {/*       onMouseLeave={(_) => this.setState({isShowkf: false})}>*/}
              {/*    <Popover*/}
              {/*      content={content}*/}
              {/*      placement="left"*/}
              {/*      trigger="hover">*/}
              {/*      客服*/}
              {/*    </Popover>*/}
              {/*  </div>)}*/}
              <div className="line"></div>
              {!isShowFeed && (
                <div className="kf_icon flex-center"
                     onMouseEnter={(_) => this.setState({isShowFeed: true})}>
                  <FormOutlined style={{fontSize: 26, color: "#ffffff"}}/>
                </div>)}
              {isShowFeed && (
                <div className="kf_icon flex-center"
                     style={{backgroundColor: "#ffffff", cursor: 'pointer', color: '#f94a3d'}}
                     onMouseLeave={(_) => this.setState({isShowFeed: false})}
                     onClick={() => this.setState({isShowFeedModal: true})}>
                    反馈
                </div>)}
            </div>
          </div>
        )}
        {!isShowDiv && (
          <div className="fix_icon_show_wrapper" onClick={this.showDiv}>
            <div className="show_icon" title="展开">
              <DoubleLeftOutlined />
            </div>
          </div>
        )}
        <div className="common_footer">
          <Divider plain>严正声明</Divider>
          <div className="footer_desc">
            <div className="flex-start">
              <div className="index flex-center">1</div>
              <div className="desc">本站为正规礼品批发商城，类似1688平台，不提供信封、空包等违法服务，严禁用于刷单、洗钱行为！</div>
            </div>
            <div className="flex-start">
              <div className="index flex-center">2</div>
              <div className="desc">严禁张冠李戴，非法使用他人个人信息用于犯罪，一经发现，直接封号，并保存记录协助公安机关调查！</div>
            </div>
            <div className="flex-start">
              <div className="index flex-center">3</div>
              <div className="desc">平台账户仅限于自己使用，严禁非法牟利注册/倒卖账号行为，违规者双方均须承担相应法律责任！</div>
            </div>
            <div className="flex-start">
              <div className="index flex-center">4</div>
              <div className="desc">严禁通过平台获取快递单号后到第三方电商平台店铺进行退货退款行为，违规者直接封号且须承担相应法律责任！</div>
            </div>
            {/*<div className="flex-center" style={{fontSize: 15,marginTop:10}}>联系电话：19005443903</div>*/}
          </div>
          <div className="footer_href">
		    <p>Copyright © 2024 All Rights Reserved</p>
            <a
              href="https://beian.miit.gov.cn/#/Integrated/recordQuery"
              target="_blank"
              style={{marginRight: '20px'}}>
              浙ICP备2024095682号-1
            </a>
			{/*
            <a href="https://beian.miit.gov.cn" target="_blank">
              增值电信业务经营许可证：
              {prefix ? "浙B2-20230964" : "浙B2-20230964"}
            </a>*/}
          </div>
        </div>
		{/*
        <Modal
          visible={isShow}
          footer={null}
          onCancel={(_) => this.setState({ isShow: false })}
          className="modal_first"
        >
          <div className="modal_code">
            <img src={`${imgRoot}/kfqw.png`} alt="" />
          </div>
          <div>扫码添加管理员</div>
          <div>礼品问题24小时在线回复</div>
        </Modal>*/}
        <Modal
          visible={isShowVideo}
          footer={null}
          width="1100px"
          title={this.title(video_title)}
          onCancel={(_) => this.setState({ isShowVideo: false, video_url: '', key: '1' })}
          className="modal_video">
          {video_title.includes('插件') ? (
            <div className="tab_wrapper">
              <Tabs onChange={ e => this.onTabChange(e)} activeKey={key}>
                <Tabs.TabPane tab="淘宝版" key="1"></Tabs.TabPane>
                <Tabs.TabPane tab="拼多多版" key="2"></Tabs.TabPane>
                <Tabs.TabPane tab="发货助手" key="3"></Tabs.TabPane>
              </Tabs>
            </div>
          ) : ''}
          <div className="video_wrapper">
            <video src={video_url} autoPlay muted controls="controls" width="1050" height="600"/>
          </div>
        </Modal>
        <Modal
          visible={isShowFeedModal}
          footer={null}
          width="600px"
          title="意见反馈"
          onCancel={(_) => this.setState({ isShowFeedModal: false })}
          className="modal_feed">
          <div className="form_wrapper">
            <div className="flex-start" style={{marginBottom: 14}}>
              <div style={{width: '70px'}}>反馈类型</div>
              <div>
               <Radio.Group
                value={feedType}
                buttonStyle="solid"
                onChange={(e) => this.setState({ feedType: e.target.value })}>
                <Radio.Button value={1}>bug反馈</Radio.Button>
                <Radio.Button value={2}>功能建议</Radio.Button>
                <Radio.Button value={3}>投诉客服</Radio.Button>
                <Radio.Button value={4}>订单问题</Radio.Button>
                <Radio.Button value={5}>其它</Radio.Button>
               </Radio.Group>
              </div>
            </div>
            <div className="flex-start" style={{marginBottom: 14}}>
              <div style={{width: '70px'}}>反馈描述</div>
              <div>
              <TextArea
                className="input_desc"
                value={feedContent}
                style={{width: 450}}
                max={200}
                onChange={(e) => this.setState({feedContent: e.target.value})}
                autoSize={{minRows: 4}}
                placeholder="请输入问题描述"/>
              </div>
            </div>
            <div className="flex-start">
              <div style={{width: '70px'}}>反馈附件</div>
              <div className="flex-start">
                <Upload
                  listType="picture-card"
                  className="avatar-uploader"
                  fileList={fileList}
                  beforeUpload={beforeUpload}
                  action={`${ROOT}/upload/filecos`}
                  onChange={this.handleChange}>
                  {fileList.length > 0 ? '' : uploadButton}
                </Upload>
              </div>
            </div>
            <div style={{marginTop: "30px", marginLeft: "210px"}}>
              <Button
                type="primary"
                size="large"
                style={{width: 120}}
                onClick={()=>this.handleSubmit()}>
                提交
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
export default App;
