/* 售后服务-底单申请 */
import React, { Component, Fragment } from "react";
import {Tabs, Button, message, Modal, Input, Alert, Upload, Table, Carousel, Radio, DatePicker} from "antd";
import { API, ROOT } from "../../../static/js/common";
import {PlusOutlined, VerticalAlignBottomOutlined, LeftOutlined, RightOutlined} from '@ant-design/icons';
import TextCount from './TextareaCount';
import moment from "moment";
import BatchOrder from './batchorder';
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

// 文件格式大小验证
function beforeUpload(file) {
  /*   const isFile = /csv|excel|sheet/g.test(file.type);
  if (!isFile) {
    message.error("仅支持CSV、XLSX、XLS格式的文件！");
  } */
  const isLt1M = file.size / 1024 / 1024 < 10;
  if (!isLt1M) {
    message.error("上传的文件不能大于10MB!");
  }
  return isLt1M;
}

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

class RequestOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      pageSize: 10,
      activeKey: "1",
      withdraw_data: {},
      fkData: "",
      email: "",
      file_url: "",
      lintNumbers: [],
      previewVisible: false,
      previewImage: "",
      fileList: [],
      status: 0,
      createStart: "",
      createEnd: "",
    };
  }

  componentDidMount() {
  }

  getServiceList = () => {
    const { page, pageSize, status, createStart, createEnd } = this.state;
    const reqData = { page, pageSize, status };
    if(createStart){
      reqData.createTimeStart = createStart;
    }
    if(createEnd){
      reqData.createTimeEnd = createEnd;
    }
    API.getOrderRequestList(reqData).then((res) => {
      if (res.status * 1 === 0) {
        this.setState({ withdraw_data: res.extData || {} });
      } else {
        message.error(res.message || "数据加载错误");
      }
    });
  };

  handleSubmit = () => {
    const { email, fileList, fkData} = this.state;
    const self = this;
    if (!fkData) {
      message.error("请输入快递单号");
      return;
    }
    if(fkData.split('\n').map(item => item.trim()).filter(item => item).length !== fkData.split('\n').length){
      message.error("快递单号不允许有空行");
      return;
    }
    if(!fileList.length){
      message.error("请上传店铺订单/维权截图");
      return;
    }
    let aray = fkData.split('\n').map(item => item.trim()).filter(item => item);
    aray = [...new Set(aray)];
    const reqData = {
      bsContentNos: aray.join(','),
      email: email || '',
      bsAttach: fileList.length ? fileList.map(item => item.url).join(',') : "",
    };
    this.onBlurPromise(fkData).then(()=>{
      API.postSaveOrderRequest(reqData).then((res) => {
        if (res.status * 1 === 0) {
          message.success("提交成功");
          self.setState({
            fkData: "",
            email: "",
            fileList: []
          });
          self.childRef.clearText();
        } else {
          message.error(res.message || "提交失败！");
        }
      });
    },()=>{
      message.error( "请检查快递单号！");
    })
  };

  // 上传文件
  handleChange = ({ fileList }) => {
    const file = fileList.map(iterator => {
      if (iterator.status === 'done' && iterator.response.extData) {
        iterator.url = iterator.response.extData.fileUrl;
      }
      return iterator;
    });
    this.setState({fileList: file})
  };

  handleChangeTab = (key) => {
    this.setState({activeKey: key});
    if(parseInt(key) === 2){
      this.getServiceList();
    }
    if (window._hmt) {
      window._hmt.push(['_trackEvent', 'bottomOrder-request', 'tab', key==1?'bottomOrderRequest':(key==2?'bottomOrderList':'autoFetchBottomOrder')])
    }
  };

  handlePreview = file => {
    this.setState({previewImage: ''});
    if (!file.url && !file.preview) {
      file.preview = getBase64(file.originFileObj);
    }
    this.setState({
      previewImage: [file.url || file.response.extData.fileUrl || file.preview],
      previewVisible: true,
    });
  };

  onTextChange = (val) => {
    this.setState({fkData: val});
  };

  onTextBlur = (val) => {
    this.onBlurPromise(val).then(()=>{},()=>{})
  };

  onBlurPromise = (val) => {
    return new Promise((resolve, reject) => {
      if (!val) {
        this.setState({lintNumbers: []});
        message.error("请填写快递单号");
        reject();
        return;
      }
      const numbers = val.split('\n').map(item => item.trim()).filter(item => item);
      if (numbers.length !== val.split('\n').length) {
        this.setState({lintNumbers: []});
        message.error("快递单号不允许有空行");
        reject();
        return;
      }
      API.getCheckNumbers({
        fkStatus: 1,
        fkData: numbers.join(',')
      }).then((res) => {
        if (res.status * 1 === 0) {
          const checkList = res.extData.check;
          if (checkList && checkList.length) {
            let lintNumber = [];
            checkList.forEach((item, index) => {
              for (const number in item) {
                if (!item[number]) {
                  lintNumber.push(`第${index + 1}行单号不存在,请检查`)
                }
              }
            });
            this.setState({lintNumbers: lintNumber}, () => {
              if (lintNumber.length) {
                reject()
              } else {
                resolve()
              }
            })
          }
        }
      })
    });
  };

  handleChildEvent = (ref) => {
    this.childRef = ref;
  };

  handleDownloadImage =(list) => {
    list.forEach((item,idx)=>{
      let image = new Image();
      image.setAttribute("crossOrigin", "anonymous");
      image.onload = function() {
        let canvas = document.createElement("canvas");
        canvas.width = image.width;
        canvas.height = image.height;
        let context = canvas.getContext("2d");
        context.drawImage(image, 0, 0, image.width, image.height);
        let url = canvas.toDataURL("image/png"); //得到图片的base64编码数据
        let a = document.createElement("a"); // 生成一个a元素
        let event = new MouseEvent("click"); // 创建一个单击事件
        a.download = "底单"+(idx + 1); // 设置图片名称
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
      };
      image.src = item;
    });
  };

  // 选择日期
  changeTime = (e) => {
    let { createStart, createEnd } = this.state;
    if (e) {
      createStart = moment(e[0]).format("YYYY-MM-DD HH:mm");
      createEnd = moment(e[1]).format("YYYY-MM-DD HH:mm");
    } else {
      createStart = "";
      createEnd = "";
    }
    this.setState({ createStart, createEnd },(_) => this.getServiceList());
  };

  render() {
    const {
      withdraw_data,
      page,
      pageSize,
      email,
      activeKey,
      previewVisible,
      previewImage,
      fileList,
      lintNumbers,
      status
    } = this.state;

    const columns = [
      {
        title: "编号",
        dataIndex: "id",
        align: "center",
      },
      {
        title: "数量",
        dataIndex: "num",
        align: "center",
      },
      {
        title: "店铺订单/维权截图",
        dataIndex: "bsAttach",
        align: "center",
        width: 150,
        render: (e) => {
          return <div>
            {e ? (
              <div style={{position: "relative",width: '60px', height: '60px',margin: 'auto'}}>
                <img src={e.split(',')[0]}
                     style={{width: '100%', height: '100%', cursor: 'pointer', objectFit: 'cover'}}
                     onClick={() => {
                       this.setState({
                         previewImage: e.split(','),
                         previewVisible: true,
                       });
                     }}/>
                <div className="photo_num">{e.split(',').length}</div>
              </div>
            ) : ""}
          </div>;
        },
      },
      {
        title: "接收邮箱",
        dataIndex: "email",
        align: "center",
      },
      {
        title: "申请状态",
        dataIndex: "status",
        align: "center",
        render: (e) => {
          return <div style={{color: e === 1 ? '#f94a3d' : (e === 2 ? '#4BC600' : 'red')}}>{["", "待审核", "已审核", "已驳回"][e]}</div>;
        },
      },
      {
        title: "申请时间",
        dataIndex: "createTime",
        align: "center",
      },
      {
        title: "审核时间",
        dataIndex: "dealTime",
        align: "center",
      },
      {
        title: "操作",
        dataIndex: "operate",
        align: "center",
        render: (e,record) => (
          <Fragment>
            <div>
              {parseInt(record.status) === 2 ? (
                  record.bsContentAddr.includes('zip') ? (
                  <div style={{color: '#f94a3d', fontSize: 13, cursor: 'pointer'}}  onClick={() => {
                    window.open(record.bsContentAddr, '_blank')
                  }}>下载文件</div>
                  ) : (
                  <div style={{color: '#9A9A9A', fontSize: 13, cursor: 'pointer'}}  onClick={() => {
                    this.setState({
                      previewImage: record.bsContentAddr.split(','),
                      previewVisible: true,
                    });
                  }}>查看底单</div>
                  )
              ) : ""}
            </div>
          </Fragment>
        )
      },
    ];

    const uploadButton = (
      <div>
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>上传图片</div>
      </div>
    );

    return (
      <div>
        <div className="service_title">底单申请</div>
        <div className="service_tip">
          {activeKey != 3 ? (
            <Alert message="仅提供被降权的订单，请输入快递单号(可在平台搜索到结果)，客服审核通过后请在底单记录查看。" type="info" showIcon />
          ) : (
            <div style={{color: 'red'}}>
              <div>自助获取底单仅支持部分快递，如果未查询到结果请提交底单申请或联系客服获取。</div>
              <div>每次最多30条，获取成功后，可以点击批量下载或者复制单张图片另存为文件下载到本地目录。</div>
            </div>
          )}
        </div>
        <div className="service_tab">
          <Tabs activeKey={activeKey} onChange={(e)=>this.handleChangeTab(e)}>
            <TabPane tab="底单申请" key="1">
            </TabPane>
            <TabPane tab="底单记录" key="2">
            </TabPane>
            <TabPane tab="自助获取底单(仅支持部分快递)" key="3">
            </TabPane>
          </Tabs>
        </div>
        {activeKey == 1 && (
          <div className="search_form">
            <div className="flex-start">
              <label className="label"><i style={{color: 'red'}}>*</i>快递单号：</label>
              <div style={{width: '90%', display: 'flex'}}>
                <TextCount
                  type={1}
                  onTextAreaBlur={this.onTextBlur.bind(this)}
                  onTextAreaChange={this.onTextChange.bind(this)}
                  onChildEvent={this.handleChildEvent}/>
                {!!lintNumbers.length && (<div className="number_test">
                  {lintNumbers.map((item, idx) => (
                    <p key={idx}>{item}</p>
                  ))}
                </div>)}
              </div>
            </div>
            <div className="flex-start top_margin">
              <label className="label"><i style={{color: 'red'}}>*</i>上传店铺订单/维权截图：</label>
              <div style={{width: '80%'}}>
                <Upload
                  listType="picture-card"
                  className="avatar-uploader"
                  fileList={fileList}
                  beforeUpload={beforeUpload}
                  action={`${ROOT}/upload/filecos`}
                  onChange={this.handleChange}
                  onPreview={this.handlePreview}
                >
                  {fileList.length > 2 ? '' : uploadButton}
                </Upload>
              </div>
            </div>
            <div className="flex-start top_margin">
              <label className="label">接收邮箱：</label>
              <Input
                className="input_email"
                value={email}
                onChange={(e) => this.setState({email: e.target.value})}
                placeholder="请输入接收邮箱"/>
            </div>
          </div>
        )}

        {activeKey == 2 && (
          <div className="service_list">
            <div className="flex-space" style={{ marginBottom: 20 }}>
              <Radio.Group
                value={status}
                buttonStyle="solid"
                onChange={(e) =>
                  this.setState({ status	: e.target.value, page: 1 }, (_) =>
                    this.getServiceList()
                  )
                }>
                <Radio.Button value={0}>全部</Radio.Button>
                <Radio.Button value={1}>待审核</Radio.Button>
                <Radio.Button value={2}>已审核</Radio.Button>
                <Radio.Button value={3}>已驳回</Radio.Button>
              </Radio.Group>
              <div>
                <span style={{ marginRight: 10 }}>创建时间</span>
                <RangePicker onChange={(e) => this.changeTime(e)} showTime={{ format: 'HH:mm' }} format="YYYY-MM-DD HH:mm"/>
              </div>
            </div>
            <Table
              columns={columns}
              dataSource={withdraw_data.list || []}
              rowKey="id"
              pagination={{
                current: page,
                pageSize,
                total: withdraw_data.total * 1 || 0,
                showSizeChanger: false,
                onChange: (page) => {
                  this.setState({page}, (_) => {
                    this.getServiceList();
                  });
                },
              }}
            />
          </div>
        )}

        {activeKey == 1 && (
          <div style={{marginTop: "30px", marginLeft: "80px"}}>
            <Button
              type="primary"
              size="large"
              style={{width: 120}}
              onClick={()=>this.handleSubmit()}>
              提交
            </Button>
          </div>
        )}

        {activeKey == 3 && (
          <BatchOrder />
        )}

        {/* 查看图片 */}
        <Modal
          className="image-wrapper"
          visible={previewVisible}
          title=""
          footer={null}
          onCancel={()=>{this.setState({previewVisible: false})}}
        >
          {previewImage.length > 1 && (
            <div>
              <div
                className="carousel-left"
                onClick={() => {
                  // 通过获取走马灯dom，调用Carousel的prev()方法
                  this.card.prev();
                }}>
                <LeftOutlined/>
              </div>
              <div
                className="carousel-right"
                onClick={() => {
                  // 通过获取走马灯dom，调用Carousel的next()方法
                  this.card.next();
                }}>
                <RightOutlined/>
              </div>
            </div>
          )}
          <Carousel autoplay ref={e => this.card = e}>
            {previewImage.length && previewImage.map((item,idx) => (
              <img key={idx} style={{ width: '100%' }} src={item} />
            ))}
          </Carousel>
          <div style={{color: '#f94a3d', cursor: 'pointer', marginTop: '5px'}} onClick={()=>this.handleDownloadImage(previewImage)}><VerticalAlignBottomOutlined />批量下载</div>
        </Modal>
      </div>
    );
  }
}
export default RequestOrder;
