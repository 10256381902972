/* 财务管理-我要充值 */
import React, { Component } from "react";
import { InputNumber, Button, Table, message, Popover, Tooltip } from "antd";
import { API, IconFont, imgRoot, ROOT } from "../../../static/js/common";

const columns = [
  {
    title: "交易单号",
    dataIndex: "logSn",
    align: "center",
  },
  {
    title: "类型",
    dataIndex: "type",
    align: "center",
    render: (_) => {
      return <div>充值</div>;
    },
  },
  {
    title: "状态",
    dataIndex: "status",
    align: "center",
    render: (e, record) => {
      return <div>
        {["", "待支付", "充值成功", "充值失败"][e]}
        <span style={{color: '#1890ff'}}>{record.hasRefund && parseInt(record.hasRefund) ? '(有退款)' : ''}</span>
        </div>;
    },
  },
  {
    title: "金额",
    dataIndex: "amount",
    align: "center",
    render: (e) => {
      return <div>￥{(e / 100).toFixed(2)}</div>;
    },
  },
  {
    title: "提交时间",
    dataIndex: "createTime",
    align: "center",
  },
  {
    title: "支付时间",
    dataIndex: "applyTime",
    align: "center",
  },
  {
    title: "备注",
    dataIndex: "applyMsg",
    align: "center",
  },
];

class Recharge extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // type: 2,
      amount: 0,
      page: 1,
      pageSize: 10,
      recharge_data: {},
      remainAmount: (0).toFixed(2),
      isBan: 0,
      czzh: 1
    };
  }

  componentDidMount() {
    this.getRechargeList();
    this.getOrderLimits();
    API.getRechargeAmount({}).then((res) => {
      if (res.status * 1 === 0) {
        this.setState({
          remainAmount: res.extData.remainAmount
            ? (res.extData.remainAmount / 100).toFixed(2)
            : (0).toFixed(2),
          czzh: res.extData.czzh ? parseInt(res.extData.czzh) : 1
        });
      } else {
        message.error(res.message || "数据加载错误");
      }
    });
  }

  //获取用户下单权限
  getOrderLimits = () => {
    API.postUserLimit({}).then((res) => {
      if (res.status * 1 === 0) {
        this.setState({isBan: parseInt(res.extData.isBan) || parseInt(res.extData.isBanIp)})
      } else {
        message.error(res.message);
      }
    });
  };

  handleRecharge = () => {
    const { amount, isBan, czzh } = this.state;
    if(parseInt(isBan)) {
      message.error("账号异常，无法充值！");
      return;
    }
    if (!amount) {
      message.error("请输入正确的充值金额");
      return;
    }
    if (window._hmt) {
      window._hmt.push(['_trackEvent', 'recharge', 'button', 'click'])
    }
    const token = window.localStorage.getItem("slpToken") || "";
    const value = parseInt((amount * 100).toFixed(2));
    window.open(
      `https://dfapi.shuidilipin.com/alipay/recharge?_t=${new Date().valueOf()}&token=${token}&amount=${value}&czzh=${czzh}`
    );
  };

  getRechargeList = () => {
    const { page, pageSize } = this.state;
    const reqData = { page, pageSize };
    API.getRechargeList(reqData).then((res) => {
      if (res.status * 1 === 0) {
        this.setState({ recharge_data: res.extData || {} });
      } else {
        message.error(res.message || "数据加载错误");
      }
    });
  };

  render() {
    const { page, pageSize, recharge_data, remainAmount } = this.state;
    return (
      <div>
        <div className="recharge">账户充值</div>
        <div className="recharge_use">
          可用余额：
          <span style={{ fontSize: 24, color: "#f94a3d" }}>
            ￥{remainAmount}
          </span>
          {/*<span style={{marginLeft: 50}}>体验金：</span>*/}
          {/*<span style={{ fontSize: 24, color: "#f94a3d" }}>*/}
          {/*  ￥{remainAmount}*/}
          {/*</span>*/}
          {/*<span style={{ fontSize: 12, color: "#B8BECC", marginTop: 8, marginLeft: 8 }}>*/}
          {/*  体验金使用说明*/}
          {/*  <Popover*/}
          {/*    placement="top"*/}
          {/*    trigger="hover"*/}
          {/*    content={*/}
          {/*      <span>*/}
          {/*        1.体验金活动仅限2021年6月1日之后注册的用户参与;<br/>*/}
          {/*        2.体验金仅用于下单支付抵扣,每单可抵扣0.5元体验金;<br/>*/}
          {/*        3.体验金为平台优惠补贴,不支持提现;<br/>*/}
          {/*        4.本活动最终解释权归水滴礼品平台所有。*/}
          {/*      </span>*/}
          {/*    }>*/}
          {/*    <IconFont*/}
          {/*      type="icon-bangzhu"*/}
          {/*      style={{marginLeft: 2, cursor: 'pointer'}}/>*/}
          {/*  </Popover>*/}
          {/*</span>*/}
        </div>
        <div className="recharge_type">
          {/* <div className="flex-start">
            充值方式：
            <Radio.Group
              value={type}
              style={{ fontWeight: 400, marginLeft: 10 }}
              onChange={(e) => this.setState({ type: e.target.value })}
            >
              <Radio value={2}>支付宝</Radio>
              <Radio value={3}>微信</Radio>
            </Radio.Group>
          </div> */}
		  <div className="flex-start">
		    充值方式：<img src={`${imgRoot}/zfb.webp`} alt="" width="120px" />
			</div>
          <div className="flex-start" style={{ margin: "30px 0" }}>
            充值金额：
            <InputNumber
              style={{ width: 150 }}
              size="large"
              placeholder="请输入充值金额"
              min={0.01}
              max={1000000}
              precision={2}
              onChange={(e) => this.setState({ amount: e })}
            />
            <span style={{ fontWeight: 400, marginLeft: 10 }}>元</span>
          </div>
          <div style={{ paddingLeft: 50 }}>
            <Button
              type="primary"
              style={{ width: 150 }}
              onClick={this.handleRecharge}
            >
              确认支付
            </Button>
          </div>
          <div style={{marginTop: 20, color: 'red'}}>温馨提示：未使用完充值金额可以提现，资金将原路退回充值账户</div>
        </div>
        <div className="recharge_list">
          <div style={{ fontSize: 16, fontWeight: 600, marginBottom: 20 }}>
            账户充值记录
          </div>
          <Table
            columns={columns}
            dataSource={recharge_data.list || []}
            rowKey="logSn"
            pagination={{
              current: page,
              pageSize,
              total: recharge_data.total * 1 || 0,
              showSizeChanger: false,
              onChange: (page) => {
                this.setState({ page }, (_) => {
                  this.getRechargeList();
                });
              },
            }}
          />
        </div>
      </div>
    );
  }
}
export default Recharge;
