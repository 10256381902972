/* eslint-disable array-callback-return */
/*
 * 收藏列表
 */
import React from "react";
import InfiniteScroll from "react-infinite-scroller";
import {API, history, IconFont, imgRoot} from "../../../static/js/common";
import {
  message,
  Spin,
  Tooltip,
  Button, Modal
} from "antd";

class CollectList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      pageSize: 20,
      goods_list: [],
      hasMore: true,
      loading: false,
      imgType: 'main',
      goodsId: '',
      isAuth: 0,
      isShowAuth: false,
      canUse: 1
    };
  }

  componentDidMount() {
    this.getCollectList();
    API.getAuthInfo({}).then((res) => {
      if (res.status * 1 === 0) {
        const data = res.extData;
        this.setState({
          isAuth: data.needRealName,
          canUse: parseInt(data.canUse)
        })
      }
    })
  }

  // 下拉加载
  handleMoreGoodList = () => {
    const {page, hasMore, loading} = this.state;
    if (loading || !hasMore) return;
    this.setState({page: page + 1}, (_) => this.getCollectList());
  };

  // 收藏、取消收藏
  handleCollect = (goodsId, isCollect, isLogin, idx, e) => {
    e.stopPropagation();
    if (isLogin === 0) {
      message.error("请登录", 1.2, (_) => history.push("/login?tabKey=login"));
      return;
    }
    const reqData = {goodsId, act: isCollect === 0 ? "add" : "del"};
    API.postHandleCollect(reqData).then((res) => {
      if (res.status * 1 === 0) {
        const {goods_list} = this.state;
        const new_goods_list = JSON.parse(JSON.stringify(goods_list));
        new_goods_list[idx].isCollect = 1 - isCollect;
        this.setState({
          goods_list: new_goods_list,
        });
        message.success(
          `${isCollect === 0 ? "收藏成功" : "取消收藏成功"}`,
          1.2
        );
      } else {
        message.error(res.message || "操作失败，请稍后再试！");
      }
    });
  };

  // 立即购买
  handlePay = (item) => {
    if(!parseInt(item.stock)){
      message.warn("库存不足");
      return;
    }
    if(this.state.canUse===2) {
      return;
    }
    if(parseInt(this.state.isAuth)===1) {
      this.setState({
        isShowAuth: true
      })
      return;
    }
    if(item.status * 1 === 1){
      history.push(
        `/seller/goods/goodsinfo?${item.goodsId}`
      )
    }else{
      message.warn("商品已下架");
    }
  };

  // 收藏列表
  getCollectList = () => {
    const {page, pageSize} = this.state;
    const reqData = {
      page,
      pageSize,
    };
    this.setState({loading: true});
    API.postCollectList(reqData).then((res) => {
      this.setState({loading: false});
      if (res.status * 1 === 0) {
        let {goods_list} = this.state;
        const data = res.extData || {};
        goods_list = goods_list.concat(data.list || []);
        this.setState({
          goods_list,
          hasMore: goods_list.length !== data.total * 1,
        });
      } else {
        message.error(res.message || "数据加载错误");
        this.setState({hasMore: false});
      }
    });
  };

  toAuth = () => {
    window.open(`/#/seller/account/home?status=1`,'_blank')
  };

  render() {
    const {
      goods_list,
      loading,
      hasMore,
      imgType,
      goodsId,
      isShowAuth,
      canUse
    } = this.state;
    const {isLogin} = this.context;
    return (
      <div className="home">
        <InfiniteScroll
          initialLoad={false}
          loadMore={this.handleMoreGoodList}
          hasMore={!loading && hasMore}
          threshold={100}>
          <div
            style={{
              width: 1200,
              margin: "0 auto",
              display: "flex",
              flexWrap: "wrap",
              marginTop: 20
            }}>
            {goods_list.map((item, idx) => (
              <div
                key={item.goodsId}
                className="new_home_goods_flex"
                style={{ marginRight: (idx + 1) % 5 === 0 ? 0 : 12 }}>
                <div style={{ position: "relative", cursor: 'pointer' }}
                     onClick={(_) => this.handlePay(item)}>
                  {goodsId !== item.goodsId && (<div>
                    <img
                      src={
                        item.goodsImg
                          ? `${item.goodsImg}?imageMogr2/crop/230x230/gravity/center`
                          : `${imgRoot}/photo.png`
                      }
                      alt=""
                    />
                  </div>)}
                  {/*{imgType === 'pro1' && goodsId === item.goodsId  && (
                    <div style={{position: "relative"}}>
                      <img
                        src={
                          item.productPhoto1
                            ? `${item.productPhoto1}?imageMogr2/crop/230x230/gravity/center`
                            : `${imgRoot}/photo.png`
                        }
                        alt=""
                      />
                      <div className="new_home_goods_icon">产品实拍</div>
                    </div>)}
                  {imgType === 'pro2' && goodsId === item.goodsId && (
                    <div style={{position: "relative"}}>
                      <img
                        src={
                          item.productPhoto2
                            ? `${item.productPhoto2}?imageMogr2/crop/230x230/gravity/center`
                            : `${imgRoot}/photo.png`
                        }
                        alt=""
                      />
                      <div className="new_home_goods_icon">产品实拍</div>
                    </div>)}
                  {imgType === 'package' && goodsId === item.goodsId && (
                    <div style={{position: "relative"}}>
                      <img
                        src={
                          item.packagePhoto
                            ? `${item.packagePhoto}?imageMogr2/crop/230x230/gravity/center`
                            : `${imgRoot}/photo.png`
                        }
                        alt=""
                      />
                      <div className="new_home_goods_icon">包裹实拍</div>
                    </div>)}
                  {imgType === 'warehouse' && goodsId === item.goodsId && (
                    <div style={{position: "relative"}}>
                      <img
                        src={
                          item.warehousePhoto
                            ? `${item.warehousePhoto}?imageMogr2/crop/230x230/gravity/center`
                            : `${imgRoot}/photo.png`
                        }
                        alt=""
                      />
                      <div className="new_home_goods_icon">供应商认证</div>
                    </div>)}*/}
                </div>
                <div className="new_home_goods_info_flex">
                  <div
                    className="new_home_goods_title_wrapper flex-space">
                    <div
                      className="new_home_goods_title_flex"
                      title={item.goodsName}>
                      {item.goodsName}
                    </div>
                    <div className="icon">
                      <Tooltip
                        placement="top"
                        title={item.isCollect * 1 ? "取消收藏" : "收藏商品"}>
                        <IconFont
                          type={`${
                            item.isCollect * 1 === 0
                              ? "icon-xiaotianshoucang"
                              : "icon-xiaotianshoucang2"
                            }`}
                          style={{fontSize: 24, marginTop: 2, marginLeft: 6}}
                          onClick={(e) =>
                            this.handleCollect(
                              item.goodsId,
                              item.isCollect * 1,
                              isLogin * 1,
                              idx,
                              e
                            )}/>
                      </Tooltip>
                    </div>
                  </div>
                  <div>
                    <div className="new_home_goods_price_flex flex-space">
                      <div style={{color: "#FF3333"}}>
                        <span style={{fontSize: 14, marginRight: 2}}>￥</span>
                        <span style={{fontSize: 20, fontWeight: 600}}>
                          {(item.goodsFee / 100).toFixed(2)}
                        </span>
                        <span className="new_home_goods_local_flex" style={{marginLeft: 4}}>包邮</span>
                        {item.overtimePaid * 1 === 1 && (
                          <Tooltip
                            placement="top"
                            title={
                              <span>
                              1、出单超时赔付
                              <br/>
                              该商品已加入出单超时赔付计划，最迟在完成付款时间的基础上延迟2小时完成打单发货。（例如：10:00付款的订单，必须在12:00前完成打单发货）如果超时，我们将给您赔付补偿。
                              <br/>
                              2、揽收超时赔付
                              <br/>
                              该商品已加入揽收超时赔付计划，当天18:00点前完成付款的包裹，需在第二天12:00前产生揽收记录，如果超时，我们将给您赔付补偿
                              <br/>
                              当天18:00点后完成付款的包裹，需在第二天24:00前产生揽收记录，如果超时，我们将给您赔付补偿。
                              <br/>
                              3、赔付金额
                              <br/>
                              低于2元的商品，我们将按商品实际包邮价格，全额赔付；高于2元的商品，我们将全部按照2元赔付。
                            </span>
                            }>
                            <img
                              src={`${imgRoot}/chaoshi.svg`}
                              alt=""
                              style={{
                                display: "inline-block",
                                width: 61,
                                height: 22,
                                cursor: "pointer",
                                marginBottom: 1,
                                marginLeft: 1,
                              }}
                            />
                          </Tooltip>
                        )}
                      </div>
                      <div style={{color: "#7A8399"}}>
                        {item.weight}g/件
                      </div>
                    </div>
                  </div>

                  <div className="flex-space" style={{marginTop: 8,}}>
                    <div className="new_home_goods_local">{item.address}</div>
                    <div className="new_home_goods_local flex-center">
                      <img src={item.courierImg} alt="" width="56px"/>
                    </div>
                    {canUse == 2 ? '' : (
                      <Button
                        type="primary"
                        style={{
                          width: 80,
                          height: 24,
                          fontSize: 12,
                          padding: 0
                        }}
                        onClick={(_) => this.handlePay(item)}>
                        立即购买
                      </Button>
                    )}
                  </div>
                  {/*<div className="img_wrapper flex-space-colstart" style={{marginTop: '10px'}}>
                    <img
                      style={{width: '48px',height:'48px',borderRadius: '4px',objectFit: 'cover', cursor: 'pointer'}}
                      src={
                        item.productPhoto1
                          ? `${item.productPhoto1}?imageMogr2/crop/48x48/gravity/center`
                          : `${imgRoot}/photo.png`
                      }
                      alt=""
                      onMouseEnter={()=>{this.setState({imgType: 'pro1', goodsId: item.goodsId})}}
                      onMouseLeave={()=>{this.setState({imgType: 'main', goodsId: ''})}}
                    />
                    <img
                      style={{width: '48px',height:'48px',borderRadius: '4px',objectFit: 'cover', cursor: 'pointer'}}
                      src={
                        item.productPhoto2
                          ? `${item.productPhoto2}?imageMogr2/crop/48x48/gravity/center`
                          : `${imgRoot}/photo.png`
                      }
                      alt=""
                      onMouseEnter={()=>{this.setState({imgType: 'pro2', goodsId: item.goodsId})}}
                      onMouseLeave={()=>{this.setState({imgType: 'main', goodsId: ''})}}
                    />
                    <img
                      style={{width: '48px',height:'48px',borderRadius: '4px',objectFit: 'cover', cursor: 'pointer'}}
                      src={
                        item.packagePhoto
                          ? `${item.packagePhoto}?imageMogr2/crop/48x48/gravity/center`
                          : `${imgRoot}/photo.png`
                      }
                      alt=""
                      onMouseEnter={()=>{this.setState({imgType: 'package', goodsId: item.goodsId})}}
                      onMouseLeave={()=>{this.setState({imgType: 'main', goodsId: ''})}}
                    />
                    <img
                      style={{width: '48px',height:'48px',borderRadius: '4px',objectFit: 'cover', cursor: 'pointer'}}
                      src={"https://dfcdn.shuidilipin.com/source/daifa/authImage.png"}
                      alt=""
                      onMouseEnter={()=>{this.setState({imgType: 'warehouse', goodsId: item.goodsId})}}
                      onMouseLeave={()=>{this.setState({imgType: 'main', goodsId: ''})}}
                    />
                  </div>*/}
                </div>
              </div>
            ))}
          </div>

          {loading && (
            <div style={{textAlign: "center", margin: 20}}>
              <Spin size="large" tip="努力加载中"/>
            </div>
          )}
        </InfiniteScroll>
        <div
          style={{
            fontSize: 14,
            color: "#999999",
            textAlign: "center",
            margin: 24,
          }}>
          {hasMore ? "下拉加载更多" : "暂无更多商品"}
        </div>
        <Modal
          width={350}
          visible={isShowAuth}
          onOk={this.toAuth}
          okText="去实名"
          onCancel={(_) => this.setState({ isShowAuth: false })}>
          <div>
            <div>
              <div style={{ fontSize: 16}}>
                请先完成实名认证
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default CollectList;
