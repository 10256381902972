/* 财务管理-我要提现 */
import React, { Component, Fragment } from "react";
import { InputNumber, Button, message, Modal, Input, Radio, Table } from "antd";
import { API } from "../../../static/js/common";

const columns = [
  {
    title: "交易单号",
    dataIndex: "logSn",
    align: "center",
  },
  {
    title: "类型",
    dataIndex: "type",
    align: "center",
    render: (e) => {
      return <div>{["", "分成提现", "余额提现"][e]}</div>;
    },
  },
  {
    title: "状态",
    dataIndex: "status",
    align: "center",
    render: (e) => {
      return <div>{["", "待审核", "打款中", "已打款", "已退回"][e]}</div>;
    },
  },
  {
    title: "收支",
    dataIndex: "amount",
    align: "center",
    render: (e) => {
      return <div>￥{(e / 100).toFixed(2)}</div>;
    },
  },
  {
    title: "时间",
    dataIndex: "createTime",
    align: "center",
  },
  {
    title: "支付时间",
    dataIndex: "payTime",
    align: "center",
  },
  {
    title: "备注",
    dataIndex: "remark",
    align: "center",
  },
];

class Withdraw extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      pageSize: 10,
      withdraw_data: {},
      type: 2,
      isBindAlipay: false,
      amount: "",
      remainAmount: (0).toFixed(2),
      remainCommission: (0).toFixed(2),
      showBind: false,
      isSend: false,
      getcode: "获取验证码",
      verifyCode: "",
      myAlipayName: "",
      myAlipayAccount: "",
      isBan: 0,
    };
  }

  componentDidMount() {
    this.getUserAccount();
    this.getOrderLimits();
    this.getWithdrawList();
  }

  //获取用户下单权限
  getOrderLimits = () => {
    API.postUserLimit({}).then((res) => {
      if (res.status * 1 === 0) {
        this.setState({isBan: parseInt(res.extData.isBan) || parseInt(res.extData.isBanIp)})
      } else {
        message.error(res.message);
      }
    });
  };

  getUserAccount = () => {
    API.getRechargeAmount({}).then((res) => {
      if (res.status * 1 === 0) {
        const { alipayName, alipayAccount, mobile } = res.extData;
        this.setState({
          isBindAlipay: !!alipayAccount,
          alipayName,
          alipayAccount,
          mobile,
          remainAmount: res.extData.remainAmount
            ? (res.extData.remainAmount / 100).toFixed(2)
            : (0).toFixed(2),
          remainCommission: res.extData.remainCommission
            ? (res.extData.remainCommission / 100).toFixed(2)
            : (0).toFixed(2),
        });
      } else {
        message.error(res.message || "数据加载错误");
      }
    });
  };

  handleWithdraw = (option, num) => {
    const { amount, isBindAlipay, type, isBan } = this.state;
    if(parseInt(isBan)) {
      message.error("账号异常，无法提现！");
      return;
    }
    if (!isBindAlipay && option === 2) {
      message.error("请绑定支付宝账号");
      return;
    }
    if (!amount) {
      message.error("请输入正确的提现金额");
      return;
    }
    if (amount * 1 < num) {
      message.error(`提现金额${num}元起`);
      return;
    }
    if (window._hmt) {
      window._hmt.push(['_trackEvent', 'withdraw', 'button', 'click'])
    }
    API.getWithdraw({
      type,
      option,
      amount: parseInt(amount * 100),
    }).then((res) => {
      if (res.status * 1 === 0) {
        message.success("提现成功", 1.2);
        this.setState({
          remainAmount: res.extData.remainAmount
            ? (res.extData.remainAmount / 100).toFixed(2)
            : (0).toFixed(2),
          remainCommission: res.extData.remainCommission
            ? (res.extData.remainCommission / 100).toFixed(2)
            : (0).toFixed(2),
        });
      } else {
        message.error(res.message || "提现失败！");
      }
    });
  };

  drawAll = () => {
    const { type, remainAmount, remainCommission } = this.state;
    if (type == 2) {
      this.setState({
        amount: parseFloat(remainAmount)
      })
    } else {
      this.setState({
        amount: parseFloat(remainCommission)
      })
    }
  };

  waitingVefiry = () => {
    // 获取验证码倒计时
    let countdown = 60;
    if (this.state.is_send) return;
    clearInterval(window._verify_timer);
    window._verify_timer = null;
    this.setState({
      is_send: true,
      getcode: `${countdown}s后重新获取`,
    });
    window._verify_timer = setInterval(() => {
      countdown--;
      let tips = `${countdown}s后重新获取`,
        send = true;
      if (countdown === 0) {
        clearInterval(window._verify_timer);
        tips = "获取验证码";
        send = false;
      }
      this.setState({ getcode: tips, is_send: send });
    }, 1000);
  };

  // 验证码
  onGetCaptcha = () => {
    // 获取验证码
    const { mobile, is_send } = this.state;
    if (is_send) return;
    API.getAuthSmsCode({
      mobile,
      sendType: "editAlipay",
    }).then((res) => {
      if (res.status * 1 === 0) {
        message.success("发送成功", 1.2);
        this.waitingVefiry();
      } else {
        message.error(res.message || "验证码发送失败！", 1.2);
        this.setState({
          is_send: false,
        });
      }
    });
  };

  // 绑定、修改支付宝
  handleBindAccount = () => {
    const { myAlipayName, myAlipayAccount, mobile } = this.state;
    if (!myAlipayName) {
      message.error("请填写支付宝姓名");
      return;
    }
    if (!myAlipayAccount) {
      message.error("请填写支付宝账号");
      return;
    }
    /* if (!verifyCode) {
      message.error("请填写短信验证码");
      return;
    } */
    API.postBindAlipay({
      mobile,
      alipayName: myAlipayName,
      alipayAccount: myAlipayAccount,
      // verifyCode,
    }).then((res) => {
      if (res.status * 1 === 0) {
        message.success("绑定成功！");
        this.getUserAccount();
        this.setState({
          showBind: false,
          myAlipayName: "",
          myAlipayAccount: "",
          verifyCode: "",
        });
      } else {
        message.error(res.message || "绑定失败！");
      }
    });
  };

  getWithdrawList = () => {
    const { page, pageSize } = this.state;
    const reqData = { page, pageSize };
    API.getWithdrawList(reqData).then((res) => {
      if (res.status * 1 === 0) {
        this.setState({ withdraw_data: res.extData || {} });
      } else {
        message.error(res.message || "数据加载错误");
      }
    });
  };

  render() {
    const {
      page,
      pageSize,
      withdraw_data,
      type,
      remainAmount,
      remainCommission,
      isBindAlipay,
      alipayName,
      alipayAccount,
      showBind,
      myAlipayName,
      myAlipayAccount,
      amount
      /* mobile,
      isSend,
      getcode,
      verifyCode,
       */
    } = this.state;
    return (
      <div>
        <div className="recharge">账户提现</div>
        <div className="recharge_use">
          可用余额：
          <span style={{ fontSize: 24, color: "#f94a3d", marginRight: 30 }}>
            ￥{remainAmount}
          </span>
          分成余额：
          <span style={{ fontSize: 24, color: "#f94a3d" }}>
            ￥{remainCommission}
          </span>
        </div>
        <div className="recharge_type">
          <div style={{ marginBottom: "20px" }}>
            提现类型：
            <Radio.Group
              value={type}
              buttonStyle="solid"
              onChange={(e) => this.setState({ type: e.target.value, amount: '' })}
            >
              <Radio.Button value={2}>可用余额</Radio.Button>
              <Radio.Button value={1}>分成余额</Radio.Button>
            </Radio.Group>
          </div>
          <div className="flex-start">
            提现金额：
            <InputNumber
              style={{ width: 150 }}
              value={ amount }
              size="large"
              placeholder="请输入提现金额"
              min={0}
              max={1000000}
              precision={2}
              onChange={(e) => this.setState({ amount: e })}
            />
            <span style={{ fontWeight: 400, marginLeft: 10 }}>元</span>
            <span
              style={{
                marginLeft: 20,
                color: "#ff143b",
                fontSize: 12,
                fontWeight: 400,
                cursor: "pointer",
              }}
              onClick={() => this.drawAll()}
            >
              全部提现
            </span>
          </div>
          <div style={{ margin: "20px 0" }}>
            支付宝姓名：{isBindAlipay ? alipayName : "--"}
          </div>
          <div>支付宝账号：
            <span style={{minWidth: 175, display: 'inline-block'}}>{isBindAlipay ? alipayAccount : "--"}</span>
            <span
              style={{
                marginLeft: 6,
                color: "#ff143b",
                fontSize: 12,
                fontWeight: 400,
                cursor: "pointer",
              }}
              onClick={(_) => this.setState({ showBind: true })}
            >
              {isBindAlipay ? "修改支付宝账户" : "绑定支付宝账户"}
            </span>
            </div>
          <div>
            {type === 1 ? (
              <Fragment>
                <Button
                  type="primary"
                  onClick={() => this.handleWithdraw(1, 0.1)}
                  style={{ marginTop: 20, marginRight: 20 }}
                >
                  提现至可用余额
                </Button>
                <Button
                  type="primary"
                  onClick={() => this.handleWithdraw(2, 10)}
                  style={{
                    marginTop: 20,
                    background: "#ffffff",
                    color: "#333333",
                    borderColor: "#d9d9d9",
                  }}
                >
                  提现至支付宝
                </Button>
              </Fragment>
            ) : (
              <Button
                type="primary"
                onClick={() => this.handleWithdraw(2, 10)}
                style={{
                  marginTop: 20,
                }}
              >
                提现至支付宝
              </Button>
            )}
            <div
              style={{
                fontSize: 14,
                fontWeight: 400,
                color: "#ff143b",
                marginTop: 10,
              }}
            >
              {type === 1
                ? "注：提现至可用余额 - 0.1元起提，立即到账；提现至支付宝 - 0.1元起提，预计3个工作日内到账，手续费1%"
                : "注：预计3个工作日内到账，手续费1%"}
            </div>
          </div>
        </div>
        <div className="recharge_list">
          <div style={{ fontSize: 16, fontWeight: 600, marginBottom: 20 }}>
            账户提现记录
          </div>
          <Table
            columns={columns}
            dataSource={withdraw_data.list || []}
            rowKey="logSn"
            pagination={{
              current: page,
              pageSize,
              total: withdraw_data.total * 1 || 0,
              showSizeChanger: false,
              onChange: (page) => {
                this.setState({ page }, (_) => {
                  this.getWithdrawList();
                });
              },
            }}
          />
        </div>
        {/* 绑定支付宝 */}
        <Modal
          title={isBindAlipay ? "修改支付宝账号" : "绑定支付宝账号"}
          visible={showBind}
          onCancel={(_) =>
            this.setState({
              showBind: false,
              myAlipayName: "",
              myAlipayAccount: "",
              verifyCode: "",
            })
          }
          footer={null}
        >
          <div>
            <div className="flex-start">
              <div style={{ width: 100, flexShrink: 0 }}>支付宝姓名：</div>
              <Input
                placeholder="请输入支付宝姓名"
                value={myAlipayName}
                onChange={(e) =>
                  this.setState({ myAlipayName: e.target.value })
                }
              />
            </div>
            <div className="flex-start">
              <div style={{ width: 100, flexShrink: 0, margin: "20px 0" }}>
                支付宝账号：
              </div>
              <Input
                placeholder="请输入支付宝账号"
                value={myAlipayAccount}
                onChange={(e) =>
                  this.setState({ myAlipayAccount: e.target.value })
                }
              />
            </div>
            {/*  <div className="flex-start">
              <div style={{ width: 100, flexShrink: 0 }}>手机号：</div>
              <Input value={mobile} disabled />
            </div> */}
            {/* <div className="flex-start">
              <div style={{ width: 100, flexShrink: 0, margin: "20px 0" }}>
                短信验证码：
              </div>
              <Input
                placeholder="请输入验证码"
                value={verifyCode}
                onChange={(e) => this.setState({ verifyCode: e.target.value })}
              />
              <Button
                disabled={isSend}
                style={{ marginLeft: 18 }}
                onClick={this.onGetCaptcha}
              >
                {getcode}
              </Button>
            </div> */}
            <div style={{ textAlign: "center" }}>
              <Button
                type="primary"
                style={{ width: 200 }}
                onClick={this.handleBindAccount}
              >
                确认
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
export default Withdraw;
