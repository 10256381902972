/* 发布商品-确认订单 */
import React, { Component } from "react";
import {Steps, Table, Button, Modal, message, Popover, Checkbox, Input} from "antd";
import {API, IconFont, imgRoot} from "../../../static/js/common";
import moment from "moment";
import domtoimage from 'dom-to-image';
import {upload} from '../../../static/js/upload'

const { Step } = Steps;

const columns = [
  {
    title: "序号",
    dataIndex: "id",
    align: "center",
  },
  {
    title: "订单编号",
    dataIndex: "tradeSn",
    align: "center",
  },
  {
    title: "收货人",
    dataIndex: "receiveName",
    align: "center",
  },
  {
    title: "联系手机",
    dataIndex: "receiveMobile",
    align: "center",
  },
  {
    title: "收货地址",
    dataIndex: "receiveProvince",
    align: "center",
    render: (e, record) => {
      return (
        <div>
          {e}&nbsp;
          {record.receiveCity}&nbsp;
          {record.receiveArea}&nbsp;
          {record.receiveAddress}
        </div>
      );
    },
  },
  {
    title: "礼品名称",
    dataIndex: "goodsName",
    align: "center",
  },
  {
    title: "礼品数量",
    dataIndex: "num",
    align: "center",
    render: (_) => {
      return <div>1</div>;
    },
  },
  {
    title: "费用",
    dataIndex: "parcelFee",
    align: "center",
    render: (e) => {
      return <div>￥{(e / 100).toFixed(2)}</div>;
    },
  },
  {
    title: "店铺名称",
    dataIndex: "shopName",
    align: "center",
  }
];

const content = (
  <div style={{fontWeight: 'bold'}}>
    <div>
      <IconFont
        type="icon-kefu"
        style={{fontSize: 15, color: "#f94a3d",marginRight: 4}}
      />
      辰辰
      <span style={{fontSize: 12, color: "#b8becc"}}>（微信和电话同号）</span>
    </div>
    {/*<div>19005443903</div>*/}
  </div>
);

class GoodsConfirm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowPay: false,
      isShowRecharge: false,
      isShowKf: false,
      isShowConfirm: false,
      isShowOpen: false,
      loading: false,
      orderId: this.props.location.search
        ? this.props.location.search.slice(1)
        : "",
      parcel_data: {},
      page: 1,
      pageSize: 10,
      totalFee: (0).toFixed(2),
      remainAmount: (0).toFixed(2),
      orderRights: 0,
      checkValue: [],
      phone: "",

      tName: '',
      tUrl: '',
      pName: '',
      pUrl: '',
      jName: '',
      jUrl: '',
      oName: '',
      oUrl: '',
      mName: '',
      mUrl: '',
      dName: '',
      dUrl: '',
    };
    this.handlePayOrder = this.debounce(this.handlePayOrder,300)
  }

  componentDidMount() {
    this.getParcelList();
    this.getOrderLimits();
    API.getOrderInfo({ orderId: this.state.orderId }).then((res) => {
      if (res.status * 1 === 0) {
        this.setState({
          totalFee: res.extData.totalFee
            ? (res.extData.totalFee / 100).toFixed(2)
            : (0).toFixed(2),
        });
      } else {
        message.error(res.message);
      }
    });
    const phone = window.localStorage.getItem('phone');
    this.setState({phone})
  }

  //获取用户下单权限
  getOrderLimits = () => {
    API.postUserLimit({}).then((res) => {
      if (res.status * 1 === 0) {
        this.setState({orderRights: parseInt(res.extData.orderRights)})
      } else {
        message.error(res.message);
      }
    });
  };

  // 包裹列表
  getParcelList = () => {
    const { page, pageSize, orderId } = this.state;
    const reqData = { page, pageSize, orderId };
    API.getParcelList(reqData).then((res) => {
      if (res.status * 1 === 0) {
        this.setState({ parcel_data: res.extData || {} });
      } else {
        message.error(res.message);
      }
    });
  };

  // 防抖，防止高频率操作
  debounce = (fn, wait = 600) =>{
    let timeout = null;
    return function() {
      if(timeout !== null) clearTimeout(timeout);
      timeout = setTimeout(fn, wait);
    }
  };

  // 支付订单
  handlePayOrder = () => {
    const { orderId } = this.state;
    API.getPayOrder({ orderId }).then((res) => {
      if (res.status * 1 === 0) {
        const todayDate = window.localStorage.getItem("PAY-DATE");
        const nowDate = moment().format("YYYY-MM-DD");
        if(!todayDate || (Date.parse(nowDate) - Date.parse(todayDate))/(1*24*60*60*1000) >= 3){
          this.setState({isShowKf: true, isShowConfirm: false});
          message.success("支付成功");
          window.localStorage.setItem("PAY-DATE", nowDate);
        }else{
          message.success("支付成功", 1, ()=>{
            window.location.href = "/#/seller/order/orderlist";
          });
        }
      } else if (res.status * 1 === -10000) {
        message.error(res.message, 1, (_) =>
          this.setState({
            isShowConfirm: false,
            isShowPay: true,
            remainAmount: res.extData.remainAmount
              ? (res.extData.remainAmount / 100).toFixed(2)
              : (0).toFixed(2),
          })
        );
      } else {
        message.error(res.message);
      }
    });
  };

  handleContact(){
    window.location.href = "/#/seller/order/orderlist";
  };

  // 充值
  handleOk = () => {
    window.open("/#/seller/finance/recharge");
    this.setState({
      isShowConfirm: false,
      isShowPay: false,
      isShowRecharge: true,
    });
  };

  content = (url) => {
    return (
      <div>
        <img src={`${imgRoot}/${url}`} alt="" width="100px" />
        <div style={{ textAlign: "center", marginTop: 6 }}>扫码联系客服</div>
      </div>
    );
  };

  onCheckboxChange = (value) => {
    this.setState({checkValue: value});
  };

  validateFields = () => {
    const {checkValue} = this.state;
    if(!checkValue.length) {
      message.warning("请勾选店铺类型");
      return false
    }
    // if(checkValue.findIndex(item => item==1)>-1) {
    //   if(!tName.trim() || !tUrl.trim()) {
    //     message.warning("请完善淘宝店铺信息");
    //     return false
    //   }
    // }
    // if(checkValue.findIndex(item => item==2)>-1) {
    //   if(!mName.trim() || !mUrl.trim()) {
    //     message.warning("请完善天猫店铺信息");
    //     return false
    //   }
    // }
    // if(checkValue.findIndex(item => item==3)>-1) {
    //   if(!jName.trim() || !jUrl.trim()) {
    //     message.warning("请完善京东店铺信息");
    //     return false
    //   }
    // }
    // if(checkValue.findIndex(item => item==4)>-1) {
    //   if(!pName.trim() || !pUrl.trim()) {
    //     message.warning("请完善拼多多店铺信息");
    //     return false
    //   }
    // }
    // if(checkValue.findIndex(item => item==5)>-1) {
    //   if(!dName.trim() || !dUrl.trim()) {
    //     message.warning("请完善抖店店铺信息");
    //     return false
    //   }
    // }
    // if(checkValue.findIndex(item => item==6)>-1) {
    //   if(!oName.trim() || !oUrl.trim()) {
    //     message.warning("请完善其他店铺信息");
    //     return false
    //   }
    // }
    return true
  };

  confirmOpenHandler = () => {
    const {checkValue} = this.state;
    const _this = this;
    if(!this.validateFields()){
      return;
    }
    if (window._hmt) {
      window._hmt.push(['_trackEvent', 'goodsconfirm', 'button', 'screenshot'])
    }
    this.setState({loading: true});
    let node = document.getElementById('root');
    domtoimage.toBlob(node, { bgColor: 'rgba(250,250,250)', width: 1800 })
      .then(function (blob) {
        const file = new window.File([blob], 'image', {type: 'png'});
        if (file) {
          upload(file, 'screenshot').then(res => {
            console.log(res, checkValue.join(','));
            _this.onSubmitShot(res.imgUrl);
          }, ()=>{
            message.error("开通失败，请联系客服");
            _this.setState({loading: false});
          });
        }else{
          message.error("开通失败，请联系客服");
          _this.setState({loading: false});
        }
      });
  };

  onSubmitShot = (url) => {
    const {checkValue, tName, tUrl, pName, pUrl, jName, jUrl, oName, oUrl, mName, mUrl, dName, dUrl} = this.state;
    let shopName = [];
    let shopUrl = [];
    checkValue.forEach(item => {
      if(parseInt(item)===1) {
        shopName.push(tName)
        shopUrl.push(tUrl)
      }else if(parseInt(item)===2) {
        shopName.push(mName)
        shopUrl.push(mUrl)
      }else if(parseInt(item)===3) {
        shopName.push(jName)
        shopUrl.push(jUrl)
      }else if(parseInt(item)===4) {
        shopName.push(pName)
        shopUrl.push(pUrl)
      }else if(parseInt(item)===5) {
        shopName.push(dName)
        shopUrl.push(dUrl)
      }else if(parseInt(item)===6) {
        shopName.push(oName)
        shopUrl.push(oUrl)
      }
    })
    let params = {
      shopType: checkValue.join(','),
      screenshotUrl: url,
      shopName: '',
      shopUrl: ''
    }
    console.log(params)
    API.screenshot(params).then((res) => {
      if (res.status * 1 === 0) {
        this.setState({loading: false, isShowOpen: false});
        message.success('开通成功');
        this.getOrderLimits();
      } else {
        message.error(res.message);
        this.setState({loading: false});
      }
    });
  };

  getContainer = () => {
    return this.container;
  };
  saveContainer = container => {
    this.container = container;
  };

  render() {
    const {
      isShowPay,
      isShowRecharge,
      isShowConfirm,
      isShowOpen,
      parcel_data,
      page,
      pageSize,
      totalFee,
      remainAmount,
      isShowKf,
      orderRights,
      checkValue,
      loading,
      phone
    } = this.state;

    const optionsShop = [
      {label: '淘宝', value: 1},
      {label: '天猫', value: 2},
      {label: '京东', value: 3},
      {label: '拼多多', value: 4},
      {label: '抖店', value: 5},
      {label: '其他', value: 6},
    ];

    const nowDate = moment().format("YYYY-MM-DD HH:mm:ss");

    return (
      <div ref={this.saveContainer}>
        <Steps current={1} style={{ width: "40%", margin: "20px auto" }}>
          <Step title="填写信息 " />
          <Step title="确认订单" />
        </Steps>
        <div>
          <div style={{ fontSize: 16, fontWeight: 600, marginBottom: 20 }}>
            订单明细
          </div>
          <Table
            columns={columns}
            dataSource={parcel_data.list || []}
            bordered
            rowKey="tradeSn"
            pagination={{
              current: page,
              pageSize: pageSize,
              total: parcel_data.total * 1 || 0,
              showSizeChanger: false,
              onChange: (page) => {
                this.setState({ page: page }, (_) => {
                  this.getParcelList();
                });
              },
            }}
          />
        </div>
        <div className="" style={{textAlign: 'right'}}>
          <div></div>
          <div>
            订单数:
            <span
              style={{ color: "#f94a3d", marginRight: 10, fontWeight: 600 }}
            >
              1
            </span>
            包裹数:
            <span
              style={{ color: "#f94a3d", marginRight: 10, fontWeight: 600 }}
            >
              {parcel_data.total}
            </span>
            费用:
            <span style={{ color: "#f94a3d", fontWeight: 600 }}>
              ￥{totalFee}
            </span>
          </div>
          {/*<div style={{marginTop: 6}}>*/}
          {/*  体验金可抵扣:*/}
          {/*  <span style={{ color: "#f94a3d", fontWeight: 600 }}>*/}
          {/*    ￥{(parcel_data.total * 0.5).toFixed(2)}*/}
          {/*  </span>*/}
          {/*</div>*/}
          <div style={{marginTop: 6}}>
            <span style={{ fontSize: 16, fontWeight: 600, marginRight: 10 }}>
              合计:
            </span>
            <span style={{ color: "#f94a3d", fontWeight: 600 }}>
              ￥{totalFee}
            </span>
          </div>
        </div>
        <div style={{ margin: "50px auto", textAlign: "center" }}>
          {/* <Button type="primary" size="large" style={{ marginRight: 20 }}>
            上一步
          </Button> */}
          <Button
            type="primary"
            size="large"
            disabled={!orderRights}
            onClick={(_) => this.setState({ isShowConfirm: true })}>
            提交订单
          </Button>
          {!orderRights && (
            <div style={{marginTop: '20px'}}>
              <div style={{color: '#515866'}}>首次购买的用户需联系客服人员进行账户审核</div>
              <div style={{marginTop: '8px', color: '#515866'}}>审核通过后可刷新当前页或进入订单列表选择最新一笔订单提交支付</div>
              <div style={{marginTop: '10px'}}>
                <Popover
                  content={() => this.content("kfqw.png")}
                  placement="top"
                  trigger="hover">
                  <img style={{width: '40px', height: '40px', marginRight: '15px', cursor: 'pointer'}}
                       src="https://images.weserv.nl/?url=https://dfcdn.shuidilipin.com/source/daifa/df_wx.png"/>
                </Popover>
                <Popover
                  content={content}
                  placement="top"
                  trigger="hover">
                  <img style={{width: '40px', height: '40px', marginRight: '15px', cursor: 'pointer'}}
                       src="https://images.weserv.nl/?url=https://dfcdn.shuidilipin.com/source/daifa/df_phone.png"/>
                </Popover>
                <Button
                  type="primary"
                  loading={loading}
                  onClick={(_) => this.setState({ isShowOpen: true })}>
                  自助开通
                </Button>
              </div>
            </div>
          )}
        </div>

        {/* 充值弹窗 */}
        <Modal
          title="充值提示"
          visible={isShowPay}
          onOk={this.handleOk}
          onCancel={(_) => this.setState({ isShowPay: false })}
          cancelText="取消"
          okText="立即充值"
        >
          <div style={{ textAlign: "center", fontSize: 16 }}>
            <div style={{ fontWeight: 600, marginBottom: 10 }}>
              余额不足无法完成支付
            </div>
            <div>
              当前余额：
              <span style={{ color: "#f94a3d", fontWeight: 600 }}>
                ￥{remainAmount}
              </span>
            </div>
          </div>
        </Modal>

        {/* 充值提示 */}
        <Modal
          title="充值提示"
          visible={isShowRecharge}
          onOk={(_) => this.setState({ isShowRecharge: false })}
          onCancel={this.handleOk}
          cancelText="立即充值"
          okText="充值完成"
        >
          <div style={{ textAlign: "center", fontSize: 16 }}>
            <div style={{ fontWeight: 600, marginBottom: 10 }}>
              等待余额充值中
            </div>
          </div>
        </Modal>

        {/* 订单确认 */}
        <Modal
          title="订单支付"
          visible={isShowConfirm}
          onCancel={(_) => this.setState({ isShowConfirm: false })}
          footer={null}
        >
          <div style={{ textAlign: "center", fontSize: 16 }}>
            <div style={{ fontWeight: 600, marginBottom: 10 }}>
              支付后将无法取消订单，请核对好后支付
            </div>
          </div>
          <div style={{ textAlign: "center", margin: "30px 0 20px" }}>
            <Button type="primary" size="large" onClick={this.handlePayOrder}>
              确认支付￥{totalFee}
            </Button>
            <div style={{ marginTop: 10 }}>
              共用
              <span style={{ color: "#f94a3d", fontWeight: 600 }}>
                {parcel_data.total}
              </span>
              个包裹，需要支付
              <span style={{ color: "#f94a3d", fontWeight: 600 }}>
                ￥{totalFee}
              </span>
            </div>
          </div>
        </Modal>

        {/* 扫码联系客服 */}
        <Modal
          title="Tips"
          visible={isShowKf}
          onOk={this.handleContact}
          onCancel={this.handleContact}
          okText="下单完成"
          cancelText="知道了"
        >
          <div style={{ textAlign: "center", fontSize: 15 }}>
            <img src={`${imgRoot}/kfqw.png`} alt="" width="120px" />
            <div style={{ marginTop: 20 }}>
              <span style={{color: 'red'}}>订单已支付成功</span>，新用户首次下单后请添加客服微信，方便后续问题处理
              (若已添加，请忽略)
            </div>
          </div>
        </Modal>

        {/* 自动开通 */}
        <Modal
          width="600px"
          title="自助开通权限申请"
          getContainer={this.getContainer}
          visible={isShowOpen}
          confirmLoading={loading}
          onOk={(_) => this.confirmOpenHandler()}
          onCancel={(_) => this.setState({ isShowOpen: false })}
          okText="我已知晓并确认"
          cancelText="不同意"
        >
          <div style={{ textAlign: "left", fontSize: 15 }} id="my-modal">
            <div style={{ fontWeight: 600, marginBottom: 10 }}>
              水滴礼品平台用户使用须知：
            </div>
            <div>
              1.本站为正规礼品批发商城，类似1688平台，<span style={{color: 'red'}}>不提供信封、空包等违法服务，严禁用于刷单、洗钱行为！</span><br/>
              2.严禁张冠李戴，非法使用他人个人信息用于犯罪，<span style={{color: 'red'}}>一经发现，直接封号，</span>并保存记录协助公安机关调查！<br/>
              3.聊天时文明沟通，理性互动。<span style={{color: 'red'}}>请勿发"刷单/补单"等违法违禁词。一经发现直接清退。</span><br/>
              4.平台账户仅限于自己使用，<span style={{color: 'red'}}>严禁非法牟利注册/倒卖账号行为</span>，违规者双方均须承担相应法律责任！<br/>
              5.严禁通过平台获取快递单号后到第三方电商平台店铺进行退货退款行为，违规者直接封号且须承担相应法律责任！
            </div>
            <div style={{marginTop: 20}}>
              <p style={{fontWeight: 600}}>勾选店铺类型(可多选)：</p>
              <Checkbox.Group
                options={optionsShop}
                value={checkValue}
                onChange={this.onCheckboxChange}
              />
            </div>
            <div className="shop-box" style={{display: 'none'}}>
              {(checkValue.findIndex(item=>item==1)>-1) && (
                <div
                  className="shop_item flex-space"
                  style={{marginTop: '13px'}}>
                  <div>
                    <label style={{marginRight: '10px'}}>淘宝店铺名称:</label>
                    <Input
                      style={{width: '140px'}}
                      placeholder="请输入店铺名称"
                      onChange={(e) =>
                        this.setState({tName: e.target.value})
                      }/>
                  </div>
                  <div>
                    <label style={{marginRight: '10px'}}>淘宝店铺链接:</label>
                    <Input
                      style={{width: '180px'}}
                      placeholder="请输入店铺链接"
                      onChange={(e) =>
                        this.setState({tUrl: e.target.value})
                      }/>
                  </div>
                </div>
              )}
              {(checkValue.findIndex(item=>item==2)>-1) && (
                <div
                  className="shop_item flex-space"
                  style={{marginTop: '13px'}}>
                  <div>
                    <label style={{marginRight: '10px'}}>天猫店铺名称:</label>
                    <Input
                      style={{width: '140px'}}
                      placeholder="请输入店铺名称"
                      onChange={(e) =>
                        this.setState({mName: e.target.value})
                      }/>
                  </div>
                  <div>
                    <label style={{marginRight: '10px'}}>天猫店铺链接:</label>
                    <Input
                      style={{width: '180px'}}
                      placeholder="请输入店铺链接"
                      onChange={(e) =>
                        this.setState({mUrl: e.target.value})
                      }/>
                  </div>
                </div>
              )}
              {(checkValue.findIndex(item=>item==3)>-1) && (
                <div
                  className="shop_item flex-space"
                  style={{marginTop: '13px'}}>
                  <div>
                    <label style={{marginRight: '10px'}}>京东店铺名称:</label>
                    <Input
                      style={{width: '140px'}}
                      placeholder="请输入店铺名称"
                      onChange={(e) =>
                        this.setState({jName: e.target.value})
                      }/>
                  </div>
                  <div>
                    <label style={{marginRight: '10px'}}>京东店铺链接:</label>
                    <Input
                      style={{width: '180px'}}
                      placeholder="请输入店铺链接"
                      onChange={(e) =>
                        this.setState({jUrl: e.target.value})
                      }/>
                  </div>
                </div>
              )}
              {(checkValue.findIndex(item=>item==4)>-1) && (
                <div
                  className="shop_item flex-space"
                  style={{marginTop: '13px'}}>
                  <div>
                    <label style={{marginRight: '10px'}}>拼多多店铺名称:</label>
                    <Input
                      style={{width: '124px'}}
                      placeholder="请输入店铺名称"
                      onChange={(e) =>
                        this.setState({pName: e.target.value})
                      }/>
                  </div>
                  <div>
                    <label style={{marginRight: '10px'}}>拼多多店铺链接:</label>
                    <Input
                      style={{width: '180px'}}
                      placeholder="请输入店铺链接"
                      onChange={(e) =>
                        this.setState({pUrl: e.target.value})
                      }/>
                  </div>
                </div>
              )}
              {(checkValue.findIndex(item=>item==5)>-1) && (
                <div
                  className="shop_item flex-space"
                  style={{marginTop: '13px'}}>
                  <div>
                    <label style={{marginRight: '10px'}}>抖店店铺名称:</label>
                    <Input
                      style={{width: '140px'}}
                      placeholder="请输入店铺名称"
                      onChange={(e) =>
                        this.setState({dName: e.target.value})
                      }/>
                  </div>
                  <div>
                    <label style={{marginRight: '10px'}}>抖店店铺链接:</label>
                    <Input
                      style={{width: '180px'}}
                      placeholder="请输入店铺链接"
                      onChange={(e) =>
                        this.setState({dUrl: e.target.value})
                      }/>
                  </div>
                </div>
              )}
              {(checkValue.findIndex(item=>item==6)>-1) && (
                <div
                  className="shop_item flex-space"
                  style={{marginTop: '13px'}}>
                  <div>
                    <label style={{marginRight: '10px'}}>其他店铺名称:</label>
                    <Input
                      style={{width: '140px'}}
                      placeholder="请输入店铺名称"
                      onChange={(e) =>
                        this.setState({oName: e.target.value})
                      }/>
                  </div>
                  <div>
                    <label style={{marginRight: '10px'}}>其他店铺链接:</label>
                    <Input
                      style={{width: '180px'}}
                      placeholder="请输入店铺链接"
                      onChange={(e) =>
                        this.setState({oUrl: e.target.value})
                      }/>
                  </div>
                </div>
              )}
            </div>
            <div style={{marginTop: 20}} className="flex-start">
              <div>要开通权限的账号：<span style={{color: 'red'}}>{phone}</span></div> <div style={{marginLeft: 12}}>开通时间：<span style={{color: 'red'}}>{nowDate}</span></div>
            </div>
            <div style={{marginTop: 20}}>
              客户您好， 请问您是否可以遵守以上用户须知？(如果可以，我们将给您审核通过账户；如果不可以，我们将无法通过审核)
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
export default GoodsConfirm;
