/*
 * loading状态
 */

import React from "react";
import { Spin } from "antd";

class Loading extends React.Component {
  render() {
    return (
      <div style={{ margin: "50px auto" }}>
        <Spin size="large" />
      </div>
      /* <div className="su-loader"></div> */
    );
  }
}
export default Loading;
