/* 推广赚钱 */
import React, { Component } from "react";
import { message, Button, Table } from "antd";
import { API, history } from "../../../static/js/common";
import { CopyToClipboard } from "react-copy-to-clipboard";

const columns = [
  {
    title: "注册账户",
    dataIndex: "mobile",
    align: "center",
  },
  {
    title: "注册时间",
    dataIndex: "createTime",
    align: "center",
  },
  {
    title: "下单包裹数",
    dataIndex: "parcelNum",
    align: "center",
  },
  {
    title: "累计分成",
    dataIndex: "totalCommission",
    align: "center",
    render: (e) => {
      return <div>￥{(e / 100).toFixed(2)}</div>;
    },
  },
];

class Invite extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      pageSize: 10,
      invite_data: {},
      isAuth: 1
    };
  }

  componentDidMount() {
    API.getUserInvite({}).then((res) => {
      if (res.status * 1 === 0) {
        this.setState({ ...res.extData });
      } else {
        message.error(res.message || "获取信息失败！");
      }
    });
    this.getInviteList();
    API.getAuthInfo({}).then((res) => {
      if (res.status * 1 === 0) {
        const data = res.extData;
        this.setState({
          isAuth: parseInt(data.needRealName)
        })
      }
    })
  }

  getInviteList = () => {
    const { page, pageSize } = this.state;
    const reqData = { page, pageSize };
    API.getInviteList(reqData).then((res) => {
      if (res.status * 1 === 0) {
        this.setState({ invite_data: res.extData || {} });
      } else {
        message.error(res.message || "数据加载错误");
      }
    });
  };

  render() {
    const {
      totalCommission,
      // remainCommission,
      inviteNum,
      inviteUrl,
      page,
      pageSize,
      invite_data,
      isAuth
    } = this.state;
    return (
      <div>
        {isAuth == 1 ? '' : (
          <div>
            <div className="recharge">我的推广</div>
            <div>
              通过您的邀请链接注册的商家用户，每支付一单包裹，您可得到0.1元现金奖励，长期有效。
            </div>
            <div className="invite_url">
              礼品批发商城，一件起发，多仓可选，发货成本低至1.75元/单
              {/* <span style={{ margin: "0 20px" }}>{inviteUrl}</span>
              <CopyToClipboard
                text={`礼品批发商城，一件起发，多仓可选，发货成本低至1.75元/单 ${inviteUrl}`}
                onCopy={() => message.success("成功复制邀请链接")}
              >
                <Button>复制</Button>
              </CopyToClipboard> */}
            </div>
          </div>
        )}
        <div>
          <div className="recharge">
            推广收益
            <Button
              style={{ marginLeft: 10 }}
              onClick={(_) =>
                history.push({
                  pathname: "/seller/finance/withdraw",
                })
              }
            >
              提现
            </Button>
          </div>
          <div className="flex-start">
            <div className="invite_info">
              累计分成
              <span>
                ￥{totalCommission ? (totalCommission / 100).toFixed(2) : 0.0}
              </span>
            </div>
            {/* <div className="invite_info">
              可提分成
              <span>
                ￥{remainCommission ? (remainCommission / 100).toFixed(2) : 0.0}
              </span>
            </div> */}
            <div className="invite_info">
              累计邀请人数
              <span>{inviteNum}</span>
            </div>
          </div>
        </div>
        <div>
          <div className="recharge">邀请记录</div>
          <div>
            <Table
              columns={columns}
              dataSource={invite_data.list || []}
              rowKey="createTime"
              pagination={{
                current: page,
                pageSize,
                total: invite_data.total * 1 || 0,
                showSizeChanger: false,
                onChange: (page) => {
                  this.setState({ page }, (_) => {
                    this.getInviteList();
                  });
                },
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}
export default Invite;
