/* 实名认证 */
import React, {Component} from "react";
import {message, Button, Cascader, Modal, Input, Radio, Upload} from "antd";
import {API, imgRoot, ROOT} from "../../../static/js/common";
import {ExclamationCircleFilled, LeftOutlined} from '@ant-design/icons';

// 文件格式大小验证
function beforeUpload(file) {
  const isLt1M = file.size / 1024 / 1024 < 10;
  if (!isLt1M) {
    message.error("上传的文件不能大于10MB!");
  }
  return isLt1M;
}

class Auth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is_send: false,
      getcode: "获取验证码",
      isShowModal: false,
      isShowOpen: false,
      loading: false,
      isShowAuth: 0,

      realPhone: "",
      storeType: '',
      shopNick: '',
      realName: '',
      realIdnum: '',
      smsCode: '',
      shopUrl: '',
      faceAuthMode: 'ZHIMACREDIT',
      fileList: [],
      flowId: null,
      authQrcode: '',
      showQrcode: false,
      isPass: false,
      showSuccess: false,
      storeLoading: false,
    };
  }

  componentDidMount() {
  }

  waitingVefiry = () => {
    // 获取验证码倒计时
    let countdown = 60;
    if (this.state.is_send) return;
    clearInterval(window._verify_timer);
    window._verify_timer = null;
    this.setState({
      is_send: true,
      getcode: `重新获取(${countdown}s)`,
    });
    window._verify_timer = setInterval(() => {
      countdown--;
      let tips = `重新获取(${countdown}s)`, send = true;
      if (countdown === 0) {
        clearInterval(window._verify_timer);
        tips = "获取验证码";
        send = false;
      }
      this.setState({getcode: tips, is_send: send});
    }, 1000);
  };

  getVerifyCode = () => {
    // 获取验证码
    const {realPhone, is_send, faceAuthMode} = this.state;
    if (is_send) return;
    const mobile = realPhone.replace(/\s/g, "");
    if (!/^(13[0-9]|14[0-9]|15[0-9]|16[0-9]|17[0-9]|18[0-9]|19[0-9])\d{8}$/.test(mobile)) {
      message.error("手机号码输入有误！", 1);
      return;
    }
    API.getAuthSmsCode({
      mobile,
      sendType: faceAuthMode === 'ZHIMACREDIT' ? 'person' : 'store'
    }).then((res) => {
      if (res.status * 1 === 0) {
        message.success("发送成功", 1.2);
        this.waitingVefiry();
      } else {
        message.error(res.message || "验证码发送失败！", 1.2);
        this.setState({
          is_send: false,
        });
      }
    });
  };

  onSubmitPerson = () => {
    const {faceAuthMode, realName, realIdnum, realPhone, smsCode} = this.state;
    if (!realName) {
      message.error("请输入姓名");
      return;
    }
    if (!realIdnum) {
      message.error("请输入身份证号");
      return;
    }
    if (!/^(13[0-9]|14[0-9]|15[0-9]|16[0-9]|17[0-9]|18[0-9]|19[0-9])\d{8}$/.test(realPhone)) {
      message.error("请输入正确的手机号");
      return;
    }
    if (!smsCode) {
      message.error("请输入验证码");
      return;
    }
    this.setState({loading: true})
    API.postSavePersonAuth({faceAuthMode, realName, realIdnum, realPhone, smsCode}).then((res) => {
      if (res.status * 1 === 0 && res.extData) {
        const info = res.extData.info ? res.extData.info : res.extData;
        this.setState({
          flowId: info.flowId,
        })
        if(info.qrcodeUrl) {
          this.setState({
            authQrcode: info.qrcodeUrl,
            showQrcode: true
          })
          window._auth_timer = setInterval(() => {
            this.checkAuth()
          }, 2000);
        }
      } else {
        message.error(res.message);
      }
      this.setState({loading: false})
    });
  };

  onCommitShop = () => {
    const {storeType, shopNick, shopUrl, fileList, realName, realPhone, smsCode} = this.state;
    if (!storeType) {
      message.error("请选择电商平台");
      return;
    }
    if (!shopNick) {
      message.error("请输入店铺名称");
      return;
    }
    if (!shopUrl) {
      message.error("请输入店铺链接");
      return;
    }
    if (!fileList.length) {
      message.error("请上传店铺后台截图");
      return;
    }
    if (!realName) {
      message.error("请输入姓名");
      return;
    }
    if (!/^(13[0-9]|14[0-9]|15[0-9]|16[0-9]|17[0-9]|18[0-9]|19[0-9])\d{8}$/.test(realPhone)) {
      message.error("请输入正确的手机号");
      return;
    }
    if (!smsCode) {
      message.error("请输入验证码");
      return;
    }
    this.setState({storeLoading: true})
    API.postSaveStoreAuth({storeType, realName, shopNick, realPhone, smsCode, shopUrl, attachUrl: fileList[0].url}).then((res) => {
      if (res.status * 1 === 0) {
        this.setState({
          showSuccess: true
        })
      } else {
        message.error(res.message);
      }
      this.setState({storeLoading: false})
    });
  };

  checkAuth = () => {
    const {flowId, isPass} = this.state;
    if (!flowId) {
      return;
    }
    if(isPass) {
      this.stop();
      return;
    }
    API.checkFaceAuth({flowId}).then((res) => {
      if (res.status * 1 === 0) {
        if(res.extData.eqbStatus === 'SUCCESS') {
          this.setState({
            isPass: true,
            showSuccess: true,
            showQrcode: false
          })
        }else if(res.extData.eqbStatus === 'FAIL') {
          message.error(res.extData.eqbMessage);
        }
      } else {
        message.error(res.message);
      }
    });
  };

  stop = () => {
    clearInterval(window._auth_timer);
    window._auth_timer = null;
  };

  onCloseCode = () => {
    this.setState({
      showQrcode: false
    })
    this.stop()
  };

  onFinish = () => {
    this.setState({
      showSuccess: false,
      showQrcode: false,
      isPass: true
    })
    this.props.closeAuth()
  };

  // 上传文件
  handleChange = ({ fileList }) => {
    const file = fileList.map(iterator => {
      if (iterator.status === 'done' && iterator.response.extData) {
        iterator.url = iterator.response.extData.fileUrl;
      }
      return iterator;
    });
    this.setState({fileList: file})
  };

  onBack = () => {
    const {isShowAuth} = this.state;
    if(isShowAuth == 1 || isShowAuth == 2) {
      this.setState({isShowAuth: 0})
    } else {
      this.props.closeAuth()
    }
  };

  getContainer = () => {
    return this.container;
  };

  saveContainer = container => {
    this.container = container;
  };

  render() {
    const {
      realName,
      getcode,
      realPhone,
      smsCode,
      realIdnum,
      shopNick,
      shopUrl,
      isShowAuth,
      fileList,
      loading,
      showQrcode,
      faceAuthMode,
      authQrcode,
      showSuccess,
      storeLoading
    } = this.state;

    const optionsShop = [
      {label: '淘宝', value: 1},
      {label: '天猫', value: 2},
      {label: '京东', value: 3},
      {label: '抖音', value: 5},
      {label: '拼多多', value: 4},
      {label: '快手', value: 6},
      {label: '阿里巴巴', value: 7},
      {label: '其他', value: 8}
    ];

    return (
      <div ref={this.saveContainer}>
        <div className="auth-title">
          <span onClick={this.onBack}><LeftOutlined style={{cursor: 'pointer', marginRight: 4}}/></span>
          {isShowAuth == 1 ? '实名认证-个人认证' : (isShowAuth == 2 ? '实名认证-店铺认证' : '实名认证')}
        </div>
        {isShowAuth == 0 && (<div className="auth-title-sub" style={{marginTop: '30px'}}>当前账号尚未认证</div>)}
        <div style={{margin: '20px 0 4px'}}>
          <ExclamationCircleFilled style={{color: '#f94a3d', marginRight: 4}}/>
          <span>{isShowAuth == 1 ? '填写的信息仅用于完成本次认证，请放心填写。' : (isShowAuth == 2 ? '提交相关资料，客服会在一个工作日内审核。 ' : '为满足您的业务需求，需要您使用认证服务。')}</span>
        </div>
        {isShowAuth == 1 && (
          <div>
            <div style={{marginBottom: 4}}>
              <ExclamationCircleFilled style={{color: '#f94a3d', marginRight: 4}}/>
              <span>仅支持大陆人士，填写中国大陆居民身份证姓名/证件号。</span>
            </div>
            <div>
              <ExclamationCircleFilled style={{color: '#f94a3d', marginRight: 4}}/>
              <span>平台账户仅限于自己使用，严禁非法牟利注册/倒卖账号行为，违规者双方均须承担相应法律责任！</span>
            </div>
          </div>
        )}
        {isShowAuth == 0 && (<div>
            <div className="auth-item">
              <div className="flex-start">
                <div className="left">
                  <img src={`${imgRoot}/personalAuth.png`} alt="" width="250px"/>
                </div>
                <div className="center">
                  <p>适用于个人用户，账户归属个人</p>
                  <p>支持支付宝扫脸认证和腾讯云扫脸认证</p>
                  <p>审核快/资料少/认证实时通过</p>
                </div>
              </div>
              <div className="right">
                <button className="bth" onClick={() => {
                  this.setState({isShowAuth: 1})
                }}>立即选择
                </button>
              </div>
            </div>
            {/*<div className="auth-item">*/}
            {/*  <div className="flex-start">*/}
            {/*    <div className="left">*/}
            {/*      <img src={`${imgRoot}/shopAuth.png`} alt="" width="250px"/>*/}
            {/*    </div>*/}
            {/*    <div className="center">*/}
            {/*      <p>适用于电商店铺，账户归属店铺</p>*/}
            {/*      <p>可开具增值税电子发票</p>*/}
            {/*      <p>需要提供店铺链接，店铺商家中心登录截图</p>*/}
            {/*      <p>提交审核后需要客服人员审核</p>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*  <div className="right">*/}
            {/*    <button className="bth" onClick={() => {*/}
            {/*      this.setState({isShowAuth: 2})*/}
            {/*    }}>立即选择*/}
            {/*    </button>*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>
        )}
        {isShowAuth == 1 && (
          <div className="auth-form">
            <div className="flex-start">
              <label className="label"><i style={{color: 'red',marginRight: 2}}>*</i>认证方式</label>
              <Radio.Group defaultValue="ZHIMACREDIT" buttonStyle="solid" onChange={(e) => {this.setState({faceAuthMode: e.target.value})}}>
                <Radio.Button value="ZHIMACREDIT">支付宝人脸识别</Radio.Button>
                <Radio.Button value="TENCENT">腾讯云人脸识别</Radio.Button>
              </Radio.Group>
            </div>
            <div className="flex-start">
              <label className="label"><i style={{color: 'red',marginRight: 2}}>*</i>姓名</label>
              <Input
                className="input"
                value={realName}
                onChange={(e) => this.setState({realName: e.target.value})}
                placeholder="请输入姓名"/>
            </div>
            <div className="flex-start">
              <label className="label"><i style={{color: 'red',marginRight: 2}}>*</i>身份证号码</label>
              <Input
                className="input"
                value={realIdnum}
                onChange={(e) => this.setState({realIdnum: e.target.value})}
                placeholder="请输入身份证号码"/>
            </div>
            <div className="flex-start mobile">
              <label className="label"><i style={{color: 'red',marginRight: 2}}>*</i>手机号码</label>
              <Input
                className="input"
                value={realPhone}
                onChange={(e) => this.setState({realPhone: e.target.value})}
                placeholder="请输入手机号码"/>
              <Button className="bth" onClick={this.getVerifyCode}>{getcode}</Button>
            </div>
            <div className="flex-start">
              <label className="label"><i style={{color: 'red',marginRight: 2}}>*</i>短信验证码</label>
              <Input
                className="input"
                value={smsCode}
                onChange={(e) => this.setState({smsCode: e.target.value})}
                placeholder="请输入短信验证码"/>
            </div>
            <div className="getCode"><Button type="primary" loading={loading} onClick={this.onSubmitPerson}>获取二维码</Button></div>
          </div>
        )}
        {isShowAuth == 2 && (
          <div className="auth-form">
            <div className="flex-start">
              <label className="label"><i style={{color: 'red',marginRight: 2}}>*</i>电商平台</label>
              <Cascader className="cascader" options={optionsShop} onChange={(e) => {this.setState({storeType: e[0]})}} placeholder="请选择电商平台"/>
            </div>
            <div className="flex-start">
              <label className="label"><i style={{color: 'red',marginRight: 2}}>*</i>店铺名称</label>
              <Input
                className="input"
                value={shopNick}
                onChange={(e) => this.setState({shopNick: e.target.value})}
                placeholder="请输入店铺名称"/>
                <span className="shopTips">主旺旺名、店铺名、京东账号  </span>
            </div>
            <div className="flex-start">
              <label className="label"><i style={{color: 'red',marginRight: 2}}>*</i>店铺链接</label>
              <Input
                className="input"
                value={shopUrl}
                onChange={(e) => this.setState({shopUrl: e.target.value})}
                placeholder="请输入店铺链接"/>
            </div>
            <div className="flex-start uploader">
              <label className="label"><i style={{color: 'red',marginRight: 2}}>*</i>店后台截图</label>
              <Upload
                className="avatar-uploader"
                fileList={fileList}
                beforeUpload={beforeUpload}
                action={`${ROOT}/upload/filecos`}
                onChange={this.handleChange}>
                {fileList.length  ? '' : (
                  <Button type="primary" style={{marginRight: 30}}>选择图片</Button>
                )}
              </Upload>
              <span style={{color: '#A0A6B4', cursor: 'pointer'}} onClick={()=>{
                window.open('https://dfcdn.shuidilipin.com/source/daifa/shop_img.png', '_blank')
              }}>示例图<ExclamationCircleFilled  style={{color: '#f94a3d', marginLeft: 4}}/></span>
            </div>
            <div className="flex-start">
              <label className="label"><i style={{color: 'red',marginRight: 2}}>*</i>联系人</label>
              <Input
                className="input"
                value={realName}
                onChange={(e) => this.setState({realName: e.target.value})}
                placeholder="请输入联系人"/>
            </div>
            <div className="flex-start mobile">
              <label className="label"><i style={{color: 'red',marginRight: 2}}>*</i>联系方式</label>
              <Input
                className="input"
                value={realPhone}
                onChange={(e) => this.setState({realPhone: e.target.value})}
                placeholder="请输入联系方式"/>
              <Button className="bth" onClick={this.getVerifyCode}>{getcode}</Button>
            </div>
            <div className="flex-start">
              <label className="label"><i style={{color: 'red',marginRight: 2}}>*</i>短信验证码</label>
              <Input
                className="input"
                value={smsCode}
                onChange={(e) => this.setState({smsCode: e.target.value})}
                placeholder="请输入短信验证码"/>
            </div>
            <div className="getCode"><Button type="primary" loading={storeLoading} onClick={this.onCommitShop}>提交审核</Button></div>
          </div>
        )}
        <Modal
          visible={showQrcode}
          centered
          width="500px"
          onCancel={this.onCloseCode}
          footer={null}
          destroyOnClose>
          <div className="introduction-wrapper">
            <div className="title">
              {faceAuthMode === 'ZHIMACREDIT' ? '支付宝' : '腾讯云'}人脸识别
            </div>
            <div className="desc-wrapper">
              请使用<span style={{color: 'red'}}>{faceAuthMode === 'ZHIMACREDIT' ? '支付宝APP' : '微信APP'}</span>扫描二维码，并依照提示完成人脸识别
            </div>
            <div className="qrCode">
              <div className="img"><img alt="" src={authQrcode} width="180"/></div>
            </div>
          </div>
        </Modal>
        <Modal
          visible={showSuccess}
          centered
          width="400px"
          footer={null}
          destroyOnClose>
          <div className="introduction-wrapper">
            <div className="success-icon">
              <img className="img" src={`${imgRoot}/success.png`} alt=""/>
            </div>
            <div className="success-desc">
              {isShowAuth == 1 ? '您本次的个人认证已通过，点击完成返回' : '您的店铺认证已提交，请等待审核，点击完成返回'}
            </div>
            <div className="success-btn">
              <Button className="btn" type="primary" shape="round" size="large" onClick={this.onFinish}>完成</Button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default Auth;
