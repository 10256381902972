// 批量获取底单
import React from "react";
import {Input, Button, message, Modal, Carousel} from "antd";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {API} from "../../../static/js/common";
import JSZip from 'jszip';
import {saveAs} from 'file-saver';
import moment from 'moment';
import {CopyOutlined, LeftOutlined, RightOutlined} from '@ant-design/icons'

const { TextArea } = Input;

class BatchOrder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      title1: "",
      errorList: [],
      errorNumber: [],
      orderImages: [],
      orderList: [],
      previewVisible: false,
      downloadLoading: false,
      loading: false,
    };
  }

  componentDidMount() {}

  handlePublish = () => {
    const { title } = this.state;
    if (!title) {
      message.error("请填写需要获取的物流单号");
      return;
    }
    this.setState({loading: true})
    let orderNo = [];
    if(title.includes(',')){
      orderNo = title.split(',');
    } else {
      orderNo = title.split('\n');
    }

    let new_title = [];
    orderNo.forEach(item => {
      let item_n = item.trim();
      if(item_n) {
        item_n = item_n.match(/[a-zA-Z0-9]/g);
        if(item_n && item_n.length) {
          new_title.push(item_n.join(''));
        }
      }
    });
    API.getDwcOrderList({courierSns: new_title.join(',')}).then(res => {
      if (res.status * 1 === 0) {
        const data = res.extData.data;
        if(data.errorList.length) {
          let number = [];
          data.errorList.forEach(error => {
            number.push(error.split('--')[0].trim())
          })
          this.setState({errorList: data.errorList, errorNumber: number})
        }
        if(Object.keys(data.data).length) {
          const orders = data.data;
          let orderImages = [];
          let orderList = [];
          for(var i in orders) {
            if(orders[i].status === 'success') {
              orderImages.push({
                image: orders[i].preview_image,
                number: orders[i].single_number
              })
              orderList.push(orders[i].preview_image + ', ' + orders[i].single_number)
            }
          }
          this.setState({orderImages: orderImages, orderList: orderList})
        }
      } else {
        message.error(res.message || "数据加载错误");
      }
      this.setState({loading: false})
    })
    // YT6819786243653,YT6819791457462,YT6819789247091,YT6819792885173,YT6819791457777,YT6819790046477,YT6819787750232,YT6819786944471,YT6819792127176,YT6819785576981
  };

  handleFilter = () => {
    const {title} = this.state;
    if (!title) {
      message.error("请填写需要获取的物流单号");
      return;
    }
    let orderNo = [];
    if(title.includes(',')){
      orderNo = title.split(',');
    } else {
      orderNo = title.split('\n');
    }
    orderNo = [...new Set(orderNo)];
    if(orderNo.length > 30) {
      orderNo = orderNo.slice(0,30)
    }
    if(title.includes(',')) {
      this.setState({title: orderNo.join(',')})
    } else {
      this.setState({title: orderNo.join('\n')})
    }
  };

  handleClear = () => {
    this.setState({title: '',title1: '', errorList: [], orderImages: [], orderList: [], errorNumber: [],});
  };

  showImage = (idx) => {
    this.setState({previewVisible: true}, () => {
      this.card && this.card.goTo(idx)
    });
  };

  getFile = (url) => {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onload = function () {
        resolve(this.response);
      };
      xhr.open('GET', "https://images.weserv.nl/?url=" + url, true);
      xhr.responseType = 'blob';
      xhr.send();
    })
  };

  handleDownloadImage =() => {
    const { orderImages } = this.state;
    if(!orderImages.length) {
      message.error("暂无可下载的底单");
      return;
    }
    this.setState({downloadLoading: true});
    let promises = [];
    orderImages.forEach(item=> {
      const promise = this.getFile(item.image).then(data => {
        item.data = data
      })
      promises.push(promise)
    })

    Promise.all(promises).then(() => {
      this.fileZipDownload('底单('+ moment().format('YYYYMMDDHHmmss')  +').zip', orderImages)
    }).catch(() => {
      message.error("下载失败");
      this.setState({downloadLoading: false});
    }).finally(() => {
      this.setState({downloadLoading: false});
      }
    )
  };

  fileZipDownload = (fileName = 'download.zip', imageList) => {
    return new Promise(resolve => {
      const zip = new JSZip();
      //const img = zip.folder('images');
      imageList.forEach(image => {
        zip.file(image.number+'.jpg', image.data);
      });
      zip.generateAsync({type: 'blob'}).then(content => {
        saveAs(content, fileName);
        resolve();
      });
    });
  };

  render() {
    const {
      title,
      title1,
      errorList,
      orderImages,
      orderList,
      previewVisible,
      errorNumber,
      downloadLoading,
      loading
    } = this.state;

    return (
      <div>
        <div style={{ fontSize: 20, fontWeight: 600, marginBottom: 20, display: 'none' }}>
          批量获取仓库底单<span style={{ fontSize: 15, fontWeight: 500, marginLeft: 6 }}>(仅支持邓**仓库)</span>
        </div>
        <div style={{marginTop: 10}}>
          <Button
            type="primary"
            style={{ width: 80}}
            onClick={this.handleClear}>
            清空
          </Button>
          {/*<Button*/}
          {/*  type="primary"*/}
          {/*  style={{ width: 100, marginLeft: 20 }}*/}
          {/*  loading={downloadLoading}*/}
          {/*  onClick={this.handleFilter}>*/}
          {/*  过滤单号*/}
          {/*</Button>*/}
          <Button
            type="primary"
            style={{ width: 80, marginLeft: 20  }}
            loading={loading}
            onClick={this.handlePublish}>
            查询
          </Button>
          <Button
            type="primary"
            style={{ width: 100, marginLeft: 20 }}
            loading={downloadLoading}
            onClick={this.handleDownloadImage}>
            批量下载
          </Button>
          <span style={{fontSize: '15px', color: '#262a33', marginLeft: '30px'}}>输入：<span style={{color: 'red', fontWeight: 'bold', marginRight: '4px'}}>{title1 ? (title1.includes(',') ? title1.split(',').length : title1.split('\n').length) : 0}</span>条，
            过滤出：<span style={{color: 'red', fontWeight: 'bold', marginRight: '4px'}}>{title ? (title.includes(',') ? title.split(',').length : title.split('\n').length) : 0}</span>条，
            成功：<span style={{color: 'red', fontWeight: 'bold', marginRight: '4px'}}>{orderImages.length || 0}</span>条，
            失败：<span style={{color: 'red', fontWeight: 'bold', marginRight: '4px'}}>{errorList.length || 0}</span>条
          </span>
        </div>
        <div style={{margin: "20px 0"}} className="flex-start">
          <div style={{display: 'flex'}}>
            <TextArea
              placeholder={"请输入需要获取底单的物流单号，多个以逗号或换行分隔"}
              rows={30}
              value={title}
              style={{width: 400, height: 200}}
              onChange={(e) => this.setState({title: e.target.value, title1: e.target.value})}
              onBlur={() => this.handleFilter()}/>
            <div style={{marginLeft: '20px', display: 'flex', flexWrap: 'wrap'}}>
              {orderImages.map((item, idx) => (
                <div className="carousel-box" key={idx} style={{marginRight: 6, cursor: 'pointer'}}
                     onClick={() => this.showImage(idx)}>
                  <img src={item.image} width={80} height={110}/>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div style={{ fontSize: 15, fontWeight: 600 }}>
          底单链接
          <CopyToClipboard
            text={
              orderList.length > 0
                ? orderList.join('\n')
                : "无底单"
            }
            onCopy={() =>
              message.success(`成功复制${orderList.length}个底单信息`)
            }>
            <Button
              type="primary"
              size="small"
              style={{marginLeft: 10, width: 70}}>
              一键复制
            </Button>
          </CopyToClipboard>
        </div>
        <div style={{marginTop: '6px'}} className="line-order-wrapper">
          {orderList.map((item,idx) => (
            <div key={idx} style={{padding: 3}}>
              {item}
              <CopyToClipboard
                text={item}
                onCopy={() =>
                  message.success(`复制成功`)
                }>
                <CopyOutlined style={{marginLeft: 4, color: '#999'}}/>
              </CopyToClipboard>
            </div>
          ))}
        </div>

        {errorList.length ? (
          <div>
            <div style={{fontSize: 15, fontWeight: 600, marginTop: 20}}>
              错误单号
              <CopyToClipboard
                text={
                  errorNumber.length > 0
                    ? errorNumber.join(',')
                    : "无错误单号"
                }
                onCopy={() =>
                  message.success(`成功复制${errorNumber.length}个错误单号`)
                }>
                <Button
                  type="primary"
                  size="small"
                  style={{marginLeft: 10, width: 70}}>
                  一键复制
                </Button>
              </CopyToClipboard>
            </div>
            <div style={{marginTop: '6px'}} className="line-order-wrapper">
              {errorList.map((item,idx) => (
                <div key={idx} style={{padding: 3}}>
                  {item}
                </div>
              ))}
            </div>
          </div>
        ) : ''}

        {/* 查看图片 */}
        <Modal
          className="image-wrapper"
          visible={previewVisible}
          title=""
          footer={null}
          onCancel={() => {
            this.setState({previewVisible: false})
          }}>
          {orderImages.length > 1 && (
            <div>
              <div
                className="carousel-left"
                onClick={() => {
                  // 通过获取走马灯dom，调用Carousel的prev()方法
                  this.card.prev();
                }}>
                <LeftOutlined/>
              </div>
              <div
                className="carousel-right"
                onClick={() => {
                  // 通过获取走马灯dom，调用Carousel的next()方法
                  this.card.next();
                }}>
                <RightOutlined/>
              </div>
            </div>
          )}
          <Carousel autoplay ref={e=>{this.card = e}}>
            {orderImages.length && orderImages.map((item,idx) => (
              <div className="carousel-box" key={idx}>
                <img src={item.image} onClick={() => {console.log(this.card)}} style={{margin: '0 auto'}}/>
                <div className="idx">{idx +1}</div>
              </div>
            ))}
          </Carousel>
        </Modal>
      </div>
    );
  }
}
export default BatchOrder;
