/* eslint-disable array-callback-return */
/*
 * 帮助中心
 */
import React from "react";
import {API} from "../../../static/js/common";
import eventBus from "../../../static/js/events";
import {
  message,
} from "antd";

class CollectList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      path: 'question',
      page: 1,
      pageSize: 100,
      article_data: {},
    };
  }

  componentDidMount() {
    if(this.getUrlParams('path')){
      this.setState({path: this.getUrlParams('path')});
    }
    this.getArticleList();

    eventBus.on('changeHelpType', () => {
      this.setState({path: 'express'})
    })
  }

  getUrlParams = (name) => {
    const reg = new RegExp(`(^|&)${ name}=([^&]*)(&|$)`);
    const r = this.props.location.search.substr(1).match(reg);
    if (r != null) return  decodeURIComponent(r[2]); return null;
  };

  getArticleList = () => {
    const { page, pageSize } = this.state;
    const reqData = { page, pageSize, type: 1 };
    API.postArticleList(reqData).then((res) => {
      if (res.status * 1 === 0) {
        this.setState({ article_data: res.extData || {} });
      } else {
        message.error(res.message || "数据加载错误");
      }
    });
  };

  render() {
    const {
      path, article_data
    } = this.state;
    const list = article_data.list || [];
    return (
      <div className="help-center">
        <div className="help_tab">
          <div className="tab_title">帮助中心</div>
          <div className="help_tab_sub">
            <div className={`tab_sub_title ${path === 'question' ? "help_active_tab" : ""}`} onClick={()=>this.setState({path: 'question'})}>
              常见问题
            </div>
            <div className={`tab_sub_title ${path === 'express' ? "help_active_tab" : ""}`} onClick={()=>this.setState({path: 'express'})}>
              虚假物流识别
            </div>
          </div>
        </div>
        <div className="help_box">
          {path === 'express' && (
            <div className="img_box">
              <img src="https://dfcdn.shuidilipin.com/source/daifa/wlinfo.png" width="100%"/>
            </div>
          )}
          {path === 'question' && (
            <div>
              <div className="recharge">常见问题</div>
              {list.map((item) => (
                <div className="question" key={item.articleId}>
                  <div>{item.title}</div>
                  <div dangerouslySetInnerHTML={{ __html: item.content }} />
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default CollectList;
