/* eslint-disable array-callback-return */
import React from "react";
import { withRouter } from "react-router-dom";

const routerTitle = [
  { router: "/", title: "首页" },
  { router: "/all", title: "所有商品" },
  { router: "/login", title: "登录" },
  { router: "/register", title: "注册" },
  { router: "/forget", title: "忘记密码" },
  { router: "/seller/goods/goodsinfo", title: "填写订单信息" },
  { router: "/seller/goods/goodsconfirm", title: "确认订单" },
  { router: "/seller/order/orderlist", title: "订单列表" },
  { router: "/seller/order/parcellist", title: "包裹列表" },
  { router: "/seller/finance/recharge", title: "我要充值" },
  { router: "/seller/finance/withdraw", title: "我要提现" },
  { router: "/seller/finance/detail", title: "资金明细" },
  { router: "/seller/finance/paylist", title: "赔付明细" },
  { router: "/seller/help/notice", title: "发货公告" },
  { router: "/seller/help/noticeinfo", title: "公告详情" },
  { router: "/seller/help/question", title: "常见问题" },
  { router: "/seller/promote/invite", title: "推广赚钱" },
];

class ScrollToTop extends React.Component {
  componentDidMount() {
    document.title = `水滴礼品—专业礼品批发商城-${this.changeTitle(
      this.props.location.pathname
    )}`;
  }
  componentDidUpdate(prevProps) {
    const path = this.props.location.pathname;
    if (path !== prevProps.location.pathname) {
      document.getElementById("root").scrollIntoView(true);
    }
    document.title = `水滴礼品—专业礼品批发商城-${this.changeTitle(
      path
    )}`;
  }

  changeTitle = (path) => {
    let title = "首页";
    routerTitle.map((item) => {
      if (item.router === path) {
        title = item.title;
      }
    });
    return title;
  };

  render() {
    return this.props.children;
  }
}
export const PageContainer = withRouter(ScrollToTop);
