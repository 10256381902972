import "whatwg-fetch";
import { b64hamcsha1, HexMD5 } from "./hash";
import COS from 'cos-js-sdk-v5'

let cos = new COS({
  getAuthorization: function (params, callback) {
    var authorization = COS.getAuthorization({
      SecretId: "AKIDnRq1zrHf9eLzSs790kIgwxnOkrorseBY",
      SecretKey: "JNDoAHAoDPPEsMlDXyFYjfbPzKPDn8D2",
      Method: params.Method,
      Key: params.Key
    });
    callback(authorization);
  }
});

export const upload = function (filepath, dirname, type) {
  return new Promise((resolve) => {
    //上传文件至腾讯云
    cos.putObject({
      Bucket: "dfcdn-1325879361",
      Region: "ap-shanghai",
      Key: createFileName(dirname, type),
      Body: filepath,
      StorageClass: 'STANDARD',
      onProgress: function (progressData) {
        console.log('onProgress' + JSON.stringify(progressData));
      }
    }, function (err, data) {
      if (err) {
        resolve({
          status: "fail",
          imgUrl: filepath
        })
      } else {
        resolve({
          status: "success",
          imgUrl: changeImagePathName(data.Location)
        })
      }
    });
  })
};

//生成文件名
let createFileName = function (dirname, type = 'jpg') {
  //获取时间
  let date = new Date();
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();
  //let datetem = date.getTime().toString().substr(0, 10);
  month = month < 10 ? "0" + month : month;
  day = day < 10 ? "0" + day : day;
  //产生4位随机数
  let randomNum = '';
  for (let i = 0; i < 20; i++) {
    randomNum += Math.floor(Math.random() * 10)
  }
  let alldirname = "img/daifa/" + dirname + "/" + year + "/" + month + "/" + day + "/" + "sy_" + randomNum + '.' + type;
  return alldirname;
};

//修改图片路径名
let changeImagePathName = function (oldpath) {
  return "https://dfcdn.shuidilipin.com".concat(oldpath.substr(oldpath.indexOf('/')))
};

export function uploadFile(data, mode) {
  const bucketname = "image-lipindaifa";
  const img_domain = "https://dfcdn.shuidilipin.com";
  const username = "client";
  const password = "client1234";
  // mode   center 用户中心 temp 其他
  const save_key = `/img/daifa/${mode}/{year}/{mon}/{day}/sy_{random32}{.suffix}`;
  const url = `https://v0.api.upyun.com/${bucketname}`;
  const policy = btoa(
    JSON.stringify({
      bucket: bucketname,
      "save-key": save_key,
      expiration: parseInt(Date.parse(new Date()) + 3600),
    })
  );
  const signature = `UPYUN ${username}:${b64hamcsha1(
    HexMD5.MD5(password).toString(HexMD5.enc.Hex),
    `POST&/${bucketname}&${policy}`
  )}`;
  // 通过FormData将文件转成二进制数据
  const formData = new FormData();
  formData.append("upload", data);
  formData.append("file", data);
  formData.append("policy", policy);
  formData.append("authorization", signature);
  return fetch(url, {
    method: "POST",
    body: formData,
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.url) {
        const imgWidth = res["image-width"];
        const imgHeight = res["image-height"];
        const imgUrl = img_domain + res.url;
        const o = { imgUrl, imgWidth, imgHeight };
        return o;
      }
    });
}
