/*
 * common 公用方法及接口
 */
import { GET, POST } from "./fetch-request.js";
import _OS from "./os-info.js";
import { createFromIconfontCN } from "@ant-design/icons";
import { createHashHistory } from "history";
export const OS = _OS;

//路由跳转
export const history = createHashHistory();

// iOS android返回用户个人中心界面
// window.webkit.messageHandlers.js_call_oc_navPop.postMessage(null);
// window.NATIVE2.back2Login();

// 又拍云前缀
export const imgRoot = "https://dfcdn.shuidilipin.com/source/daifa";

// iconfont文件
export const IconFont = createFromIconfontCN({
  scriptUrl: "//at.alicdn.com/t/font_1935759_co641uln83m.js",
});

/**
 * 接口前缀
 * @development http://test.sucoupon.com
 * @production http://tapi.sucoupon.com
 */

/* export const ROOT = /df\.shuidilipin\.com/gi.test(window.location.host)
  ? "http://shuidilipin.com"
  : "http://lipin.test.axiandan.cn"; */

export const prefix = /\.shuidilipin\.com/gi.test(window.location.host);

const isTest = /csdf\.shuidilipin\.com|localhost|test\.shuidilipin\.com/gi.test(
  window.location.host
);

export const ROOT = isTest
  ? "https://dfapi.shuidilipin.com"
  : "https://dfapi.shuidilipin.com";

export const DTS_ROOT = isTest
  ? "https://dfapi.shuidilipin.com"
  : "https://dfapi.shuidilipin.com";

// export const ROOT = "https://dfapi.shuidilipin.com";http://172.31.8.104:9020;
// 192.168.124.4:9021

// export const DTS_ROOT = "https://shuidilipin.com";https://csdtsapi.shuidilipin.com;http://testzhishu.shuidilipin.com;http://csdfdtsapi.shuidilipin.com
// 172.31.8.104:9050

let apis = {
    //接口地址
    postLogin: "/user/login", // 用户登录
    postCaptchaLogin: "/user/loginv2", // 用户验证码登录
    getRegister: "/user/register", // 用户注册
    getForget: "/user/forgotPwd", // 用户忘记密码
    getLogin: "/user/logout", // 用户退出登录
    getUserInfo: "/user/info", // 用户信息
    getSmsCode: "/user/captcha", // 发送短信验证码
    postGoodsList: "/goods/list", // 商品列表
    postGoodsList2: "/goods/listv2", // 商品列表2
    getGoodsInfo: "/goods/info", // 商品信息
    getRecharge: "/alipay/recharge", // 用户充值
    getFinanceList: "/finance/amountList", // 用户资金记录
    getShareList: "/finance/commissionList", // 用户分成记录
    getRechargeList: "/finance/rechargeList", // 用户充值记录
    getWithdrawList: "/finance/withDrawList", // 用户提现记录
    getRechargeAmount: "/finance/amount", // 用户余额
    postReceiver: "/order/parseReceiver", // 生成收货人信息
    postSaveReceiver: "/order/save", // 保存收货人信息
    getOrderList: "/order/list", // 订单列表
    getParcelList: "/order/parcelList", // 订单包裹列表
    getParcel: "/order/parcel", // 包裹列表
    getCancel: "/order/cancel", // 取消订单
    getPayOrder: "/order/pay", // 支付订单
    getOrderInfo: "/order/info", // 订单详情
    getOrderCancel: "/order/cancel", // 取消订单
    getDownload: "/export/orderParcel", // 导出表格
    postWarehouse: "/goods/warehouse", // 仓库列表
    postBindAlipay: "/user/editAlipay", // 绑定、修改支付宝
    getWithdraw: "/finance/saveWithdraw", // 提现
    getUserInvite: "/user/invite", // 推广信息
    getInviteList: "/user/invitelog", // 邀请列表
    postArticleList: "/article/list", // 文章列表
    getArticleInfo: "/article/info", // 文章详情
    getSearchCourierInfo: "/order/wuliu", // 查询物流
    postPaidList: "/finance/paidList", // 用户赔付记录
    postCollectList: "/user/collect", // 用户收藏列表
    postHandleCollect: "/user/handleCollect", // 收藏、取消收藏
    postSelectOption: "/goods/const", //快递、地点、平台
    postSaveOrderRemark: "/order/saveremark", //编辑订单备注
    postUserNotice: "/user/notice", //个人中心公告
    postUserLimit: "/user/getUserOrderRights", //获取用户使用权限
    getCheckNumbers: "/sale/check", //单号检验
    postSaveServiceRequest: "/sale/savess", //保存售后申请订单信息
    postSaveOrderRequest: "/sale/savebottomsheet", //保存底单申请订单信息
    getServiceList: "/sale/saleservicelist", //售后申请列表
    getOrderRequestList: "/sale/bottomsheetlist", //底单申请列表
    getServiceDetailList: "/sale/ssdetail", //售后申请明细列表
    getMessageList: "/sale/getmessagelist", //留言列表
    sendMessage: "/sale/savemessage", //发送留言
    getDelMessage: "/sale/delmessage", //删除留言
    getTagMessage: "/sale/updatemessagestatus", //设置已读留言
    postDelOrder: "/order/batchDelOrder", //删除订单
    postDelParcel: "/order/batchDelParcel", //删除包裹
    getHomeArticle: "/article/centershow", //个人中心公告
    getFeedList: "/feedback/list", //我的反馈
    screenshot: "/user/screenshot", //截屏
    postSubmitFeed: "/feedback/create", //提交反馈
    getDelFeedRequest: '/feedback/delete', //删除反馈记录
    postRemarkParcel: '/order/batchSelParcel', //批量标记包裹
    getCancelList: '/order/parcelCancelMxList', //退单列表
    getDwcOrderList: '/order/getDwcBottomSheetByCourierSn', //自动获取底单
    getAuthInfo: '/user/currentUser', //获取用户实名信息
    postSavePersonAuth: '/auth/savePersonreal', //保存用户实名信息
    getAuthSmsCode: '/sms/sendSmsCode', //发送短信验证码
    checkFaceAuth: '/auth/faceCheck', //校验刷脸状态
    postSaveStoreAuth: '/auth/saveStorereal', //保存店铺实名信息
  },
  _api = {};

const _request = (path, is_post) => {
  if (
    path === "/user/login" ||
    path === "/user/register" ||
    path === "/user/loginout" ||
    path === "/user/resetpwd" ||
    path === "/user/info" ||
    path === "/user/v3/captcha" ||
    path === "/user/captcha/login"
  ) {
    return !is_post
      ? (params) => GET(DTS_ROOT + path, params)
      : (params) => POST(DTS_ROOT + path, params);
  } else {
    return !is_post
      ? (params) => GET(ROOT + path, params)
      : (params) => POST(ROOT + path, params);
  }
};

for (let key in apis) {
  _api[key] = _request(apis[key], /post/g.test(key));
}

export const API = _api;

// 时间格式转化
export const setTimeStr = (time, isSeconds = false, isText = false) => {
  let date = new Date(time * 1000);
  const pad = (n) => (n < 10 ? `0${n}` : n);
  let dateStr = `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(
    date.getDate()
  )}`;
  const dateSeconds = `${date.getHours()}:${pad(date.getMinutes())}:${pad(
    date.getSeconds()
  )}`;
  if (isSeconds) {
    dateStr = `${dateStr} ${dateSeconds}`;
  }
  if (isText) {
    dateStr = `${pad(date.getMonth() + 1)}月${pad(
      date.getDate()
    )}日 ${dateSeconds}`;
  }
  return dateStr;
};

// 时间倒计时
export const getCountDown = (endtime, isDay = false, isHour = false) => {
  // 秒级时间戳结束时间
  let day,
    hours,
    minute,
    second,
    timediff = endtime - new Date().getTime() / 1000;
  // console.log(timediff, 99)
  if (timediff < 0) return;
  day = parseInt(timediff / (24 * 3600));
  hours = parseInt((timediff - day * 3600 * 24) / 3600);
  minute = parseInt((timediff - day * 3600 * 24 - hours * 3600) / 60);
  second = parseInt(timediff - day * 3600 * 24 - hours * 3600 - minute * 60);
  day = day < 10 ? `0${day}` : day;
  hours = hours < 10 ? `0${hours}` : hours;
  minute = minute < 10 ? `0${minute}` : minute;
  second = second < 10 ? `0${second}` : second;
  if (isDay) {
    return `${day}天${hours}时${minute}分`;
  } else if (isHour) {
    return `${day}天${hours}:${minute}:${second}`;
  } else {
    return `${hours}:${minute}:${second}`;
  }
};

// 写入cookie
export const setCookie = (name, value, domain) => {
  var Days = 5;
  var exp = new Date();
  exp.setTime(exp.getTime() + Days * 24 * 60 * 60 * 1000);
  document.cookie =
    name +
    "=" +
    escape(value) +
    ";expires=" +
    exp.toGMTString() +
    ";domain=" +
    domain;
};

export const Keys = [
  '检察院',
  '法院',
  '交警',
  '安保局',
  '安全局',
  '投诉',
  '拒收',
  '退货',
  '工信部',
  '派出所',
  '警察',
  '工商所',
  '工商局',
  '售后',
  '市场监督',
  '空军',
  '陆军',
  '海军',
  '使馆'
];
