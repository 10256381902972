/* 售后服务-售后查件 */
import React, {Component} from "react";
import {Input} from "antd";
const {TextArea} = Input;

class TextareaCount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      barValue: "1",
      suffix: '\n'
    };
  }

  componentDidMount() {
    this.props.onChildEvent(this)
  }

  clearText = () => {
    this.setState({value: "", barValue: "1"})
  };

  // 设置行号
  line = (n) => {
    const {suffix} = this.state;
    let num = '';
    for (let i = 1; i <= n; i++) {
      num += i + suffix
    }
    this.setState({barValue: num});
  };

  // 同步滚动
  syncScrollTop = () => {
    document.querySelector('.barArea').scrollTop = document.querySelector('.editArea').scrollTop;
  };

  onChange = (val) => {
    this.setState({value: val});
    let str = val;
    str = str.replace(/\r/gi, '');
    str = str.split('\n');
    const n = str.length;
    this.line(n);
    this.props.onTextAreaChange(val)
  };

  onBlur = (val) => {
    this.props.onTextAreaBlur(val)
  };

  render() {
    const {
      value,
      barValue
    } = this.state;
    return (
      <div className="line-edit-wrapper">
        <div className="left-bar-wrapper">
          <TextArea
            value={barValue}
            className="barArea"
            disabled
            onChange={(e) => this.setState({barValue: e.target.value})}/>
        </div>
        <div className="rigth-edit-wrapper">
          <TextArea
            value={value}
            className="editArea"
            placeholder={(this.props.type === 1 ? "请输入快递单号" : (this.props.type === 2 ? "请输入平台编号" : "请输入订单批次号")) + " (多个请换行输入)，如：\n11111111\n22222222\n33333333"}
            onChange={(e) => this.onChange(e.target.value)}
            onBlur={(e) => this.onBlur(e.target.value)}
            onScroll={this.syncScrollTop}/>
        </div>
      </div>
    );
  }
}

export default TextareaCount;
