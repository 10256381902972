import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import { ConfigProvider } from "antd";
import locale from "antd/es/locale/zh_CN";
import "moment/locale/zh-cn";
import App from "./App";
import sellerSystemHoc from "./components/LeftTab";
import { PageContainer } from "./components/Container";
import Loading from "./components/loading";
import "./static/css/common.css";

import Index from "./pages/home/Home";
import All from "./pages/home/All";
import Login from "./pages/login/Login";
import Register from "./pages/login/Register";
import Forget from "./pages/login/Forget";
import GoodsInfo from "./pages/seller/goods/GoodsInfo";
import GoodsConfirm from "./pages/seller/goods/GoodsConfirm";
import OrderList from "./pages/seller/order/OrderList";
import ParcelList from "./pages/seller/order/ParcelList";
import CancelList from "./pages/seller/order/CancelList";
import Recharge from "./pages/seller/finance/Recharge";
import Withdraw from "./pages/seller/finance/Withdraw";
import Detail from "./pages/seller/finance/Detail";
import Paylist from "./pages/seller/finance/Paylist";
import Notice from "./pages/seller/help/Notice";
import NoticeInfo from "./pages/seller/help/NoticeInfo";
import Question from "./pages/seller/help/Question";
import Invite from "./pages/seller/promote/Invite";
import CollectList from "./pages/seller/collect/CollectList";
import Search from "./pages/seller/service/Search";
import Request from "./pages/seller/service/RequestOrder";
import Home from "./pages/seller/account/home";
import HelpCenter from "./pages/seller/help/HelpCenter";
import FeedBack from "./pages/seller/help/FeedBack";

import SettlementIndex from "./pages/settlement/Index";

// const Home = lazy(() => import("./pages/home/Home"));
// const All = lazy(() => import("./pages/home/All"));
// const Login = lazy(() => import("./pages/login/Login"));
// const Register = lazy(() => import("./pages/login/Register"));
// const Forget = lazy(() => import("./pages/login/Forget"));
// const GoodsInfo = lazy(() => import("./pages/seller/goods/GoodsInfo"));
// const GoodsConfirm = lazy(() => import("./pages/seller/goods/GoodsConfirm"));
// const OrderList = lazy(() => import("./pages/seller/order/OrderList"));
// const ParcelList = lazy(() => import("./pages/seller/order/ParcelList"));
// const Recharge = lazy(() => import("./pages/seller/finance/Recharge"));
// const Withdraw = lazy(() => import("./pages/seller/finance/Withdraw"));
// const Detail = lazy(() => import("./pages/seller/finance/Detail"));
// const Paylist = lazy(() => import("./pages/seller/finance/Paylist"));
// const Notice = lazy(() => import("./pages/seller/help/Notice"));
// const NoticeInfo = lazy(() => import("./pages/seller/help/NoticeInfo"));
// const Question = lazy(() => import("./pages/seller/help/Question"));
// const Invite = lazy(() => import("./pages/seller/promote/Invite"));

ReactDOM.render(
  <ConfigProvider locale={locale}>
    <Router>
      <Suspense fallback={<Loading />}>
        <PageContainer>
          <Switch>
            {/*<Route exact path="/" component={Index} />*/}
            <App>
              {/*<Route exact path="/" component={All} />*/}
              <Route exact path="/" component={Index} />
              <Route path="/all" component={All} />
              <Route path="/collect" component={CollectList} />
              <Route path="/login" component={Login} />
              <Route path="/register" component={Register} />
              <Route path="/forget" component={Forget} />
              <Route path="/help" component={HelpCenter} />
              <Route path="/settlement" component={SettlementIndex} />

              <Route
                path="/seller/goods/goodsinfo"
                component={sellerSystemHoc(
                  GoodsInfo,
                  "/seller/goods/goodsinfo"
                )}
              />
              <Route
                path="/seller/goods/goodsconfirm"
                component={sellerSystemHoc(
                  GoodsConfirm,
                  "/seller/goods/goodsconfirm"
                )}
              />
              <Route
                path="/seller/order/orderlist"
                component={sellerSystemHoc(
                  OrderList,
                  "/seller/order/orderlist"
                )}
              />
              <Route
                path="/seller/order/parcellist"
                component={sellerSystemHoc(
                  ParcelList,
                  "/seller/order/parcellist"
                )}
              />
              <Route
                path="/seller/order/cancellist"
                component={sellerSystemHoc(
                  CancelList,
                  "/seller/order/cancellist"
                )}
              />
              <Route
                path="/seller/finance/recharge"
                component={sellerSystemHoc(
                  Recharge,
                  "/seller/finance/recharge"
                )}
              />
              <Route
                path="/seller/finance/withdraw"
                component={sellerSystemHoc(
                  Withdraw,
                  "/seller/finance/withdraw"
                )}
              />
              <Route
                path="/seller/finance/detail"
                component={sellerSystemHoc(Detail, "/seller/finance/detail")}
              />
              <Route
                path="/seller/finance/paylist"
                component={sellerSystemHoc(Paylist, "/seller/finance/paylist")}
              />
              <Route
                path="/seller/help/notice"
                component={sellerSystemHoc(Notice, "/seller/help/notice")}
              />
              <Route
                path="/seller/help/noticeinfo"
                component={sellerSystemHoc(
                  NoticeInfo,
                  "/seller/help/noticeinfo"
                )}
              />
              <Route
                path="/seller/help/feedback"
                component={sellerSystemHoc(
                  FeedBack,
                  "/seller/help/feedback"
                )}
              />
              <Route
                path="/seller/help/question"
                component={sellerSystemHoc(Question, "/seller/help/question")}
              />
              <Route
                path="/seller/promote/invite"
                component={sellerSystemHoc(Invite, "/seller/promote/invite")}
              />
              <Route
                path="/seller/service/search"
                component={sellerSystemHoc(Search, "/seller/service/search")}
              />
              <Route
                path="/seller/service/request"
                component={sellerSystemHoc(Request, "/seller/service/request")}
              />
              <Route
                path="/seller/account/home"
                component={sellerSystemHoc(Home, "/seller/account/home")}
              />
            </App>
          </Switch>
        </PageContainer>
      </Suspense>
    </Router>
  </ConfigProvider>,
  document.getElementById("root")
);
