/* eslint-disable array-callback-return */
/* 发布商品-填写信息 */
import React, { Component, Fragment } from "react";
import {
  Steps,
  Table,
  Radio,
  Input,
  Button,
  message,
  Upload,
  Spin,
  Select, Modal
} from "antd";
import { LoadingOutlined, InfoCircleOutlined } from "@ant-design/icons";
import {
  IconFont,
  history,
  API,
  ROOT,
  imgRoot,
  Keys,
} from "../../../static/js/common";
import proArea from "../../../static/json/province.json";
import cityArea from "../../../static/json/city.json";
import countyArea from "../../../static/json/county.json";

const antIcon = <LoadingOutlined style={{ fontSize: 36 }} spin />;

const { Step } = Steps;

const { TextArea } = Input;

const { Dragger } = Upload;

const { Option } = Select;

const columns = [
  {
    title: "商品名称",
    dataIndex: "goodsName",
    width: "23%",
    render: (e, record) => {
      return (
        <div className="flex-start">
          <img
            src={record.goodsImg}
            alt=""
            style={{ width: 50, height: 50, marginRight: 10 }}
          />
          {e}
        </div>
      );
    },
  },
  {
    title: "单价",
    dataIndex: "goodsFee",
    width: "8%",
    render: (e) => {
      return <div>
        {(e / 100).toFixed(2)}
      </div>;
    },
  },
  {
    title: "使用说明",
    dataIndex: "instructions",
    width: "22%",
  },
  {
    title: "采购数量",
    dataIndex: "num",
    width: "9%",
    render: (_) => {
      return <div>1</div>;
    },
  },
  {
    title: "单重量",
    dataIndex: "weight",
    width: "12%",
    render: (e) => {
      return <div>{e}g</div>;
    },
  },
  {
    title: "快递",
    dataIndex: "courier",
    width: "12%",
  },
  {
    title: "发货地",
    dataIndex: "address",
    width: "12%",
  },
];

// 文件格式大小验证
function beforeUpload(file) {
  /*   const isFile = /csv|excel|sheet/g.test(file.type);
  if (!isFile) {
    message.error("仅支持CSV、XLSX、XLS格式的文件！");
  } */
  const isLt1M = file.size / 1024 / 1024 < 5;
  if (!isLt1M) {
    message.error("上传的文件不能大于5MB!");
  }
  return isLt1M;
}

class GoodsInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      goods_info: [],
      inputType: 1,
      dataPlatform: "",
      receiver_info: "",
      receiver_list: [],
      update_list: [], // 编辑收货人信息备份列表
      goodsId: this.props.location.search
        ? this.props.location.search.slice(1)
        : "",
      remark: "",
      up_loading: false, // 文件上传
      file_url: "",
      platform: ["1", "2", "3", "4"],
      loading: false,
      orderStatus: 0,
      btnDisabled: false,
      saveLoading: false,
      isAuth: 0,
      isShowAuth: false
    };
    this.saveReceiverInfo = this.debounce(this.saveReceiverInfo,300)
  }

  componentDidMount() {
    const { goodsId } = this.state;
    if (goodsId) {
      API.getGoodsInfo({ goodsId }).then((res) => {
        if (res.status * 1 === 0) {
          let platform = ["1", "2", "3", "4", "5"];
          if (res.extData.platform) {
            platform = res.extData.platform.split(",");
          }
          this.setState({ goods_info: [res.extData || {}], platform });
          this.getOrderLimits();
          this.getUserAuth();
        } else {
          message.error(res.message);
        }
      });
    }
  }

  //获取用户下单权限
  getOrderLimits = () => {
    API.postUserLimit({}).then((res) => {
      if (res.status * 1 === 0) {
        this.setState({orderStatus: parseInt(res.extData.isBan) || parseInt(res.extData.isBanIp)})
      } else {
        message.error(res.message);
      }
    });
  };

  //获取用户实名信息
  getUserAuth = () => {
    API.getAuthInfo({}).then((res) => {
      if (res.status * 1 === 0) {
        const data = res.extData;
        this.setState({
          isAuth: data.needRealName
        })
        if(parseInt(data.needRealName) === 1) {
          this.setState({
            isShowAuth: true
          })
        }
      }
    })
  };

  getReceiverInfo = () => {
    const {
      receiver_list,
      dataPlatform,
      inputType,
      file_url,
      goodsId,
      orderStatus,
      isAuth
    } = this.state;
    let {receiver_info} = this.state;
    if(orderStatus){
      message.error("平台检测到你多次违规，账号已被限制使用");
      return;
    }
    if(parseInt(isAuth) === 1) {
      this.setState({
        isShowAuth: true
      })
      return;
    }
    if (!goodsId) {
      message.error("请选择商品");
      return;
    }
    if (!dataPlatform) {
      message.error("请选择平台");
      return;
    }
    const reqData = { goodsId, dataPlatform, inputType };
    const new_receiver_list = [];
    if (inputType === 1) {
      if (!receiver_info) {
        message.error("请输入收件人信息");
        return;
      }
      let receiver_info_old = receiver_info.split("\n");
      let receiver_label = [];
      let receiver_con = [];
      receiver_info_old.forEach(item => {
        item = item.replace(/：/g, ":");
        if(item.includes(':') && !item.includes(',') && !item.includes('，')){
          receiver_label.push(item.split(':')[0]);
          receiver_con.push(item.split(':')[1]);
        }
      });
      if(receiver_label.length >= 3){
        receiver_info = receiver_con.join(',');
      } else if(receiver_label.length > 0 && receiver_label.length < 3){
        message.warning('请完善收件人信息');
        return ;
      }

      let list = receiver_info.split("\n"),
        i = false;
      list = list.filter((e) => {
        return !!e;
      });
      if (list.length > 0) {
        list = list.map((item) => {
          let a = item
            .replace(/，/g, ",")
            .replace(/\(可不填\)/g, "")
            .replace(
              /[`~!@#$%^&*()+=|{}:;\\.<>/?~！@#￥%…&*（）—+|【】‘；：”“’。、？']/g,
              ""
            )
            .trim()
            .replace(/\s+/g, " ");
          return (a = a
            .split(",")
            .map((val) => {
              return val.trim();
            })
            .join(","));
        });
        const o = {
          addressError: [],
          infoError: [],
          mobileError: [],
          nameError: [],
          keysError: [],
        };
        const checked = !list.every((e, t) => {
          let a = e.split(","),
            r = a.length,
            idx = 2;
          if (parseFloat(a[0].replace(/[^0-9]/gi, "")) >= 100000) {
            idx = 3;
          }
          return r >= 3 && a[idx]
            ? a[idx].split(" ").length >= 3 ||
                (o.addressError.push(t + 1), (i = true), !1)
            : (o.infoError.push(t + 1), !1);

          // if(r >= 3 && a[idx]){
          //   Keys.forEach(item => {
          //     if(a[idx].includes(item)){
          //       o.keysError.push(item);
          //     }
          //   });
          //   if(o.keysError.length){
          //     o.addressError.push(t + 1);
          //     return false;
          //   }
          //   return a[idx].split(" ").length >= 3 ||
          //   (o.addressError.push(t + 1), (i = true), !1);
          // }else{
          //   return (o.infoError.push(t + 1), !1);
          // }
        });
        if (checked) {
          // if(o.keysError.length){
          //   let e = "第" + o.addressError.join() + "行收件人信息中包含违禁词" + o.keysError.join('、') + "， 请修改后按照格式示例标准填写";
          //   message.error(e);
          //   return;
          // }
          let r = i
            ? "第" +
              o.addressError.join() +
              "行收件人信息格式有误,请按照格式示例标准填写,省 市 区 详细地址中间用空格分隔"
            : "第" +
              o.infoError.join() +
              "行收件人信息格式有误，请按照格式示例标准填写！";
          message.error(r);
          return;
        }
        const mobileChecked = !list.every((e, t) => {
          let a = e.split(","),
            idx = 1;
          if (parseFloat(a[0].replace(/[^0-9]/gi, "")) >= 100000) {
            idx = 2;
          }
          const r = /^(13[0-9]|14[0-9]|15[0-9]|16[0-9]|17[0-9]|18[0-9]|19[0-9])\d{8}$/.test(
            a[idx].substr(a[idx].indexOf(1))
          );
          const r1 = /^(1[3456789]\d{9}-\d{4})$/.test(a[idx].substr(a[idx].indexOf(1)));
          return r || r1 ? true : (o.mobileError.push(t + 1), false);
        });
        if (mobileChecked) {
          let r =
            "第" +
            o.mobileError.join() +
            "行收件人电话信息有误,请按照格式示例标准填写";
          message.error(r);
          return;
        }
        const nameChecked = !list.every((e, t) => {
          let a = e.split(","),
            idx = 0;
          if (parseFloat(a[0].replace(/[^0-9]/gi, "")) >= 100000) {
            idx = 1;
          }
          const r = a[idx].length > 1;
          return r ? true : (o.nameError.push(t + 1), false);
        });
        if (nameChecked) {
          let r =
            "第" +
            o.nameError.join() +
            "行收件人姓名信息有误,至少两个字符,请按照格式示例标准填写";
          message.error(r);
          return;
        }
        list.forEach((item, idx) => {
          const i = item.split(",");
          let isOrder = false;
          if (parseFloat(i[0].replace(/[^0-9]/gi, "")) >= 100000) {
            isOrder = true;
          }
          const num = i[isOrder ? 3 : 2].replace(/\s+/g, " ").split(" ");
          const isProvince = num.length > 3;
          if (!isProvince) {
            num.unshift("");
          }
          for (let j in cityArea) {
            cityArea[j].map((item) => {
              if (
                num[1].length >= 2 &&
                item.name.indexOf(num[1]) !== -1 &&
                item.name[0] === num[1][0]
              ) {
                num[0] = item.province;
                num[1] = item.name;
              }
            });
          }
          if (
            isOrder &&
            (receiver_list.find((val) => {
              return val.tradeSn === i[0];
            }) || new_receiver_list.find((val) => {
              return val.tradeSn === i[0];
            }) )
          ) {
            message.success(`第${idx + 1}行定单编号有重复，已过滤`);
          } else {
            const obj = {
              tradeSn: isOrder ? i[0] : "",
              receiveName: isOrder ? i[1] : i[0],
              receiveMobile: i[isOrder ? 2 : 1].substr(
                i[isOrder ? 2 : 1].indexOf(1)
              ),
              receiveProvince: num[0],
              receiveCity: num[1],
              receiveArea: num[2],
              // receiveAddress: num.slice(3).join(" "),
              receiveAddress: i[isOrder ? 3 : 2].replace(/\s+/g, " "),
              shopName: isOrder ? (i.length > 4 ? i[4] : '') : (i.length > 3 ? i[3] : '')
            };
            new_receiver_list.push(obj);
          }
        });
        if (new_receiver_list.length === 0) {
          return;
        }
        reqData.inputData = JSON.stringify(new_receiver_list);
      }
    } else {
      if (!file_url) {
        message.error("请上传文件");
        return;
      }
      reqData.inputData = file_url;
    }
    this.setState({ loading: true });
    API.postReceiver(reqData).then((res) => {
      this.setState({ loading: false });
      if (res.status * 1 === 0) {
        const list = receiver_list.concat(res.extData || []);
        this.setState({
          receiver_list: list,
          update_list: list,
          receiver_info: "",
          file_url: "",
        });
        document.getElementById("receiverList").scrollIntoView();
      } else {
        message.error(res.message || "解析错误");
      }
    });
  };

  // 编辑收货人信息
  opeatorReceiver = (idx, type) => {
    const { receiver_list, update_list, goodsId, dataPlatform } = this.state;
    const list = JSON.parse(JSON.stringify(receiver_list));
    if (type === "delete") {
      list.splice(idx, 1);
    }
    if (type === "parseOk") {
      list[idx].parseOk = 1 - receiver_list[idx].parseOk * 1;
    }
    if (type === "cancel") {
      list[idx].parseOk = 1 - receiver_list[idx].parseOk * 1;
      update_list[idx] = list[idx];
    }
    if (type === "update") {
      const reqData = { goodsId, dataPlatform, inputType: 1 };
      const {
        receiveProvince,
        receiveCity,
        receiveArea,
        receiveAddress
      } = update_list[idx];
      update_list[
        idx
      ].receiveAddress = `${receiveProvince} ${receiveCity} ${receiveArea} ${receiveAddress}`;
      reqData.inputData = JSON.stringify([update_list[idx]]);
      API.postReceiver(reqData).then((res) => {
        if (res.status * 1 === 0) {
          list[idx] = res.extData[0];
          if (res.extData[0].parseOk * 1 === 0) {
            message.error(res.extData[0].parseMsg || "解析错误");
          }
          this.setState({ receiver_list: list, update_list: list });
        } else {
          message.error(res.message || "解析错误");
        }
      });
      return;
    }
    this.setState({ receiver_list: list, update_list: list });
  };

  // 更新收货人信息
  updateReceiver = (e, idx, type) => {
    const { update_list } = this.state;
    const list = JSON.parse(JSON.stringify(update_list));
    list[idx][type] = e;
    if (type === "receiveProvince") {
      list[idx].provinceCode = e;
      proArea.map((item) => {
        if (item.id * 1 === e * 1) {
          list[idx][type] = item.name;
        }
      });
    }
    if (type === "receiveCity") {
      list[idx].cityCode = e;
      cityArea[update_list[idx].provinceCode].map((item) => {
        if (item.id * 1 === e * 1) {
          list[idx][type] = item.name;
        }
      });
    }
    if (type === "receiveArea") {
      list[idx].areaCode = e;
      countyArea[update_list[idx].cityCode].map((item) => {
        if (item.id * 1 === e * 1) {
          list[idx][type] = item.name;
        }
      });
    }
    this.setState({ update_list: list });
  };

  // 防抖，防止高频率操作
  debounce = (fn, wait = 600) =>{
    let timeout = null;
    return function() {
      if(timeout !== null) clearTimeout(timeout);
      timeout = setTimeout(fn, wait);
    }
  };

  // 下一步
  saveReceiverInfo = () => {
    this.setState({btnDisabled: true});
    const { goodsId, dataPlatform, receiver_list, remark } = this.state;
    if (!goodsId) {
      message.error("请选择商品");
      return;
    }
    if (receiver_list.length === 0) {
      message.error("请填写收货人信息");
      return;
    }
    if (!dataPlatform) {
      message.error("请选择平台");
      return;
    }
    for (let item of receiver_list) {
      if (item.parseOk * 1 !== 1) {
        message.error("收件人信息有异常，请检查");
        return;
      }
    }
    this.setState({ saveLoading: true });
    const reqData = { goodsId, platform: dataPlatform, goodsNum: 1, from: 1, remark };
    reqData.receiverList = JSON.stringify(receiver_list);
    API.postSaveReceiver(reqData).then((res) => {
      if (res.status * 1 === 0) {
        history.push({
          pathname: `/seller/goods/goodsconfirm?${res.extData.orderId}`,
        });
      } else if (res.status * 1 === -12431) {
        message.error(res.message || "地址错误");
        this.setState({ receiver_list: res.extData, update_list: res.extData });
      } else {
        message.error(res.message || "保存失败");
      }
      this.setState({btnDisabled: false})
      this.setState({ saveLoading: false });
    });
  };

  // 上传文件
  handleChange = (info) => {
    if (info.file.status === "uploading") {
      this.setState({ up_loading: true });
      return;
    }
    this.setState({
      up_loading: false,
    });
    if (info.file.status === "error") {
      message.error("文件上传失败！");
      return;
    }
    if (info.file.status === "done") {
      let res = info.file.response;
      if (res.status * 1 === 0) {
        this.setState({ file_url: res.extData.fileUrl });
      } else {
        message.error(res.message || "文件上传失败！");
      }
    }
  };

  onOpenHref = () => {
    const { dataPlatform } = this.state;
    if(dataPlatform === 1) {
      window.open("https://dfcdn.shuidilipin.com/source/daifa/video/plugin_video.mp4")
      if (window._hmt) {
        window._hmt.push(['_trackEvent', 'goodsinfo', 'video', 'taobao'])
      }
    } else {
      window.open("https://dfcdn.shuidilipin.com/source/daifa/video/pdd_daifa.mp4")
      if (window._hmt) {
        window._hmt.push(['_trackEvent', 'goodsinfo', 'video', 'pdd'])
      }
    }
  };

  toAuth = () => {
    window.open(`/#/seller/account/home?status=1`,'_self')
  };

  render() {
    const {
      goods_info,
      inputType,
      dataPlatform,
      receiver_info,
      receiver_list,
      update_list,
      up_loading,
      file_url,
      platform,
      loading,
      saveLoading,
      isShowAuth
    } = this.state;

    const user_columns = [
      {
        title: "序号",
        dataIndex: "id",
        align: "center",
        render: (e, k, idx) => {
          return <div>{idx + 1 < 10 ? `0${idx + 1}` : idx + 1}</div>;
        },
      },
      {
        title: "订单编号",
        align: "center",
        dataIndex: "tradeSn",
        render: (e, item, idx) => {
          return (
            <div>
              {item.parseOk * 1 === 0 ? (
                <Input
                  defaultValue={e}
                  onChange={(val) =>
                    this.updateReceiver(val.target.value, idx, "tradeSn")
                  }
                />
              ) : (
                <div>{e}</div>
              )}
            </div>
          );
        },
      },
      {
        title: "姓名",
        align: "center",
        dataIndex: "receiveName",
        render: (e, item, idx) => {
          return (
            <div>
              {item.parseOk * 1 === 0 ? (
                <Input
                  defaultValue={e}
                  onChange={(val) =>
                    this.updateReceiver(val.target.value, idx, "receiveName")
                  }
                />
              ) : (
                <div>{e}</div>
              )}
            </div>
          );
        },
      },
      {
        title: "电话",
        align: "center",
        dataIndex: "receiveMobile",
        render: (e, item, idx) => {
          return (
            <div>
              {item.parseOk * 1 === 0 ? (
                <Input
                  defaultValue={e}
                  onChange={(val) =>
                    this.updateReceiver(val.target.value, idx, "receiveMobile")
                  }
                />
              ) : (
                <div>{e}</div>
              )}
            </div>
          );
        },
      },
      {
        title: "地址",
        align: "center",
        dataIndex: "receiveProvince",
        render: (e, item, idx) => {
          return (
            <div>
              {item.parseOk * 1 === 0 && (
                <div>
                  {receiver_list[idx].receiveProvince}&nbsp;
                  {receiver_list[idx].receiveCity}&nbsp;
                  {receiver_list[idx].receiveArea}&nbsp;
                  {receiver_list[idx].receiveAddress}
                  <div style={{ color: "#f94a3d", fontWeight: 600 }}>
                    {item.parseMsg && `解析失败,${item.parseMsg}`}
                  </div>
                </div>
              )}
              <div>
                <div className="flex-start" style={{ marginBottom: 5 }}>
                  {item.parseOk * 1 === 0 ? (
                    <Select
                      placeholder="省"
                      style={{ minWidth: 100 }}
                      onChange={(value) =>
                        this.updateReceiver(value, idx, "receiveProvince")
                      }
                    >
                      {proArea.map((data) => (
                        <Option key={data.id} value={data.id}>
                          {data.name}
                        </Option>
                      ))}
                    </Select>
                  ) : (
                    <div>{e}</div>
                  )}
                  &nbsp;
                  {item.parseOk * 1 === 0 ? (
                    <Select
                      placeholder="市"
                      style={{ minWidth: 100 }}
                      onChange={(value) =>
                        this.updateReceiver(value, idx, "receiveCity")
                      }
                    >
                      {item.provinceCode &&
                      cityArea[item.provinceCode].map((data) => (
                        <Option key={data.id} value={data.id}>
                          {data.name}
                        </Option>
                      ))}
                    </Select>
                  ) : (
                    <div>{item.receiveCity}</div>
                  )}
                </div>
                <div className="flex-start">
                  {item.parseOk * 1 === 0 ? (
                    <Select
                      placeholder="区"
                      style={{ minWidth: 100 }}
                      onChange={(value) =>
                        this.updateReceiver(value, idx, "receiveArea")
                      }
                    >
                      {item.cityCode &&
                      countyArea[item.cityCode || 0] &&
                      countyArea[item.cityCode || 0].map((data) => (
                        <Option key={data.id} value={item.id}>
                          {data.name}
                        </Option>
                      ))}
                    </Select>
                  ) : (
                    <div>{item.receiveArea}</div>
                  )}
                  &nbsp;
                  {item.parseOk * 1 === 0 ? (
                    <Input
                      placeholder="请输入详细地址"
                      style={{ minWidth: 120 }}
                      onChange={(val) =>
                        this.updateReceiver(
                          val.target.value,
                          idx,
                          "receiveAddress"
                        )
                      }
                    />
                  ) : (
                    <div>{item.receiveAddress}</div>
                  )}
                </div>
              </div>
            </div>
          );
        },
      },
      {
        title: "店铺名称",
        align: "center",
        dataIndex: "shopName",
        render: (e, item, idx) => {
          return (
            <div>
              {item.parseOk * 1 === 0 ? (
                <Input
                  defaultValue={e}
                  onChange={(val) =>
                    this.updateReceiver(val.target.value, idx, "shopName")
                  }
                />
              ) : (
                <div>{e}</div>
              )}
            </div>
          );
        },
      },
      {
        title: "操作",
        align: "center",
        dataIndex: "parseOk",
        width: 170,
        render: (e, k, idx) => {
          return (
            <div>
              {e * 1 === 0 ? (
                <Fragment>
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={(_) => this.opeatorReceiver(idx, "cancel")}
                  >
                    取消
                  </span>
                  <span
                    style={{ cursor: "pointer", marginLeft: 10 }}
                    onClick={(_) => this.opeatorReceiver(idx, "update")}
                  >
                    确认修改
                  </span>
                </Fragment>
              ) : (
                <span
                  style={{ cursor: "pointer" }}
                  onClick={(_) => this.opeatorReceiver(idx, "parseOk")}
                >
                  编辑
                </span>
              )}
              <span
                style={{ color: "#f94a3d", marginLeft: 10, cursor: "pointer" }}
                onClick={(_) => this.opeatorReceiver(idx, "delete")}
              >
                删除
              </span>
            </div>
          );
        },
      },
    ];
    return (
      <div>
        <Steps current={0} style={{ width: "40%", margin: "20px auto" }}>
          <Step title="填写信息 " />
          <Step title="确认订单" />
        </Steps>
        <div style={{ marginBottom: 30 }}>
          <div className="goods_info_title">第一步：选择礼品</div>
          <Table
            columns={columns}
            dataSource={goods_info}
            bordered
            rowKey="goodsId"
            pagination={false}
          />
          {goods_info.length && goods_info[0].remark && (
          <div className="remark" style={{marginTop: 10}}>
            仓库备注：
            <span  style={{color: "rgb(51, 170, 255)"}}>{goods_info[0].remark}</span>
          </div>
          )}
        </div>
        <div style={{ marginBottom: 30 }}>
          <div className="goods_info_title">第二步：选择数据来源平台</div>
          <div className="goods_info_platform">
            <Radio.Group
              value={dataPlatform}
              buttonStyle="solid"
              size="large"
              onChange={(e) => this.setState({ dataPlatform: e.target.value, inputType: 1})}
            >
              <Radio.Button value={1} disabled={platform.indexOf("1") === -1}>
                淘宝/天猫
              </Radio.Button>
              <Radio.Button value={2} disabled={platform.indexOf("2") === -1}>
                京东
              </Radio.Button>
              <Radio.Button value={3} disabled={platform.indexOf("3") === -1}>
                拼多多
              </Radio.Button>
              <Radio.Button value={5} disabled={platform.indexOf("5") === -1}>
                抖音
              </Radio.Button>
              <Radio.Button value={4} disabled={platform.indexOf("4") === -1}>
                其他
              </Radio.Button>
            </Radio.Group>
          </div>
        </div>
        <div style={{ marginBottom: 20 }}>
          <div className="goods_info_title">第三步：录入收件人信息</div>
          <div className="goods_info_platform">
            <Radio.Group
              value={inputType}
              buttonStyle="solid"
              size="large"
              onChange={(e) => this.setState({ inputType: e.target.value })}
            >
              <Radio.Button value={1}>手动输入</Radio.Button>
              <Radio.Button value={2}>模板导入</Radio.Button>
              {/*{(dataPlatform === 1 || dataPlatform === 3) && (*/}
                {/*<Radio.Button value={3}>插件导入</Radio.Button>*/}
              {/*)}*/}
            </Radio.Group>
          </div>
          <div style={{color: '#f94a3d',marginTop:'20px'}}>
            <label style={{color: '#000'}}>发货提示：</label>
            请务必录入真实具体收货信息，虚假地址、地址不详、收件人电话虚假等问题物流将被退回，因此造成损失将由您个人承担
          </div>
        </div>
        {inputType === 1 ? (
          <Fragment>
            <div>
              <div className="goods_info_tips">
                <div style={{ fontWeight: 600 }}>格式要求：</div>
                <div style={{ color: "#f94a3d" }}>
                  订单编号（可不填）,姓名,电话,地址
                  按顺序填写,中间用逗号分隔，省 市 区 详细地址中间用空格分隔。
                </div>
                <div>一次可以输入多个收件人信息，一个收件人信息一行输入，店铺名称可不填</div>
                <div>示例</div>
                <div style={{ color: "#f94a3d" }}>
                  600000000000，小明，13000000000，浙江省 杭州市 上城区
                  中河北路10号，xx旗舰店
                </div>
                <div>
                  订单编号：淘、猫、京、拼等平台订单编号，<span style={{color: "red"}}>有订单编号能快速出单，方便批量导出匹配发货。如无订单编号可不填写。</span>
                </div>
                <div>
                  如遇东莞市、中山市、天门市、三沙市、儋州市、嘉峪关市等没有区的城市，区域可设置为“其他区”。
                </div>
              </div>
            </div>
            <div style={{ fontWeight: 600, margin: "20px 0" }}>
              手动输入信息
            </div>
            <TextArea
              value={receiver_info}
              onChange={(e) => this.setState({ receiver_info: e.target.value })}
              placeholder="订单编号，姓名，电话，地址，店铺名称
例如：6000000000，小明，13000000000，浙江省 杭州市 下城区 中河北路10号，xx旗舰店"
              autoSize={{ minRows: 5 }}
            />
          </Fragment>
        ) : inputType === 2 ? (
          <div className="goods_file flex-space">
            <Dragger
              name="file"
              showUploadList={false}
              beforeUpload={beforeUpload}
              action={`${ROOT}/upload/filecos`}
              onChange={this.handleChange}
            >
              {up_loading ? (
                <Spin indicator={antIcon} />
              ) : (
                <Fragment>
                  {file_url ? (
                    <div>
                      <div>
                        <IconFont
                          type="icon-chenggong"
                          style={{
                            fontSize: 54,
                            marginBottom: 10,
                          }}
                        />
                      </div>
                      <div style={{ fontSize: 14 }}>
                        文件上传成功，可
                        <span style={{ color: "#f94a3d" }}>重新上传</span>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div>
                        <IconFont
                          type="icon-shangchuanwenjian"
                          style={{
                            fontSize: 46,
                            color: "#333333",
                            marginBottom: 10,
                          }}
                        />
                      </div>
                      <div style={{ fontSize: 14 }}>
                        将文件拖到此处，或
                        <span style={{ color: "#f94a3d" }}>点击上传</span>
                      </div>
                    </div>
                  )}
                </Fragment>
              )}
            </Dragger>
            <div className="goods_file_tips">
              <div style={{ fontWeight: 600 }}>注意事项:</div>
              <div> 1、仅支持CSV、XLSX、XLS文件</div>
              <div>2、文件大小限制在5M以内</div>
              <div
                style={{ color: "#f94a3d", cursor: "pointer" }}
                onClick={(_) =>
                  (window.location.href = `${imgRoot}/%E7%A4%BC%E5%93%81%E4%BB%A3%E5%8F%91%E6%A8%A1%E6%9D%BF.zip`)
                }
              >
                3、导入模板下载
              </div>
            </div>
          </div>
        ) : (
          <div className="goods_file flex-space">
            {/*<div className="goods_file_plugin">
              <img
                src="https://dfcdn.shuidilipin.com/source/daifa/dts-headicon.png"
                alt=""
              />
              <Button
                type="primary"
                size="large"
                onClick={(_) => window.open("https://shuidilipin.com/help.html")}
              >
                下载水滴插件
              </Button>
            </div>
            <div className="goods_file_tips">
              <div style={{ fontWeight: 600 }}>注意事项:</div>
              <div>
                1、支持平台：
                {dataPlatform === 1 ? "淘宝、天猫" : "拼多多"}
              </div>
              <div>
                2、插件安装教程：点击
                <span
                  style={{ color: "#f94a3d", cursor: "pointer" }}
                  onClick={(_) =>
                    window.open("https://shuidilipin.com/help.html")
                  }
                >
                  【下载水滴插件】
                </span>
                ，跳转页面有详细的下载地址以及安装教程
              </div>
              <div>
                3、使用教程：
                <span
                  style={{ color: "#f94a3d", cursor: "pointer" }}
                  onClick={(_) => this.onOpenHref()}
                >
                  操作步骤
                </span>
              </div>
            </div>*/}
          </div>
        )}
        {inputType !== 3 && (
          <Fragment>
            <div style={{margin: "20px auto", textAlign: "center"}}>
              <Button
                type="primary"
                size="large"
                onClick={this.getReceiverInfo}
                loading={loading}
              >
                生成收件人信息
              </Button>
            </div>
            {receiver_list.length > 0 ? (
              <div id="receiverList">
                <div style={{margin: "20px 0"}}>
                  <span style={{fontWeight: 600}}>收件人信息</span>
                  <span style={{float: 'right'}}><InfoCircleOutlined style={{marginRight: 3}}/>
                    地址为机器识别，可能会出现错误，请您核查后下单
                    <Button
                      type="primary"
                      size="small"
                      style={{marginLeft: 10}}
                      onClick={() => this.setState({receiver_list: [], update_list: []})}>
                      清空收件人信息
                    </Button>
                  </span>
                </div>
                <Table
                  columns={user_columns}
                  dataSource={update_list}
                  bordered
                  rowKey="tradeSn"
                  pagination={false}
                />
              </div>
            ) : null}
            {receiver_list.length > 0 ? (
              <div>
                <div style={{fontWeight: 600, margin: "20px 0"}}>
                  备注信息（非必填）
                </div>
                <TextArea
                  placeholder="备注信息仅在平台浏览，可不填写"
                  autoSize={{minRows: 5}}
                  onChange={(e) => this.setState({remark: e.target.value})}/>
                <div style={{margin: "20px auto", textAlign: "center"}}>
                  <Button
                    type="primary"
                    size="large"
                    onClick={this.saveReceiverInfo}
                    loading={saveLoading}
                  >
                    订单预览
                  </Button>
                </div>
              </div>
            ) : null}
          </Fragment>
        )}

        <Modal
          width={350}
          visible={isShowAuth}
          onOk={this.toAuth}
          okText="去实名"
          onCancel={(_) => this.setState({ isShowAuth: false })}>
          <div>
            <div>
              <div style={{ fontSize: 16}}>
                请先完成实名认证
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
export default GoodsInfo;
