/* 订单管理-订单列表 */
import React, { Component, Fragment } from "react";
import {
  Table,
  DatePicker,
  Select,
  Modal,
  Button,
  message,
  Popconfirm, Input, Form,Popover,Tooltip
} from "antd";
import {API, IconFont, imgRoot, ROOT} from "../../../static/js/common";
import {CopyOutlined, EditOutlined, QuestionCircleOutlined} from "@ant-design/icons";
import { CopyToClipboard } from "react-copy-to-clipboard";
import moment from "moment";
const FormItem = Form.Item;
const { RangePicker } = DatePicker;
const {TextArea} = Input;
const { Option } = Select;
const { confirm } = Modal;

const goods_columns = [
  {
    title: "序号",
    dataIndex: "id",
    align: "center",
    render: (e, k, id) => {
      return <div>{id + 1 < 10 ? `0${id + 1}` : id + 1}</div>;
    },
  },
  {
    title: "订单编号",
    dataIndex: "tradeSn",
    align: "center",
    render: (e) => (
      <CopyToClipboard
        text={e}
        onCopy={() => message.success("成功复制订单编号")}
      >
        <div style={{ cursor: "pointer" }}>{e}</div>
      </CopyToClipboard>
    ),
  },
  {
    title: "收货人",
    dataIndex: "receiveName",
    align: "center",
  },
  {
    title: "联系手机",
    dataIndex: "receiveMobile",
    align: "center",
  },
  {
    title: "收货地址",
    dataIndex: "receiveProvince",
    align: "center",
    render: (e, record) => {
      return (
        <div>
          {e}&nbsp;
          {record.receiveCity}&nbsp;
          {record.receiveArea}&nbsp;
          {record.receiveAddress}
        </div>
      );
    },
  },
  {
    title: "礼品名称",
    dataIndex: "goodsName",
    align: "center",
    render: (e,record) => {
      return <div>
        {e}
        {record.instructions && (
          <Popover
            content={record.instructions}
            placement="top"
            trigger="hover">
            <IconFont style={{cursor: 'pointer'}} type="icon-bangzhu"/>
          </Popover>
        )}
      </div>;
    },
  },
  {
    title: "礼品数量",
    dataIndex: "num",
    align: "center",
    render: (_) => {
      return <div>1</div>;
    },
  },
  {
    title: "费用",
    dataIndex: "parcelFee",
    align: "center",
    render: (e) => {
      return <div>￥{(e / 100).toFixed(2)}</div>;
    },
  },
];

class OrderList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      pageSize: 10,
      parcel_page: 1,
      parcel_pageSize: 10,
      orderStatus: 0,
      platform: 0,
      parcelStatus: 0,
      payTimeStart: "",
      payTimeEnd: "",
      orderId: "",
      isShow: false,
      order_data: {}, // 订单列表
      parcel_data: {}, // 包裹列表
      modal_type: 1, // 1支付订单 2查看订单
      totalFee: (0).toFixed(2),
      courierInfo: [],
      isShowInfo: false,
      isShowRemark: false,
      remark: '',
      editOrderId: '',
      selectedRowKeys: [],
      isShowConfirm: false,
      parcelSnList: [],
      courierSn: "",
      selectedRowIds: [],
      orderSn: "",
      loading: false,
      isAuth: 0,
      isShowAuth: false
    };
  }

  componentDidMount() {
    if(this.getUrlParams('status')){
      this.setState({orderStatus: parseInt(this.getUrlParams('status'))},(_) => this.getOrderList());
      return;
    }
    this.getOrderList();
    this.getUserAuth();
  }

  getUrlParams = (name) => {
    const reg = new RegExp(`(^|&)${ name}=([^&]*)(&|$)`);
    const r = this.props.location.search.substr(1).match(reg);
    if (r != null) return  decodeURIComponent(r[2]); return null;
  };

  //获取用户实名信息
  getUserAuth = () => {
    API.getAuthInfo({}).then((res) => {
      if (res.status * 1 === 0) {
        const data = res.extData;
        this.setState({
          isAuth: data.needRealName
        })
      }
    })
  };

  // 订单列表
  getOrderList = () => {
    const {
      page,
      pageSize,
      orderStatus,
      payTimeStart,
      payTimeEnd,
      platform,
      orderSn,
    } = this.state;
    const reqData = { page, pageSize, orderStatus, platform };
    if (payTimeStart && payTimeEnd) {
      reqData.payTimeStart = payTimeStart;
      reqData.payTimeEnd = payTimeEnd;
    }
    if(orderSn) {
      reqData.orderSn = orderSn;
    }
    this.setState({loading: true})
    API.getOrderList(reqData).then((res) => {
      if (res.status * 1 === 0) {
        this.setState({ order_data: res.extData || {} });
      } else {
        message.error(res.message);
      }
      this.setState({loading: false})
    });
  };

  // 包裹列表
  getParcelList = () => {
    const { parcel_page, parcel_pageSize, orderId, parcelStatus } = this.state;
    const reqData = {
      page: parcel_page,
      pageSize: parcel_pageSize,
      orderId,
      parcelStatus,
    };
    API.getParcelList(reqData).then((res) => {
      if (res.status * 1 === 0) {
        this.setState({ parcel_data: res.extData || {} });
      } else {
        message.error(res.message);
      }
    });
    API.getOrderInfo({ orderId }).then((res) => {
      if (res.status * 1 === 0) {
        this.setState({
          totalFee: res.extData.totalFee
            ? (res.extData.totalFee / 100).toFixed(2)
            : (0).toFixed(2),
        });
      } else {
        message.error(res.message);
      }
    });
  };

  // 查看订单
  handleSeeOrder = (id, type, orderRights) => {
    if(parseInt(this.state.isAuth) === 1 && type === 1) {
      this.setState({
        isShowAuth: true
      })
      return;
    }
    if(!orderRights && type === 1){
      message.warning('首次购买的用户需联系客服人员进行账户审核，审核通过后可刷新当前页或进入订单列表选择最新一笔订单提交支付。');
      return;
    }
    this.setState(
      {
        isShow: true,
        parcel_page: 1,
        parcel_data: {},
        orderId: id,
        modal_type: type,
        parcelStatus: 0,
        isShowKf: false,
      },
      (_) => this.getParcelList()
    );
  };

  // 支付订单
  handlePayOk = () => {
    const { orderId } = this.state;
    API.getPayOrder({ orderId }).then((res) => {
      if (res.status * 1 === 0) {
        message.success("支付成功");
        this.setState({ page: 1, isShow: false }, (_) => this.getOrderList());

        const todayDate = window.localStorage.getItem("PAY-DATE");
        const nowDate = moment().format("YYYY-MM-DD");
        if(!todayDate || (Date.parse(nowDate) - Date.parse(todayDate))/(1*24*60*60*1000) >= 3){
          this.setState({isShowKf: true});
          window.localStorage.setItem("PAY-DATE", nowDate);
        }

      } else if (res.status * 1 === -10000) {
        message.error(res.message);
      }
    });
  };

  // 取消订单
  handleCancel = (orderId) => {
    API.getOrderCancel({ orderId }).then((res) => {
      if (res.status * 1 === 0) {
        message.success("取消成功");
        this.setState({ page: 1 }, (_) => this.getOrderList());
      } else if (res.status * 1 === -10000) {
        message.error(res.message);
      }
    });
  };

  handleSubmit = () => {
    this.setState({ page: 1}, (_) => this.getOrderList());
  };

  // 选择日期
  changeTime = (e) => {
    let { payTimeStart, payTimeEnd } = this.state;
    if (e) {
      payTimeStart = moment(e[0]).format("YYYY-MM-DD HH:mm");
      payTimeEnd = moment(e[1]).format("YYYY-MM-DD HH:mm");
    } else {
      payTimeStart = "";
      payTimeEnd = "";
    }
    this.setState({ payTimeStart, payTimeEnd }, (_) => this.getOrderList());
  };

  // 导出表格
  downloadExcel = () => {
    const token = window.localStorage.getItem("slpToken") || "";
    window.open(
      `${ROOT}/export/orderParcel?_t=${new Date().valueOf()}&token=${token}&orderId=${
        this.state.orderId
      }&parcelStatus=${this.state.parcelStatus}`
    );
    // const url = `http://shuidilipin.com/export/orderParcel?_t=${new Date().valueOf()}&token=${token}&orderId=${
    //   this.state.orderId}&parcelStatus=${this.state.parcelStatus}`;
    // let iframe = document.createElement('iframe');
    // iframe.id = 'hiddenIframe';
    // iframe.style.display = 'none';
    // document.body.appendChild(iframe);
    // iframe.src = url;
  };

  handleSearchCourierInfo = (kno, parcelId) => {
    let k_no = kno;
    if(kno.indexOf('SF')>-1){
      k_no = k_no + ':6086';
    }
    API.getSearchCourierInfo({
      kno: k_no,
      parcelId,
    }).then((res) => {
      if (res.status * 1 === 0) {
        const data = res.extData || {};
        const courierInfo = data.wuliu ? JSON.parse(data.wuliu) : [];
        this.setState({ courierInfo, isShowInfo: true, courierSn: kno });
      } else {
        message.error(res.message || "物流信息加载错误");
      }
    });
  };

  handleEditRemark = (remark, editOrderId) => {
    this.setState({ remark, editOrderId, isShowRemark: true });
  };

  saveRemarkHandler = () =>{
    const { remark, editOrderId } = this.state;
    const reqData = {
      remark,
      orderId: editOrderId
    };
    API.postSaveOrderRemark(reqData).then((res) => {
      if (res.status * 1 === 0) {
        this.setState({ isShowRemark: false });
        message.success("保存成功");
        this.getOrderList();
      } else {
        message.error(res.message || "数据加载错误");
      }
    });
  };

  handleSelected = (selectedRowKeys, item) => {
    const parcelSnList = [];
    for (let data of item) {
      if (data.parcelSn) {
        parcelSnList.push(data.parcelSn);
      }
    }
    this.setState({ selectedRowKeys, parcelSnList });
  };

  handleSelectedOrder = (selectedRowKeys) => {
    this.setState({ selectedRowIds: selectedRowKeys});
  };

  content = (sn) => {
    return (
      <span>
        物流信息（{sn}
        <CopyToClipboard
          text={sn}
          onCopy={() =>
            message.success(`复制成功`)
          }>
         <CopyOutlined style={{marginLeft: 3, color: '#999', fontSize: 14, cursor: "pointer"}}/>
        </CopyToClipboard>
        ）
      </span>
    );
  };

  onParcelList = (info) => {
    window.open(`/#/seller/order/parcellist?orderId=${info.orderId}`, '_blank')
  };

  batchDelOrder = () => {
    const { selectedRowIds } = this.state;
    if(!selectedRowIds.length){
      message.warning("请勾选要删除的订单");
      return;
    }
    this.handleDelOrder(selectedRowIds.join(','));
  };

  handleDelOrder = (id) => {
    const _this = this;
    confirm({
      content: "确认要删除吗",
      onOk() {
        const reqData = {
          orderIds: id
        };
        API.postDelOrder(reqData).then((res) => {
          if (res.status * 1 === 0) {
            message.success("删除成功");
            _this.getOrderList();
          } else {
            message.error(res.message || "数据加载错误");
          }
        });
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  toAuth = () => {
    window.open(`/#/seller/account/home?status=1`,'_self')
  };

  render() {
    const {
      page,
      parcel_page,
      pageSize,
      parcel_pageSize,
      orderStatus,
      parcelStatus,
      order_data,
      parcel_data,
      isShow,
      modal_type,
      totalFee,
      courierInfo,
      isShowInfo,
      isShowRemark,
      platform,
      remark,
      selectedRowKeys,
      parcelSnList,
      isShowKf,
      courierSn,
      selectedRowIds,
      isShowConfirm,
      orderSn,
      loading,
      isShowAuth
    } = this.state;

    const columns = [
      {
        title: "订单批次号",
        dataIndex: "orderSn",
        align: "center",
        render: (e) => (
          <CopyToClipboard
            text={e}
            onCopy={() => message.success("成功复制订单批次号")}
          >
            <div style={{ cursor: "pointer" }}>{e}</div>
          </CopyToClipboard>
        ),
      },
      {
        title: "平台",
        dataIndex: "platformStr",
        align: "center",
      },
      {
        title: "订单状态",
        dataIndex: "orderStatus",
        align: "center",
        render: (e) => {
          return (
            <div>{e * 1 === 9 ? "已取消" : ["", "待支付", "已支付"][e]}</div>
          );
        },
      },
      {
        title: "下单时间",
        dataIndex: "createTime",
        align: "center",
        width: 100
      },
      {
        title: "支付时间",
        dataIndex: "payTime",
        align: "center",
        width: 100
      },
      {
        title: "仓库",
        dataIndex: "addr",
        align: "center",
      },
      {
        title: "快递",
        dataIndex: "courierName",
        align: "center",
      },
      {
        title: "包裹数",
        dataIndex: "parcelNum",
        align: "center",
        render: (e,record) => {
          return <span style={{color: '#f94a3d',cursor: 'pointer'}} onClick={()=>this.onParcelList(record)}>{e}</span>;
        },
      },
      {
        title: "已发包裹",
        dataIndex: "deliveryNum",
        align: "center",
      },
      {
        title: "费用",
        dataIndex: "totalFee",
        align: "center",
        render: (e) => {
          return <div>{(e / 100).toFixed(2)}</div>;
        },
      },
      {
        title: "备注",
        dataIndex: "remark",
        align: "center",
        render: (e, record) => (
          <Fragment>
              <div
                className="flex-start"
                style={{
                  width: 120,
                  cursor: "pointer",
                  justifyContent: "center"
                }}
              >
                <Popover content={e} title="" trigger="hover">
                  <div
                    style={{
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                    }}>
                    {e}
                  </div>
                </Popover>
                <EditOutlined
                  style={{ fontSize: 16, marginLeft: 4, cursor: "pointer" }}
                  onClick={() =>
                    this.handleEditRemark(e, record.orderId)
                  }
                />
              </div>
          </Fragment>
        ),
      },
      {
        title: "操作",
        dataIndex: "opeator",
        align: "center",
        render: (e, record) => {
          return (
            <div style={{ color: "#f94a3d" }}>
              {record.orderStatus * 1 === 1 ? (
                <Fragment>
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={(_) => this.handleSeeOrder(record.orderId, 1, record.orderRights)}
                  >
                    立即支付
                  </div>
                  <Popconfirm
                    placement="bottom"
                    title="订单取消后将无法恢复"
                    onConfirm={(_) => this.handleCancel(record.orderId)}
                    okText="确认"
                    cancelText="取消"
                  >
                    <div style={{ cursor: "pointer" }}>取消订单</div>
                  </Popconfirm>
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={(_) => this.handleSeeOrder(record.orderId, 2)}>
                    查看包裹
                  </div>
                </Fragment>
              ) : (
                <div
                  style={{ cursor: "pointer" }}
                  onClick={(_) => this.handleSeeOrder(record.orderId, 2)}>
                  查看包裹
                </div>
              )}
              <div
                style={{ cursor: "pointer" }}
                onClick={(_) => this.handleDelOrder(record.orderId)}>
                删除订单
              </div>
            </div>
          );
        },
      },
    ];

    const parcel_columns = [
      /* {
        title: "序号",
        dataIndex: "id",
        align: "center",
        render: (e, k, id) => {
          return <div>{id + 1 < 10 ? `0${id + 1}` : id + 1}</div>;
        },
      }, */
      {
        title: "包裹状态",
        dataIndex: "parcelStatus",
        align: 'center',
        render: (e, record) => {
          return (
            <Fragment>{
              e * 1 === 9 ? (
                <Popover
                  content={(
                    <div>
                      取消时间：{record.cancelTime}
                      <div>取消原因：{record.dealMsg}</div>
                    </div>)}
                  title=""
                  trigger="hover">
                  <div
                    style={{ cursor: "pointer", color: 'red' }}>
                    {e * 1 === 9 ? "已取消" : ["", "", "待发货", "已发货"][e]}
                  </div>
                </Popover>
              ) : (
                <div>
                  {e * 1 === 9 ? "已取消" : ["", "", "待发货", "已发货"][e]}
                </div>
              )
            }
            </Fragment>
          );
        },
      },
      {
        title: "快递类型",
        dataIndex: "courierName",
        align: "center",
        width: 60
      },
      {
        title: "平台编号",
        dataIndex: "parcelSn",
        align: "center",
        render: (e) => (
          <CopyToClipboard
            text={e}
            onCopy={() => message.success("成功复制平台编号")}
          >
            <div
              style={{
                cursor: "pointer",
                wordBreak: "break-all",
                wordWrap: "break-word",
                width: 100
              }}
            >
              {e}
            </div>
          </CopyToClipboard>
        ),
      },
      {
        title: "平台",
        dataIndex: "splatform",
        align: "center",
        render: (e) => {
          return <span>{["", "淘宝/天猫", "京东", "拼多多", "其它", "抖音"][e]}</span>
        }
      },
      {
        title: "物流单号",
        dataIndex: "courierSn",
        align: "center",
        render: (e, record) => (
          <Fragment>
            {e ? (
              <div
                className="flex-start"
                style={{
                  cursor: "pointer",
                  wordBreak: "break-all",
                  wordWrap: "break-word",
                }}
              >
                <CopyToClipboard
                  text={e}
                  onCopy={() => message.success("成功复制物流单号")}
                >
                  <span>{e}</span>
                </CopyToClipboard>
                <IconFont
                  type="icon-search"
                  style={{ fontSize: 16, marginLeft: 4, cursor: "pointer" }}
                  onClick={() => this.handleSearchCourierInfo(e, record.id)}
                />
              </div>
            ) : null}
          </Fragment>
        ),
      },
      {
        title: "订单编号",
        dataIndex: "tradeSn",
        align: "center",
        render: (e) => (
          <CopyToClipboard
            text={e}
            onCopy={() => message.success("成功复制订单编号")}
          >
            <div
              style={{
                cursor: "pointer",
                wordBreak: "break-all",
                wordWrap: "break-word",
              }}
            >
              {e}
            </div>
          </CopyToClipboard>
        ),
      },
      {
        title: "收货人",
        dataIndex: "receiveName",
        align: "center",
      },
      {
        title: "联系手机",
        dataIndex: "receiveMobile",
        align: "center",
      },
      {
        title: "收货地址",
        dataIndex: "receiveProvince",
        align: "center",
        render: (e, record) => {
          return (
            <div style={{width: 120}}>
              {e}&nbsp;
              {record.receiveCity}&nbsp;
              {record.receiveArea}&nbsp;
              {record.receiveAddress}
            </div>
          );
        },
      },
      {
        title: "礼品名称",
        dataIndex: "goodsName",
        align: "center",
        width: 110,
        render: (e,record) => {
          return <div>
            {e}
            {record.instructions && (
              <Popover
                content={record.instructions}
                placement="top"
                trigger="hover">
                <IconFont style={{cursor: 'pointer'}} type="icon-bangzhu"/>
              </Popover>
            )}
          </div>;
        },
      },
      {
        title: "礼品数量",
        dataIndex: "num",
        align: "center",
        width: 60,
        render: (_) => {
          return <div>1</div>;
        },
      },
      {
        title: "费用",
        dataIndex: "parcelFee",
        align: "center",
        render: (e) => {
          return <div>￥{(e / 100).toFixed(2)}</div>;
        },
      },
      {
        title: "店铺名称",
        dataIndex: "shopName",
        align: "center",
        width: 80
      },
      {
        title: "时间",
        dataIndex: "createTime",
        align: 'center',
        render: (e, record) => {
          return (
            <Popover
              content={(
                <div>
                  <div>下单时间：{record.createTime}</div>
                  <div>发货时间：{record.deliveryTime}</div>
                  <div>取消时间：{record.cancelTime}</div>
                </div>)}
              title=""
              trigger="hover">
              <div
                style={{cursor: "pointer"}}>
                查看
              </div>
            </Popover>
          );
        },
      },
    ];

    return (
      <div>
        <div style={{ fontSize: 16, fontWeight: 600, marginBottom: 20 }}>
          订单列表
          <Tooltip title="该页面仅展示近90天数据">
            <QuestionCircleOutlined style={{ fontSize: 15, marginLeft: 4 }}/>
          </Tooltip>
        </div>
        <div className="flex" style={{display: 'flex',marginBottom: 10}}>
          <div>
            <span style={{ marginRight: 10, marginLeft: 12 }}>支付时间</span>
            <RangePicker onChange={(e) => this.changeTime(e)} showTime={{ format: 'HH:mm' }} format="YYYY-MM-DD HH:mm"/>
          </div>
          <div style={{ marginLeft: 15 }}>
            订单批次号
            <Input
              value={orderSn}
              placeholder="请输入订单批次号"
              style={{ width: 200, marginLeft: 8, marginRight: 12}}
              onChange={(e) => this.setState({ orderSn: e.target.value })}
            />
          </div>
          <div>
            <span style={{ marginRight: 10,marginLeft: 15 }}>平台</span>
            <Select
              value={platform}
              style={{ width: 150 }}
              onChange={(e) =>
                this.setState({ platform: e }, (_) => this.getOrderList())
              }
            >
              <Option value={0}>全部</Option>
              <Option value={1}>淘宝/天猫</Option>
              <Option value={2}>京东</Option>
              <Option value={3}>拼多多</Option>
              <Option value={5}>抖音</Option>
              <Option value={4}>其它</Option>
            </Select>
          </div>
        </div>
        <div style={{display: 'flex',marginBottom: 20,}}>
          <div>
            <span style={{ marginRight: 10, marginLeft: 15 }}>订单状态</span>
            <Select
              value={orderStatus}
              style={{ width: 150 }}
              onChange={(e) =>
                this.setState({ orderStatus: e }, (_) => this.getOrderList())
              }
            >
              <Option value={0}>全部</Option>
              <Option value={1}>待支付</Option>
              <Option value={2}>已支付</Option>
              <Option value={9}>已取消</Option>
            </Select>
          </div>
          <Button type="primary" style={{marginLeft: 20}} loading={loading} onClick={this.handleSubmit}>
            查询
          </Button>
          <Button
            style={{marginLeft: 20}}
            type="primary"
            onClick={this.batchDelOrder}>
            批量删除
          </Button>
        </div>
        <Table
          className="order_table"
          columns={columns}
          dataSource={order_data.list || []}
          rowKey="orderId"
          rowSelection={{
            selectedRowIds,
            onChange: (selectedRowKeys) =>
              this.handleSelectedOrder(selectedRowKeys),
          }}
          pagination={{
            current: page,
            pageSize,
            showTotal: (total) => {
              return `总计 ${total * 1 || 0} 条数据`;
            },
            total: order_data.total * 1 || 0,
            showSizeChanger: true,
            pageSizeOptions: ["10", "30", "50", "100", "200"],
            onShowSizeChange: (page, pageSize) => {
              this.setState({ page: 1, pageSize }, (_) => {
                this.getOrderList();
              });
            },
            onChange: (page) => {
              this.setState({ page }, (_) => {
                this.getOrderList();
              });
            },
          }}
        />
        <Modal
          visible={isShow}
          onCancel={(_) => this.setState({ isShow: false })}
          footer={null}
          width="1100px"
          bodyStyle={{ minHeight: 720 }}
        >
          <div className="parce_list">
            <div
              style={{ textAlign: "center", fontSize: 16, marginBottom: 20 }}
            >
              包裹信息
            </div>
            {modal_type === 2 && (
              <div className="flex-start" style={{ marginBottom: 20 }}>
                <div style={{ marginRight: 20 }}>
                  <span style={{ marginRight: 10 }}>包裹状态</span>
                  <Select
                    value={parcelStatus}
                    style={{ width: 150 }}
                    onChange={(e) => this.setState({ parcelStatus: e })}
                  >
                    <Option value={0}>全部</Option>
                    <Option value={2}>待发货</Option>
                    <Option value={3}>已发货</Option>
                    <Option value={9}>已取消</Option>
                  </Select>
                </div>
                <Button
                  type="primary"
                  onClick={this.getParcelList}
                  style={{ marginRight: 10 }}
                >
                  查询
                </Button>
                <Button type="primary" onClick={this.downloadExcel}>
                  导出表格
                </Button>
                <CopyToClipboard
                  text={
                    parcelSnList.length > 0 ? parcelSnList.join(",") : "无平台编号"
                  }
                  onCopy={() =>
                    message.success(`成功复制${parcelSnList.length}个平台编号`)
                  }>
                  <Button
                    type="primary"
                    style={{ marginLeft: 12 }}
                    disabled={selectedRowKeys.length === 0}
                  >
                    复制平台编号
                  </Button>
                </CopyToClipboard>
              </div>
            )}
            <Table
              rowSelection={modal_type === 2 ? {
                selectedRowKeys,
                onChange: (selectedRowKeys, item) =>
                  this.handleSelected(selectedRowKeys, item),
              } : ''}
              columns={modal_type === 1 ? goods_columns : parcel_columns}
              dataSource={parcel_data.list || []}
              bordered
              rowKey="tradeSn"
              pagination={{
                current: parcel_page,
                pageSize: parcel_pageSize,
                total: parcel_data.total * 1 || 0,
                showSizeChanger: false,
                onChange: (page) => {
                  this.setState({ parcel_page: page }, (_) => {
                    this.getParcelList();
                  });
                },
              }}
            />
            <div className="flex-space">
              <div></div>
              <div>
                <span
                  style={{ fontSize: 16, fontWeight: 600, marginRight: 10 }}
                >
                  合计
                </span>
                订单数:
                <span
                  style={{ color: "#f94a3d", marginRight: 10, fontWeight: 600 }}
                >
                  1
                </span>
                包裹数:
                <span
                  style={{ color: "#f94a3d", marginRight: 10, fontWeight: 600 }}
                >
                  {parcel_data.total}
                </span>
                费用:
                <span style={{ color: "#f94a3d", fontWeight: 600 }}>
                  ￥{totalFee}
                </span>
              </div>
              {/*<div style={{marginTop: 6}}>*/}
              {/*  体验金可抵扣:*/}
              {/*  <span style={{ color: "#f94a3d", fontWeight: 600 }}>*/}
              {/*    ￥{(parcel_data.total * 0.5).toFixed(2)}*/}
              {/*  </span>*/}
              {/*</div>*/}
              {/*<div style={{marginTop: 6}}>*/}
              {/*  <span style={{ fontSize: 16, fontWeight: 600, marginRight: 10 }}>*/}
              {/*    合计:*/}
              {/*  </span>*/}
              {/*  <span style={{ color: "#f94a3d", fontWeight: 600 }}>*/}
              {/*    ￥{totalFee}*/}
              {/*  </span>*/}
              {/*</div>*/}
            </div>
            {modal_type === 1 && (
              <div className="orderlist_button">
                <Button type="primary" size="large" onClick={this.handlePayOk}>
                  立即支付
                </Button>
              </div>
            )}
          </div>
        </Modal>
        {/* 订单确认 */}
        <Modal
          title="订单支付"
          visible={isShowConfirm}
          onCancel={(_) => this.setState({ isShowConfirm: false })}
          footer={null}
        >
          <div style={{ textAlign: "center", fontSize: 16 }}>
            <div style={{ fontWeight: 600, marginBottom: 10 }}>
              支付后将无法取消订单，请核对好后支付
            </div>
          </div>
          <div style={{ textAlign: "center", margin: "30px 0 20px" }}>
            <Button type="primary" size="large" onClick={this.handlePayOk}>
              确认支付￥{totalFee}
            </Button>
            <div style={{ marginTop: 10 }}>
              共用
              <span style={{ color: "#f94a3d", fontWeight: 600 }}>
                {parcel_data.total}
              </span>
              个包裹，需要支付
              <span style={{ color: "#f94a3d", fontWeight: 600 }}>
                ￥{totalFee}
              </span>
            </div>
          </div>
        </Modal>
        <Modal
          title={this.content(courierSn)}
          visible={isShowInfo}
          centered
          width="660px"
          onCancel={() => this.setState({ isShowInfo: false, courierInfo: [] })}
          footer={null}
          destroyOnClose
        >
          <div>
            {courierInfo.length > 0 ? (
              <div style={{ height: 600, overflowY: "scroll" }}>
                {courierInfo.map((item, idx) => (
                  <div
                    key={idx}
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      minHeight: 90,
                    }}
                  >
                    <div className="courier_line"></div>
                    <div>
                      <div style={{ color: "#000000" }}>{item.status}</div>
                      <div>{item.time}</div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div style={{ textAlign: "center", fontSize: 16 }}>
                暂无物流信息，请稍后查询
              </div>
            )}
            <div style={{ marginTop: 20, textAlign: "right" }}>
              <Button
                type="primary"
                onClick={() =>
                  this.setState({ isShowInfo: false, courierInfo: [] })
                }
              >
                关闭
              </Button>
            </div>
          </div>
        </Modal>
        <Modal
          title="更新备注"
          visible={isShowRemark}
          centered
          width="360px"
          onCancel={() => this.setState({ isShowRemark: false, remark: '', editOrderId: '' })}
          footer={null}
          destroyOnClose
        >
          <div>
            <div>
              <Form>
                <FormItem
                  label="备注"
                  initialValue={remark}
                  name="remark">
                  <TextArea
                    rows={3}
                    placeholder="请填写备注"
                    onChange={(e) =>
                      this.setState({ remark: e.target.value })
                    }
                  />
                </FormItem>
              </Form>
            </div>
            <div style={{ marginTop: 20, textAlign: "center" }}>
              <Button
                type="primary"
                onClick={this.saveRemarkHandler}>
                保存
              </Button>
            </div>
          </div>
        </Modal>
        {/* 扫码联系客服 */}
        <Modal
          title="Tips"
          visible={isShowKf}
          onOk={() => this.setState({isShowKf: false})}
          onCancel={() => this.setState({isShowKf: false})}
        >
          <div style={{ textAlign: "center", fontSize: 15 }}>
            <img src={`${imgRoot}/kfqw.png`} alt="" width="120px" />
            <div style={{ marginTop: 20 }}>
              <span style={{color: 'red'}}>订单已支付成功</span>，新用户首次下单后请添加客服微信，方便后续问题处理
              (若已添加，请忽略)
            </div>
          </div>
        </Modal>
        <Modal
          width={350}
          visible={isShowAuth}
          onOk={this.toAuth}
          okText="去实名"
          onCancel={(_) => this.setState({ isShowAuth: false })}>
          <div>
            <div>
              <div style={{ fontSize: 16}}>
                请先完成实名认证
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
export default OrderList;
