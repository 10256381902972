/* 帮助中心-常见问题 */
import React, { Component } from "react";
import { message } from "antd";
import { API } from "../../../static/js/common";

class Question extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      pageSize: 100,
      article_data: {},
    };
  }

  componentDidMount() {
    this.getArticleList();
  }

  getArticleList = () => {
    const { page, pageSize } = this.state;
    const reqData = { page, pageSize, type: 1 };
    API.postArticleList(reqData).then((res) => {
      if (res.status * 1 === 0) {
        this.setState({ article_data: res.extData || {} });
      } else {
        message.error(res.message || "数据加载错误");
      }
    });
  };

  render() {
    const { article_data } = this.state;
    const list = article_data.list || [];
    return (
      <div>
        <div className="recharge">常见问题</div>
        {list.map((item) => (
          <div className="question" key={item.articleId}>
            <div>{item.title}</div>
            <div dangerouslySetInnerHTML={{ __html: item.content }} />
          </div>
        ))}
        {/*  <div className="question">       
          <div>Q 礼品购购如何使用?</div>
          <div>A 具体操作步骤为：</div>
          <div>第一步：登录官网，选择礼品</div>
          <div>
            第二步：从电商平台批量导出订单，保留需要发货的信息（订单编号，姓名，地址，手机）
          </div>
          <div>第三步：导入收件人表格</div>
          <div>
            第四步：支付成功后等待快递单号生成，导出平台所给的快递单号，用打单软件一键导入电商平台并批量发货。
          </div>
          <div>注：17:00之前的包裹会在当天发货，超过17:00统一第二天发货。</div>
        </div>
        <div className="question">
          <div>Q 支持多店铺和不同电商平台发货吗？</div>
          <div>
            A
            礼品购购对店铺和电商平台没有要求，只要提供发货信息（订单编号，姓名，地址，手机）就可以出仓发货。
          </div>
        </div>
        <div className="question">
          <div>Q 支付后未出单号？</div>
          <div>
            A
            一般支付后半小时内会生成快递单号，若超过30分钟仍未有单号，可以判定为卡单。
          </div>
          <div>原因一般为：1、您上传的收件人地址有误（主要原因）</div>
          <div>2、平台其他问题（如遇特殊情况平台会提前公告通知）</div>
          <div> 解决办法：直接联系客服</div>
        </div> */}
      </div>
    );
  }
}
export default Question;
