/* eslint-disable no-useless-escape */
/*
 * fetch再封装
 */

import "whatwg-fetch";
// import { DTS_ROOT } from "./common";

// if (/localhost/gi.test(window.location.host)) {
//   document.cookie = 'ENT-USER-TOKEN=c6ea3a6af7c9e432d7b4969531c70db0';
// }

// document.cookie = 'CURRENT-USER-TOKEN=1827a05882f9fe53fb26f040afca4d8c';
// document.cookie = 'CURRENT-USER-TOKEN=d74a687c83585b396185dc92c0d29f6b';
// if (window.localStorage.getItem('SHIYONG-USER-TOKEN')) {
//   APP_TOKEN = window.localStorage.getItem('SHIYONG-USER-TOKEN')
// }
// { data:"json字符串", timestamp:"", sign:"大写(Md5(json字符串+timestamp+签名字符串))" }

/**
 * 将对象转成 '{"a": 1, "b": 2}' 或者 'a=1&b=2' 的形式
 */
function obj2String(obj, uri, arr = [], idx = 0) {
  // 无uri则为json字符串，反之为key-value字符串
  if (!uri) {
    return JSON.stringify(obj);
  }
  let ret = "";
  for (let item in obj) {
    ret += "&";
    ret +=
      item +
      "=" +
      (typeof obj[item] === "object" ? JSON.stringify(obj[item]) : obj[item]);
    // arr[idx++] = [item, obj[item]];
  }
  return ret.substring(1);
  // return new URLSearchParams(arr).toString();
}

/*
 * 时间字符串
 */

/**
 * 组装数据发起请求请求
 * @param url 请求地址
 * @param data 请求参数
 * @param method 请求方式
 * @param options 附加参数
 */
// console.log(APP_TOKEN, 9999)
function commonFetcdh(url, data, method = "GET", options = {}) {
  // if (
  //   url !== `${DTS_ROOT}/user/login` &&
  //   url !== `${DTS_ROOT}/user/register` &&
  //   url !== `${DTS_ROOT}/user/loginout` &&
  //   url !== `${DTS_ROOT}/user/resetpwd` &&
  //   url !== `${DTS_ROOT}/user/info` &&
  //   url !== `${DTS_ROOT}/user/v2/captcha` &&
  //   url !== `${DTS_ROOT}/user/captcha/login`
  // ) {
  //   data.token = window.localStorage.getItem("slpToken") || "";
  // }
  data.token = window.localStorage.getItem("slpToken") || "";
  let searchStr = obj2String({ ...data }, true);
  let initObj = { method: method };
  url += "?" + obj2String({ _t: +new Date() }, true);

  if (method === "GET") {
    // 如果是GET请求，拼接url
    if (searchStr) {
      url += "&" + searchStr;
    }
  } else {
    initObj.body = searchStr;
  }

  initObj.credentials = "include";

  if (options.headers !== undefined && typeof options.headers === "object") {
    initObj.headers = new Headers(options.headers);
  } else {
    initObj.headers = new Headers({
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    });
  }
  // initObj.headers = new Headers({
  //   'Content-Type': 'multipart/form-data',
  // });
  return fetch(url, initObj)
    .then((r) => {
      return r.status === 200
        ? options.dataType === "text"
          ? r.text()
          : r.json()
        : { status: "n" };
    })
    .then((r) => r);
}

/**
 * GET请求
 * @param url 请求地址
 * @param data 请求参数
 * @param options 附加参数
 */
export const GET = (url, data, options) => {
  return commonFetcdh(url, data, "GET", options);
};

/**
 * POST请求
 * @param url 请求地址
 * @param data 请求参数
 * @param options 附加参数
 */
export const POST = (url, data, options) => {
  return commonFetcdh(url, data, "POST", options);
};
