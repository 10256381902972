// 退单列表
import React, { Fragment } from "react";
import {
  Input,
  Button,
  Table,
  message,
  Select,
  DatePicker,
  Modal,
  Popover,
  Tooltip,
  Spin,
} from "antd";
import {CopyOutlined, QuestionCircleOutlined} from '@ant-design/icons';
import { API, ROOT, IconFont } from "../../../static/js/common";
import moment from "moment";
import { CopyToClipboard } from "react-copy-to-clipboard";

const { Option } = Select;
const { RangePicker } = DatePicker;
const { confirm } = Modal;

class CancelList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      pageSize: 10,
      parcelSn: "",
      platform: 0,
      parcelData: {},
      loading: false,
      courierInfo: [],
      courierSn: "",
      couriersn: "",
      selectedRowKeys: [],
      hideChecked: true,
      isShowInfo: false,
      createStart: "",
      createEnd: "",
    };
  }

  componentDidMount() {
    if(this.getUrlParams('orderId')){
      this.setState({searchId: this.getUrlParams('orderId')},(_) => this.getData());
      return;
    }
    if(this.getUrlParams('status')){
      this.setState({parcelStatus: parseInt(this.getUrlParams('status'))},(_) => this.getData());
      return;
    }
    this.getData();
  }

  getUrlParams = (name) => {
    const reg = new RegExp(`(^|&)${ name}=([^&]*)(&|$)`);
    const r = this.props.location.search.substr(1).match(reg);
    if (r != null) return  decodeURIComponent(r[2]); return null;
  };

  getData() {
    const {
      page,
      pageSize,
      platform,
      courierSn,
      parcelSn,
      createStart,
      createEnd,
    } = this.state;
    const reqData = { page, pageSize, platform };
    if (courierSn) {
      reqData.courierSn = courierSn;
    }
    if (parcelSn) {
      reqData.parcelSn = parcelSn;
    }
    if (createStart && createEnd) {
      reqData.createStart = createStart;
      reqData.createEnd = createEnd;
    }
    this.setState({ loading: true });
    API.getCancelList(reqData).then((res) => {
      this.setState({ loading: false });
      if (res.status * 1 === 0) {
        res.extData.list.forEach(item => {
          item.receiveNameHide = item.receiveName.slice(0,1) + '**';
          item.receiveMobileHide = item.receiveMobile ? item.receiveMobile.slice(0,3)+'****'+item.receiveMobile.slice(-4) : '';
          item.receiveAddressHide = item.receiveProvince +' '+item.receiveCity + ' ' +item.receiveArea + '********';
        });
        this.setState({ parcelData: res.extData || {} });
      } else {
        message.error(res.message || "数据加载错误");
      }
      this.setState({loading: false, hideLoading: false})
    });
  }

  handleSubmit = () => {
    this.setState({ page: 1}, (_) => this.getData());
  };

  handleDownload = () => {
    const {
      parcelStatus,
      selKey,
      selVal,
      createStart,
      createEnd,
      platform,
      orderSn,
      scheck
    } = this.state;
    let url = "";
    let str = "";
    if (selKey) {
      str += `&selKey=${selKey}`;
    }
    if (selVal) {
      str += `&selVal=${selVal}`;
    }
    if (orderSn) {
      str += `&orderSn=${orderSn}`;
    }
    if (createStart && createEnd) {
      url = `&createStart=${createStart}&createEnd=${createEnd}`;
    }
    str += `&scheck=${scheck}`;
    const token = window.localStorage.getItem("slpToken") || "";
    window.open(
      `${ROOT}/export/parcel?_t=${new Date().valueOf()}&token=${token}&parcelStatus=${parcelStatus}&platform=${platform}${str}${url}`
    );
  };

  // 选择日期
  changeTime = (e) => {
    let { createStart, createEnd } = this.state;
    if (e) {
      createStart = moment(e[0]).format("YYYY-MM-DD HH:mm");
      createEnd = moment(e[1]).format("YYYY-MM-DD HH:mm");
    } else {
      createStart = "";
      createEnd = "";
    }
    this.setState({ createStart, createEnd });
  };

  handleSearchCourierInfo = (kno, parcelId) => {
    let k_no = kno;
    if(kno.indexOf('SF')>-1){
      k_no = k_no + ':6086';
    }
    API.getSearchCourierInfo({
      kno: k_no,
      parcelId,
    }).then((res) => {
      if (res.status * 1 === 0) {
        const data = res.extData || {};
        const courierInfo = data.wuliu ? JSON.parse(data.wuliu) : [];
        this.setState({ courierInfo, isShowInfo: true, couriersn: kno });
      } else {
        message.error(res.message || "物流信息加载错误");
      }
    });
  };

  content = (sn) => {
    return (
      <span>
        物流信息（{sn}
        <CopyToClipboard
          text={sn}
          onCopy={() =>
            message.success(`复制成功`)
          }>
         <CopyOutlined style={{marginLeft: 3, color: '#999', fontSize: 14, cursor: "pointer"}}/>
        </CopyToClipboard>
        ）
      </span>
    );
  };

  instruction = (text) => {
    return (
      <span>
        <strong>使用说明：</strong>
        {text}
      </span>
    );
  };

  changeSwitch = (e) => {
    this.setState({hideLoading: true});
    setTimeout(()=>{
      this.setState({hideChecked: e, hideLoading: false})
    }, 600)
  };

  handleSelectedParcel = (selectedRowKeys) => {
    this.setState({ selectedRowKeys: selectedRowKeys});
  };

  batchDelParcel = () => {
    const { selectedRowKeys } = this.state;
    if(!selectedRowKeys.length){
      message.warning("请勾选要删除的包裹");
      return;
    }
    this.handleDelParcel(selectedRowKeys.join(','));
  };

  handleDelParcel = (id) => {
    const _this = this;
    confirm({
      content: "确认要删除吗",
      onOk() {
        const reqData = {
          parcelIds: id
        };
        API.postDelParcel(reqData).then((res) => {
          if (res.status * 1 === 0) {
            message.success("删除成功");
            _this.handleSubmit();
          } else {
            message.error(res.message || "数据加载错误");
          }
        });
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  render() {
    const {
      page,
      pageSize,
      parcelData,
      loading,
      platform,
      courierInfo,
      isShowInfo,
      courierSn,
      couriersn,
      hideChecked,
      hideLoading,
      parcelSn
    } = this.state;

    const columns = [
      {
        title: "取消时间",
        dataIndex: "cancelTime"
      },
      {
        title: "快递公司",
        dataIndex: "courierName",
        align: 'center'
      },
      {
        title: "物流单号",
        dataIndex: "courierSn",
        render: (e, record) => (
          <Fragment>
            {e ? (
              <div
                className="flex-start"
                style={{
                  wordBreak: "break-all",
                  wordWrap: "break-word",
                  cursor: "pointer",
                }}
              >
                <CopyToClipboard
                  text={e}
                  onCopy={() => message.success("成功复制物流单号")}
                >
                  <span>{e}</span>
                </CopyToClipboard>
                <IconFont
                  type="icon-search"
                  style={{ fontSize: 16, marginLeft: 4, cursor: "pointer" }}
                  onClick={() =>
                    this.handleSearchCourierInfo(e, record.parcelId)
                  }
                />
              </div>
            ) : null}
          </Fragment>
        ),
      },
      {
        title: "平台编号",
        dataIndex: "parcelSn",
        render: (e,record) => (
          <CopyToClipboard
            text={e}
            onCopy={() => message.success("成功复制平台编号")}
          >
            <div style={{ cursor: "pointer",wordWrap: "break-word", textAlign: "left" , width: 100, display: 'flex'}}>
              {parseInt(record.scheck) ? (
                <span style={{display:"inline-block", width: '5px',height: '5px', background: '#2add48',
                  borderRadius: '50%', marginTop: '7px',marginRight: '3px'}}></span>
              ):""}
              <div style={{  width: 92 }}>{e}</div>
            </div>
          </CopyToClipboard>
        ),
      },
      {
        title: "平台",
        dataIndex: "platformId",
        align: "center",
        render: (e) => (
          <div>
            {['','淘宝/天猫','京东','拼多多','其它', "抖音"][e]}
          </div>
        ),
      },
      {
        title: "收货人",
        dataIndex: "receiveName",
        align: 'center',
        render: (e, record) => (
          <div>
            {hideChecked ? e : record.receiveNameHide}
          </div>
        ),
      },
      {
        title: "联系方式",
        dataIndex: "receiveMobile",
        render: (e, record) => (
          <div>
            {hideChecked ? e : record.receiveMobileHide}
          </div>
        ),
      },
      {
        title: "收货地址",
        dataIndex: "receiveAddress",
        render: (e, record) => (
          <div style={{width: 90}}>
            {hideChecked ? (<div>
                {record.receiveProvince} &nbsp;
                {record.receiveCity} &nbsp;
                {record.receiveArea} &nbsp;
                {e}
              </div>)
              : record.receiveAddressHide}
          </div>
        ),
      },
      {
        title: "礼品名称",
        dataIndex: "goodsName",
        width: 100,
        render: (e,record) => {
          return <div>
            {e}
            {record.instructions && (
              <Popover
                width="200px"
                content={this.instruction(record.instructions)}
                placement="top"
                trigger="hover">
                <IconFont style={{cursor: 'pointer',fontWeight: 'bold'}} type="icon-bangzhu"/>
              </Popover>
            )}
          </div>;
        },
      },
      {
        title: "费用",
        dataIndex: "parcelFee",
        render: (e) => {
          return <div>￥{(e / 100).toFixed(2)}</div>;
        },
      }
    ];

    return (
      <div>
        <div style={{ fontSize: 16, fontWeight: 600, marginBottom: 10 }}>
          退单列表
          <Tooltip title="该页面仅展示近90天数据">
            <QuestionCircleOutlined style={{ fontSize: 15, marginLeft: 4 }}/>
          </Tooltip>
        </div>
        <div style={{ fontSize: 14, marginBottom: 10, color: '#707070'}}>
          可查询已出物流单号因疫情原因无法揽件或快递停发退单取消的包裹，都已发过短信提醒。
        </div>
        <div className="flex-start" style={{ fontSize: 14, marginBottom: 10}}>
          <div style={{ marginRight: 15}}>
            <span style={{ marginRight: 10 }}>取消时间</span>
            <RangePicker onChange={(e) => this.changeTime(e)} showTime={{ format: 'HH:mm' }} format="YYYY-MM-DD HH:mm" style={{width: 200 }}/>
          </div>
          <div style={{marginLeft: 0}}>
            <span style={{marginRight: 10}}>物流单号</span>
            <Input
              value={courierSn}
              placeholder="请输入物流单号"
              style={{width: 200, marginLeft: 8, marginRight: 15}}
              onChange={(e) => this.setState({courierSn: e.target.value})}
            />
          </div>
          <div style={{marginLeft: 0}}>
            <span style={{marginRight: 10}}>平台编号</span>
            <Input
              value={parcelSn}
              placeholder="请输入平台编号"
              style={{width: 200, marginLeft: 8, marginRight: 15}}
              onChange={(e) => this.setState({parcelSn: e.target.value})}
            />
          </div>
          <div style={{marginLeft: 0}}>
            <span style={{marginRight: 10}}>平台</span>
            <Select
              value={platform}
              style={{width: 100}}
              onChange={(e) => this.setState({platform: e})}
            >
              <Option value={0}>全部</Option>
              <Option value={1}>淘宝/天猫</Option>
              <Option value={2}>京东</Option>
              <Option value={3}>拼多多</Option>
              <Option value={5}>抖音</Option>
              <Option value={4}>其它</Option>
            </Select>
          </div>
        </div>
        <div>
          <Button type="primary" onClick={this.handleSubmit} loading={loading} style={{marginBottom: 18}}>
            查询
          </Button>
        </div>
        <div style={{paddingBottom: 50}} className="parce_list">
          <Spin spinning={hideLoading}>
            <Table
              dataSource={parcelData.list || []}
              columns={columns}
              loading={loading}
              rowKey="id"
              pagination={{
                total: parcelData.total * 1 || 0, //数据总数量
                showTotal: (total) => {
                  return `总计 ${total * 1 || 0} 条数据`;
                },
                pageSize, //显示几条一页
                current: page,
                showSizeChanger: true,
                pageSizeOptions: ["10", "30", "50", "100", "200"],
                onShowSizeChange: (page, pageSize) => {
                  this.setState({page: 1, pageSize}, (_) => {
                    this.getData();
                  });
                },
                onChange: (page) => {
                  this.setState({page}, (_) => {
                    this.getData();
                  });
                },
              }}
            />
          </Spin>
        </div>
        <Modal
          title={this.content(couriersn)}
          visible={isShowInfo}
          centered
          width="660px"
          onCancel={() => this.setState({ isShowInfo: false, courierInfo: [] })}
          footer={null}
          destroyOnClose
        >
          <div>
            {courierInfo.length > 0 ? (
              <div style={{ height: 600, overflowY: "scroll" }}>
                {courierInfo.map((item, idx) => (
                  <div
                    key={idx}
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      minHeight: 90,
                    }}
                  >
                    <div className="courier_line"></div>
                    <div>
                      <div style={{ color: "#000000" }}>{item.status}</div>
                      <div>{item.time}</div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div style={{ textAlign: "center", fontSize: 16 }}>
                暂无物流信息，请稍后查询
              </div>
            )}
            <div style={{ marginTop: 20, textAlign: "right" }}>
              <Button
                type="primary"
                onClick={() =>
                  this.setState({ isShowInfo: false, courierInfo: [] })
                }
              >
                关闭
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
export default CancelList;
