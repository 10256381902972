// 包裹列表
import React, { Fragment } from "react";
import {
  Input,
  Button,
  Table,
  message,
  Select,
  DatePicker,
  Modal,
  Popover,
  Tooltip,
  Radio,
  Switch,
  Spin,
  Popconfirm, Tabs
} from "antd";
import {CopyOutlined, QuestionCircleOutlined} from '@ant-design/icons';
import { API, ROOT, IconFont } from "../../../static/js/common";
import moment from "moment";
import { CopyToClipboard } from "react-copy-to-clipboard";

const { Option } = Select;
const { RangePicker } = DatePicker;
const { TextArea } = Input;
const { confirm } = Modal;

class ParcelList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      pageSize: 10,
      parcelStatus: 0,
      createStart: "",
      createEnd: "",
      selKey: "parcel_sn",
      selVal: "",
      orderSn: "",
      platform: 0,
      parcelData: {},
      loading: false,
      courierInfo: [],
      isShowInfo: false,
      courierSn: "",
      searchId: "",
      isBatchSearch: false,
      searchSns: "",
      searchType: "parcel_sn",
      hideChecked: false,
      hideLoading: false,
      selectedRowKeys: [],
      scheck: -1,
      isShowVideo: false
    };
  }

  componentDidMount() {
    if(this.getUrlParams('orderId')){
      this.setState({searchId: this.getUrlParams('orderId')},(_) => this.getData());
      return;
    }
    if(this.getUrlParams('status')){
      this.setState({parcelStatus: parseInt(this.getUrlParams('status'))},(_) => this.getData());
      return;
    }
    this.getData();
  }

  getUrlParams = (name) => {
    const reg = new RegExp(`(^|&)${ name}=([^&]*)(&|$)`);
    const r = this.props.location.search.substr(1).match(reg);
    if (r != null) return  decodeURIComponent(r[2]); return null;
  };

  getData() {
    const {
      page,
      pageSize,
      parcelStatus,
      createStart,
      createEnd,
      selKey,
      selVal,
      orderSn,
      platform,
      searchId,
      searchSns,
      searchType,
      scheck
    } = this.state;
    const reqData = { page, pageSize, parcelStatus, platform, scheck };
    if (createStart && createEnd) {
      reqData.createStart = createStart;
      reqData.createEnd = createEnd;
    }
    if (selKey) {
      reqData.selKey = selKey;
    }
    if (selVal) {
      reqData.selVal = selVal;
    }else if(searchSns){
      reqData.selVal = searchSns.split('\n').map(item => item.trim()).join(',');
      reqData.selKey = searchType;
    }
    if (orderSn) {
      reqData.orderSn = orderSn;
    }
    if(searchId){
      reqData.orderId = searchId;
    }
    this.setState({ loading: true });
    API.getParcel(reqData).then((res) => {
      this.setState({ loading: false });
      if (res.status * 1 === 0) {
        res.extData.list.forEach(item => {
          // item.receiveNameHide = item.receiveName.replace(/(?<=.)./g, '*');
          item.receiveNameHide = item.receiveName.slice(0,1) + '**';
          // item.receiveMobileHide = item.receiveMobile.replace(/(\d{3})\d+(\d{4})$/, '$1****$2');
          item.receiveMobileHide = item.receiveMobile ? item.receiveMobile.slice(0,3)+'****'+item.receiveMobile.slice(-4) : '';
          item.receiveAddressHide = item.receiveProvince +' '+item.receiveCity + ' ' +item.receiveArea + '********';
        });
        this.setState({ parcelData: res.extData || {} });
      } else {
        message.error(res.message || "数据加载错误");
      }
      this.setState({searchSns: "", searchType: "parcel_sn"})
    });
  }

  handleSubmit = () => {
    this.setState({ page: 1, isBatchSearch: false, selectedRowKeys: [] }, (_) => this.getData());
  };

  handleDownload = () => {
    const {
      parcelStatus,
      selKey,
      selVal,
      createStart,
      createEnd,
      platform,
      orderSn,
      scheck
    } = this.state;
    let url = "";
    let str = "";
    if (selKey) {
      str += `&selKey=${selKey}`;
    }
    if (selVal) {
      str += `&selVal=${selVal}`;
    }
    if (orderSn) {
      str += `&orderSn=${orderSn}`;
    }
    if (createStart && createEnd) {
      url = `&createStart=${createStart}&createEnd=${createEnd}`;
    }
    str += `&scheck=${scheck}`;
    const token = window.localStorage.getItem("slpToken") || "";
    window.open(
      `${ROOT}/export/parcel?_t=${new Date().valueOf()}&token=${token}&parcelStatus=${parcelStatus}&platform=${platform}${str}${url}`
    );
  };

  handleDownloadParcel = () => {
    if (window._hmt) {
      window._hmt.push(['_trackEvent', 'parcellist', 'button', 'downloadParcel'])
    }
    const {
      parcelStatus,
      selKey,
      selVal,
      createStart,
      createEnd,
      platform,
      orderSn,
      scheck
    } = this.state;
    let url = "";
    let str = "";
    if (selKey) {
      str = `&selKey=${selKey}`;
    }
    if (selVal) {
      str += `&selVal=${selVal}`;
    }
    if (orderSn) {
      str += `&orderSn=${orderSn}`;
    }
    if (createStart && createEnd) {
      url = `&createStart=${createStart}&createEnd=${createEnd}`;
    }
    str += `&scheck=${scheck}`;
    const token = window.localStorage.getItem("slpToken") || "";
    window.open(
      `${ROOT}/export/parcelV2?_t=${new Date().valueOf()}&token=${token}&parcelStatus=${parcelStatus}&platform=${platform}${str}${url}`
    );
  }

  // 选择日期
  changeTime = (e) => {
    let { createStart, createEnd } = this.state;
    if (e) {
      createStart = moment(e[0]).format("YYYY-MM-DD HH:mm");
      createEnd = moment(e[1]).format("YYYY-MM-DD HH:mm");
    } else {
      createStart = "";
      createEnd = "";
    }
    this.setState({ createStart, createEnd });
  };

  handleSearchCourierInfo = (kno, parcelId) => {
    let k_no = kno;
    if(kno.indexOf('SF')>-1){
      k_no = k_no + ':6086';
    }
    API.getSearchCourierInfo({
      kno: k_no,
      parcelId,
    }).then((res) => {
      if (res.status * 1 === 0) {
        const data = res.extData || {};
        const courierInfo = data.wuliu ? JSON.parse(data.wuliu) : [];
        this.setState({ courierInfo, isShowInfo: true, courierSn: kno });
      } else {
        message.error(res.message || "物流信息加载错误");
      }
    });
  };

  content = (sn) => {
    return (
      <span>
        物流信息（{sn}
        <CopyToClipboard
          text={sn}
          onCopy={() =>
            message.success(`复制成功`)
          }>
         <CopyOutlined style={{marginLeft: 3, color: '#999', fontSize: 14, cursor: "pointer"}}/>
        </CopyToClipboard>
        ）
      </span>
    );
  };

  instruction = (text) => {
    return (
      <span>
        <strong>使用说明：</strong>
        {text}
      </span>
    );
  };

  changeSwitch = (e) => {
    this.setState({hideLoading: true});
    setTimeout(()=>{
      this.setState({hideChecked: e, hideLoading: false})
    }, 600)
  };

  handleSelectedParcel = (selectedRowKeys) => {
    this.setState({ selectedRowKeys: selectedRowKeys});
  };

  batchDelParcel = () => {
    const { selectedRowKeys } = this.state;
    if(!selectedRowKeys.length){
      message.warning("请勾选要删除的包裹");
      return;
    }
    this.handleDelParcel(selectedRowKeys.join(','));
  };

  handleDelParcel = (id) => {
    const _this = this;
    confirm({
      content: "确认要删除吗",
      onOk() {
        const reqData = {
          parcelIds: id
        };
        API.postDelParcel(reqData).then((res) => {
          if (res.status * 1 === 0) {
            message.success("删除成功");
            _this.handleSubmit();
          } else {
            message.error(res.message || "数据加载错误");
          }
        });
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  batchRemark = () => {
    const { selectedRowKeys } = this.state;
    if(!selectedRowKeys.length){
      message.warning("请勾选要标记的包裹");
      return;
    }
    this.handleRemark(selectedRowKeys.join(','), 1);
  };
  batchCancelRemark = () => {
    const { selectedRowKeys } = this.state;
    if(!selectedRowKeys.length){
      message.warning("请勾选要取消标记的包裹");
      return;
    }
    this.handleRemark(selectedRowKeys.join(','), 0);
  };

  handleRemark = (id, type) => {
    const _this = this;
    confirm({
      content: "确认要"+ (parseInt(type) ? "标记" : "取消标记") +"吗？",
      onOk() {
        const reqData = {
          parcelIds: id,
          scheck: parseInt(type) ? 1 : 0
        };
        API.postRemarkParcel(reqData).then((res) => {
          if (res.status * 1 === 0) {
            message.success(parseInt(type) ? "标记成功" : "取消成功");
            _this.handleSubmit();
          } else {
            message.error(res.message || "数据加载错误");
          }
        });
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  title = () => {
    return (
      <span>
        发货助手教程
        <span style={{marginLeft: 10, color: '#f94a3d',fontWeight: 'bold', cursor: 'pointer'}}
              onClick={() => window.open('https://shuidilipin.com/help.html','_blank')}
        >插件下载</span>
         <span style={{marginLeft: 10, color: '#f94a3d',fontWeight: 'bold', cursor: 'pointer'}}
               onClick={() => window.open('https://dfcdn.shuidilipin.com/source/daifa/%E5%8F%91%E8%B4%A7%E5%8A%A9%E6%89%8B%E4%BD%BF%E7%94%A8%E6%95%99%E7%A8%8B.pdf','_blank')}
         >文档查看</span>
      </span>
    );
  };

  render() {
    const {
      page,
      pageSize,
      parcelData,
      loading,
      parcelStatus,
      selKey,
      selVal,
      orderSn,
      platform,
      courierInfo,
      isShowInfo,
      courierSn,
      isBatchSearch,
      searchSns,
      searchType,
      hideChecked,
      hideLoading,
      selectedRowKeys,
      scheck,
      isShowVideo
    } = this.state;

    const columns = [
      /* {
        title: "序号",
        dataIndex: "parcelId",
      }, */
      {
        title: "平台编号",
        dataIndex: "parcelSn",
        render: (e,record) => (
          <CopyToClipboard
            text={e}
            onCopy={() => message.success("成功复制平台编号")}
          >
            <div style={{ cursor: "pointer",wordWrap: "break-word", textAlign: "left" , width: 100, display: 'flex'}}>
              {parseInt(record.scheck) ? (
                <span style={{display:"inline-block", width: '5px',height: '5px', background: '#2add48',
                  borderRadius: '50%', marginTop: '7px',marginRight: '3px'}}></span>
              ):""}
              <div style={{  width: 92 }}>{e}</div>
            </div>
          </CopyToClipboard>
        ),
      },
      {
        title: "平台",
        dataIndex: "splatforms",
        align: "center",
      },
      {
        title: "包裹状态",
        dataIndex: "parcelStatus",
        align: 'center',
        render: (e, record) => {
          return (
            <Fragment>{
              e * 1 === 9 ? (
                <Popover
                  content={(
                    <div>
                      取消时间：{record.cancelTime}
                      <div>取消原因：{record.dealMsg}</div>
                      </div>)}
                  title=""
                  trigger="hover">
                  <div
                    style={{ cursor: "pointer", color: 'red' }}
                  >
                    {e * 1 === 9 ? "已取消" : ["", "待支付", "待发货", "已发货"][e]}
                  </div>
                </Popover>
              ) : (
                <div>
                  {e * 1 === 9 ? "已取消" : ["", "待支付", "待发货", "已发货"][e]}
                </div>
              )
            }
            </Fragment>
          );
        },
      },
      {
        title: "仓库",
        dataIndex: "addr",
      },
      {
        title: "快递公司",
        dataIndex: "courierName",
        align: 'center'
      },
      {
        title: "物流单号",
        dataIndex: "courierSn",
        render: (e, record) => (
          <Fragment>
            {record.parcelStatus * 1 !== 9 ? (e ? (
              <div
                className="flex-start"
                style={{
                  wordBreak: "break-all",
                  wordWrap: "break-word",
                  cursor: "pointer",
                }}
              >
                <CopyToClipboard
                  text={e}
                  onCopy={() => message.success("成功复制物流单号")}
                >
                  <span>{e}</span>
                </CopyToClipboard>
                <IconFont
                  type="icon-search"
                  style={{ fontSize: 16, marginLeft: 4, cursor: "pointer" }}
                  onClick={() =>
                    this.handleSearchCourierInfo(e, record.parcelId)
                  }
                />
              </div>
            ) : null) : (
              <span style={{color: 'red'}}>{record.courierSnBak}</span>
            )}
          </Fragment>
        ),
      },
      {
        title: "交易单号",
        dataIndex: "tradeSn",
        render: (e, record) => (
          <Fragment>
            {e ? (
              <div
                className=""
                style={{
                  wordBreak: "break-all",
                  wordWrap: "break-word",
                  cursor: "pointer",
                }}
              >
                <span>{e}</span>
                <CopyToClipboard
                  text={e}
                  onCopy={() => message.success("成功复制交易单号")}
                >
                  <CopyOutlined style={{marginLeft: 2, color: '#999'}}/>
                </CopyToClipboard>
              </div>
            ) : null}
          </Fragment>
        ),
      },
      {
        title: "收货人",
        dataIndex: "receiveName",
        align: 'center',
        render: (e, record) => (
          <div>
            {hideChecked ? e : record.receiveNameHide}
          </div>
        ),
      },
      {
        title: "联系方式",
        dataIndex: "receiveMobile",
        render: (e, record) => (
          <div>
            {hideChecked ? e : record.receiveMobileHide}
          </div>
        ),
      },
      {
        title: "收货地址",
        dataIndex: "receiveAddress",
        render: (e, record) => (
          <div style={{width: 90}}>
            {hideChecked ? (<div>
                {record.receiveProvince} &nbsp;
                {record.receiveCity} &nbsp;
                {record.receiveArea} &nbsp;
                {e}
              </div>)
              : record.receiveAddressHide}
          </div>
        ),
      },
      {
        title: "礼品名称",
        dataIndex: "goodsName",
        width: 100,
        render: (e,record) => {
          return <div>
            {e}
            {record.instructions && (
              <Popover
                width="200px"
                content={this.instruction(record.instructions)}
                placement="top"
                trigger="hover">
                <IconFont style={{cursor: 'pointer',fontWeight: 'bold'}} type="icon-bangzhu"/>
              </Popover>
            )}
          </div>;
        },
      },
      {
        title: "数量",
        dataIndex: "goodsNum",
        align: 'center'
      },
      {
        title: "费用",
        dataIndex: "parcelFee",
        render: (e) => {
          return <div>￥{(e / 100).toFixed(2)}</div>;
        },
      },
      {
        title: "店铺名称",
        dataIndex: "shopName",
        width: 80
      },
      {
        title: "下单时间",
        dataIndex: "createTime",
        align: "center",
        width: 100
      },
      {
        title: "操作",
        dataIndex: "opeator",
        align: "center",
        render: (e, record) => {
          return (
            <div style={{ color: "#f94a3d" }}>
              <div
                style={{ cursor: "pointer" }}
                onClick={(_) => this.handleDelParcel(record.parcelId)}>
                删除
              </div>
              {
                parseInt(record.scheck) ?
                (<div
                  style={{ cursor: "pointer", color: "#2add48" }}
                  onClick={(_) => this.handleRemark(record.parcelId, 0)}>
                  取消标记
                </div>) : ''
              }
              {
                !parseInt(record.scheck) ?
                  (<div
                    style={{ cursor: "pointer", color: "#2add48" }}
                    onClick={(_) => this.handleRemark(record.parcelId, 1)}>
                    标记
                  </div>) : ''
              }
            </div>
          );
        },
      },
    ];

    return (
      <div>
        <div style={{ fontSize: 16, fontWeight: 600, marginBottom: 10 }}>
          包裹列表
          <Tooltip title="该页面仅展示近90天数据">
            <QuestionCircleOutlined style={{ fontSize: 15, marginLeft: 4 }}/>
          </Tooltip>
        </div>
        <div style={{ fontSize: 14, marginBottom: 10, display: 'flex', alignItems: 'center'}}>
          <span style={{opacity: 0.6}}>该页面涉及用户隐私信息，请注意数据安全，如需显示全部用户信息请点击右侧按钮</span>
          <Switch style={{ marginLeft: 10 }}
                  checkedChildren="显示"
                  unCheckedChildren="隐藏"
                  checked={hideChecked}
                  onChange={(e)=>{this.changeSwitch(e)}}/>
        </div>
        {/*<div style={{ fontSize: 14, marginBottom: 10,  alignItems: 'center'}}>
          <span style={{color: '#707070'}}>如需使用
             <span style={{marginLeft: 6, color: 'rgb(51, 170, 255)',fontWeight: 'bold', cursor: 'pointer'}}
                   onClick={() => window.open('https://shuidilipin.com/help.html','_blank')}
             >水滴插件</span>
            【发货助手】一键发货，可标记已发货的包裹筛选并<span style={{color: 'rgb(51, 170, 255)'}}>导出发货助手表单</span>，仅支持淘宝/天猫平台
            <span style={{marginLeft: 6, color: 'rgb(51, 170, 255)',fontWeight: 'bold', cursor: 'pointer'}}
                  onClick={() => this.setState({isShowVideo: true})}
            >查看教程</span>。</span>
        </div>*/}
        <div className="flex-start">
          <div style={{ marginRight: 20 }}>
            <span style={{ marginRight: 10 }}>下单时间</span>
            <RangePicker onChange={(e) => this.changeTime(e)} showTime={{ format: 'HH:mm' }} format="YYYY-MM-DD HH:mm"/>
          </div>
          <div>
            <span style={{ marginRight: 10 }}>包裹状态</span>
            <Select
              value={parcelStatus}
              style={{ width: 100 }}
              onChange={(e) => this.setState({ parcelStatus: e })}
            >
              <Option value={0}>全部</Option>
              <Option value={1}>待支付</Option>
              <Option value={2}>待发货</Option>
              <Option value={3}>已发货</Option>
              <Option value={9}>已取消</Option>
            </Select>
          </div>
          <div style={{ width: 330, marginLeft: 18 }}>
            <Select
              value={selKey}
              style={{ width: 120 }}
              onChange={(e) => this.setState({ selKey: e })}
            >
              <Option value="">请选择</Option>
              <Option value="parcel_sn">平台编号</Option>
              <Option value="trade_sn">第三方订单号</Option>
              <Option value="shop_name">店铺名称</Option>
              <Option value="courier_sn">物流单号</Option>
              <Option value="receive_name">姓名</Option>
              <Option value="receive_mobile">手机号</Option>
            </Select>
            <Input
              value={selVal}
              placeholder="请输入您要查询的信息"
              style={{ width: 200 }}
              onChange={(e) => this.setState({ selVal: e.target.value })}
            />
          </div>
          <div style={{ marginLeft: 20 }}>
            <span style={{ marginRight: 10 }}>平台</span>
            <Select
              value={platform}
              style={{ width: 100 }}
              onChange={(e) => this.setState({ platform: e })}
            >
              <Option value={0}>全部</Option>
              <Option value={1}>淘宝/天猫</Option>
              <Option value={2}>京东</Option>
              <Option value={3}>拼多多</Option>
              <Option value={5}>抖音</Option>
              <Option value={4}>其它</Option>
            </Select>
          </div>
        </div>
        <div style={{ margin: "10px 0", display: 'flex' }}>
          <div>
            订单批次号
            <Tooltip title="可在右侧订单列表中获取">
              <QuestionCircleOutlined style={{ fontSize: 13, marginLeft: 4 }}/>
            </Tooltip>
            <Input
              value={orderSn}
              placeholder="请输入订单批次号"
              style={{ width: 200, marginLeft: 8, marginRight: 12}}
              onChange={(e) => this.setState({ orderSn: e.target.value })}
            />
          </div>
          <div style={{ marginLeft: 20 }}>
            <span style={{ marginRight: 4 }}>标记状态</span>
            <Tooltip title="仅支持已发货的包裹可标记成功">
              <QuestionCircleOutlined style={{ fontSize: 13, marginRight: 8 }}/>
            </Tooltip>
            <Select
              value={scheck}
              style={{ width: 100 }}
              onChange={(e) => this.setState({ scheck: e })}
            >
              <Option value={-1}>全部</Option>
              <Option value={1}>已标记</Option>
              <Option value={0}>未标记</Option>
            </Select>
          </div>
        </div>
        <div style={{ margin: "10px 0 20px", display: 'flex' }}>
          <Button type="primary" onClick={this.handleSubmit} loading={loading}>
            查询
          </Button>
          <Button
            type="primary"
            onClick={this.handleDownload}
            style={{ marginLeft: 12 }}
          >
            导出
          </Button>
          <Button
            type="primary"
            onClick={()=>{this.setState({isBatchSearch: true})}}
            style={{ marginLeft: 12 }}>
            批量查询
          </Button>
          <Button
            type="primary"
            onClick={this.batchDelParcel}
            style={{ marginLeft: 12 }}>
            批量删除
          </Button>
          <Tooltip title="仅支持淘宝/天猫平台订单，已出物流单号的订单可标记。">
            {/*，在水滴插件通过订单批次号搜索已标记包裹一件发货*/}
            <Button
              type="primary"
              onClick={this.batchRemark}
              style={{marginLeft: 12}}>
              批量标记
            </Button>
          </Tooltip>
          {/*<Tooltip title="对已标记的包裹取消，取消后水滴插件不能搜索出结果。">*/}
            <Button
              type="primary"
              onClick={this.batchCancelRemark}
              style={{marginLeft: 12}}>
              取消标记
            </Button>
          {/*</Tooltip>*/}
          {/*<Tooltip title="需要先标记，再导出，仅支持已出物流单号的包裹订单，可在发货助手直接上传一件发货。">
            <Button
              type="primary"
              onClick={this.handleDownloadParcel}
              style={{marginLeft: 12}}>
              导出发货助手表单
            </Button>
          </Tooltip>*/}
        </div>
        <div style={{paddingBottom: 50}} className="parce_list">
          <Spin spinning={hideLoading}>
            <Table
              dataSource={parcelData.list || []}
              columns={columns}
              loading={loading}
              rowKey="parcelId"
              rowSelection={{
                selectedRowKeys,
                onChange: (selectedRowKeys) =>
                  this.handleSelectedParcel(selectedRowKeys),
              }}
              pagination={{
                total: parcelData.total * 1 || 0, //数据总数量
                showTotal: (total) => {
                  return `总计 ${total * 1 || 0} 条数据`;
                },
                pageSize, //显示几条一页
                current: page,
                showSizeChanger: true,
                pageSizeOptions: ["10", "30", "50", "100", "200"],
                onShowSizeChange: (page, pageSize) => {
                  this.setState({page: 1, pageSize}, (_) => {
                    this.getData();
                  });
                },
                onChange: (page) => {
                  this.setState({page}, (_) => {
                    this.getData();
                  });
                },
              }}
            />
          </Spin>
        </div>
        <Modal
          title={this.content(courierSn)}
          visible={isShowInfo}
          centered
          width="660px"
          onCancel={() => this.setState({ isShowInfo: false, courierInfo: [] })}
          footer={null}
          destroyOnClose
        >
          <div>
            {courierInfo.length > 0 ? (
              <div style={{ height: 600, overflowY: "scroll" }}>
                {courierInfo.map((item, idx) => (
                  <div
                    key={idx}
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      minHeight: 90,
                    }}
                  >
                    <div className="courier_line"></div>
                    <div>
                      <div style={{ color: "#000000" }}>{item.status}</div>
                      <div>{item.time}</div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div style={{ textAlign: "center", fontSize: 16 }}>
                暂无物流信息，请稍后查询
              </div>
            )}
            <div style={{ marginTop: 20, textAlign: "right" }}>
              <Button
                type="primary"
                onClick={() =>
                  this.setState({ isShowInfo: false, courierInfo: [] })
                }
              >
                关闭
              </Button>
            </div>
          </div>
        </Modal>
        <Modal
          title="批量查询"
          visible={isBatchSearch}
          centered
          width="450px"
          onCancel={() => this.setState({ isBatchSearch: false, searchSns: '',searchType: "parcel_sn" })}
          footer={null}
          destroyOnClose>
          <div>
            <div>
              <Radio.Group
                value={searchType}
                onChange={(e) => this.setState({searchType: e.target.value})}>
                <Radio value="parcel_sn">平台编号</Radio>
                <Radio value="courier_sn">物流单号</Radio>
              </Radio.Group>
              <TextArea
                rows={5}
                placeholder={searchType === "parcel_sn" ? "请输入平台编号，多个请换行输入，如：\n11111111\n22222222\n33333333" : "请输入物流单号，多个请换行输入，如：\n11111111\n22222222\n33333333"}
                style={{ marginTop: 12 }}
                onChange={(e) =>
                  this.setState({ searchSns: e.target.value })
                }/>
            </div>
            <div style={{ marginTop: 20, textAlign: "center" }}>
              <Button
                style={{ marginRight: 60 }}
                onClick={()=> {this.setState({ isBatchSearch: false, searchSns: '',searchType: "parcel_sn" })}}>
                取消
              </Button>
              <Button
                type="primary"
                onClick={this.handleSubmit}>
                确定
              </Button>
            </div>
          </div>
        </Modal>
        <Modal
          visible={isShowVideo}
          footer={null}
          width="1100px"
          title={this.title()}
          onCancel={(_) => this.setState({ isShowVideo: false })}
          className="modal_video">
          <div className="video_wrapper">
            <video src="https://dfcdn.shuidilipin.com/source/daifa/video/fhzscj.mp4" autoPlay muted controls="controls" width="1050" height="600"/>
          </div>
        </Modal>
      </div>
    );
  }
}
export default ParcelList;
