/* eslint-disable react/jsx-no-target-blank */
/*
 * 首页
 */
import React from "react";
import { Link } from "react-router-dom";
import { message, Modal, Popover, Tooltip } from "antd";
import {
  API,
  history,
  prefix,
  imgRoot,
  IconFont,
} from "../../static/js/common";

import {
  ShoppingCartOutlined,
} from '@ant-design/icons';

import { LoginContext } from "../../App";

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLogin: false, // 是否登录
      isShow: false,
      isShowBuy: "", // 购买按钮
      newList: [],
      hotList: [],
      isFixed: false,
    };
  }

  componentDidMount() {
    //第一次进入网页弹窗
    let isShow = false;
    if (!window.localStorage.getItem("LP-ISFIRST")) {
      isShow = true;
      window.localStorage.setItem("LP-ISFIRST", 1);
    }
    this.setState({ isShow });
    API.getAuthInfo({}).then((res) => {
      if (res.status * 1 === 0) {
        this.setState({ isLogin: true, phone: res.extData.mobile });
        // window.localStorage.setItem("slpToken", res.extData.token);
        // setCookie("slpToken", res.extData.token, ".shuidilipin.com");
      }
    });
    this.getGoodsList();
  }

  getGoodsList = () => {
    API.postGoodsList2({}).then((res) => {
      if (res.status * 1 === 0) {
        const data = res.extData || {};
        const newList = data.newList || [];
        const hotList = data.hotList || [];
        this.setState({
          newList,
          hotList,
        });
      } else {
        message.error(res.message || "数据加载错误");
      }
    });
  };

  handleChangeTab = (url) => {
    if (this.state.isLogin) {
      history.push(url);
    } else {
      history.push("/login?tabKey=login");
    }
  };

  handleScroll = () => {
    /*const { isFixed } = this.state;
    const top =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop;
    if (isFixed && top > 0) {
      this.setState({ isFixed: false });
    } else if (!isFixed && top === 0) {
      this.setState({ isFixed: true });
    }*/
  };

  handleLogout = () => {
    API.getLogin({}).then((res) => {
      if (res.status * 1 === 0) {
        this.setState({ isLogin: false });
        history.push("/");
      }
    });
  };
  

  renderItem = (item) => (
    <div
      key={item.goodsId}
      className="home_goods"
      style={{
        width: 222,
        margin: "6px 10px",
        border: "1px solid #f5f5f5",
      }}
      onMouseEnter={(_) => this.setState({ isShowBuy: item.goodsId })}
      onMouseLeave={(_) => this.setState({ isShowBuy: "" })}
      onClick={(_) =>
        this.handleChangeTab(`/seller/goods/goodsinfo?${item.goodsId}`)
      }
    >
      <div className="home_goods_img">
        <img src={item.goodsImg} alt="" />
      </div>
      <div className="home_goods_info flex-column-between">
        <div className="home_goods_title">{item.goodsName}</div>
        <div className="flex-space">
          <div className="flex-start">
            <div
              style={{
                fontSize: 20,
                fontWeight: 600,
                color: "#ff3333",
                marginRight: 10,
              }}
            >
              ￥{(item.goodsFee / 100).toFixed(2)}
            </div>
            <div className="home_goods_baoyou">包邮</div>
          </div>
          <div style={{ fontSize: 12, color: "#666666" }}>
            {item.weight}g/件
          </div>
        </div>
        <div className="flex-space">
          <div className="home_goods_local">{item.address}</div>
          <div className="home_goods_local">{item.courier}</div>
        </div>
      </div>
      <div
        className="home_goods_hover"
        style={{
          display: this.state.isShowBuy === item.goodsId ? "block" : "none",
          // display: "block",
        }}
      >
        {/*  <div className="flex-start home_goods_hover_info">
          <div className="home_goods_hover_title">发货详情</div>
          <div className="home_goods_hover_line"></div>
          <div
            className="home_goods_hover_detail flex-column-between"
            style={{ height: 118 }}
          >
            <div>出单截止时间：23:59:59</div>
            <div>发货截止时间：23:59</div>
            <div>仓储：义乌</div>
            <div>快递：韵达</div>
            <div>不发：新疆、西藏</div>
          </div>
        </div>
        <div className="flex-start home_goods_hover_info">
          <div className="home_goods_hover_title">物流时效</div>
          <div className="home_goods_hover_line"></div>
          <div
            className="home_goods_hover_detail flex-column-between"
            style={{ height: 118 }}
          >
            <div>
              平均出单时间：8分钟
              <Tooltip
                placement="top"
                title="平均出单时间：近三天，在9:00至18:00时间段，下单到出单号的平均时长=【（A包裹下单时间-A包裹出单时间）+（B包裹下单时间-B包裹出单时间）+（C包裹下单时间-C包裹出单时间）】/ (A+B+C)单量"
              >
                <IconFont
                  type="icon-bangzhu"
                  style={{
                    fontSize: 14,
                    color: "#333333",
                    marginLeft: 2,
                  }}
                />
              </Tooltip>
            </div>
            <div>
              平均揽收时间：6小时
              <Tooltip
                placement="top"
                title="平均揽收时间：近三天，在9:00至18:00时间段，下单到出单号的平均时长=【（A包裹下单时间-A包裹揽收时间）+（B包裹下单时间-B包裹揽收时间）+（C包裹下单时间-C包裹揽收时间）】/ (A+B+C)单量"
              >
                <IconFont
                  type="icon-bangzhu"
                  style={{
                    fontSize: 14,
                    color: "#333333",
                    marginLeft: 2,
                  }}
                />
              </Tooltip>
            </div>
            <div>
              24小时揽收率：70%
              <Tooltip
                placement="top"
                title="24小时揽收率：近三天，下单后，在24h内完成揽收的比例=【（包裹出单时间-包裹揽收时间）<24h】的单量/总单量"
              >
                <IconFont
                  type="icon-bangzhu"
                  style={{
                    fontSize: 14,
                    color: "#333333",
                    marginLeft: 2,
                  }}
                />
              </Tooltip>
            </div>
            <div>
              中转超时率：5%
              <Tooltip
                placement="top"
                title="中转超时率：近三天，物流中转停留超过48小时的比例= 【已揽收包裹（物流信息最后一次更新时间 - 当下时间）>48h 的单量】/已揽收包裹总单量 "
              >
                <IconFont
                  type="icon-bangzhu"
                  style={{
                    fontSize: 14,
                    color: "#333333",
                    marginLeft: 2,
                  }}
                />
              </Tooltip>
            </div>
          </div>
        </div>
        <div className="home_goods_title">{item.goodsName}</div>
        <div className="flex-space">
          <div className="flex-start">
            <div
              style={{
                fontSize: 20,
                fontWeight: 600,
                color: "#ff3333",
                marginRight: 10,
              }}
            >
              ￥{(item.goodsFee / 100).toFixed(2)}
            </div>
            <div className="home_goods_baoyou">包邮</div>
          </div>
          <div style={{ fontSize: 12, color: "#666666" }}>
            {item.weight}g/件
          </div>
        </div> */}
        <div className="home_goods_buy">立即购买</div>
      </div>
    </div>
  );

  content = (url) => {
    return (
      <div>
        <img src={`${imgRoot}/${url}`} alt="" width="100px" />
        <div style={{ textAlign: "center", marginTop: 6 }}>扫码联系客服</div>
      </div>
    );
  };

  render() {
    const { isShow, newList, hotList, isFixed, isLogin, phone } = this.state;
    return (
      <div
        style={{ background: "#f5f5f5" }}
        onWheel={(e) => this.handleScroll(e)}
      >
        {/*<div
          className="home_new_header"
          style={{
            background: isFixed ? "transparent" : "#ffffff",
            color: isFixed ? "#ffffff" : "#333333",
          }}
        >
          <div className="home_new_tab">
            <img
              src={`${imgRoot}/${isFixed ? "shuidi_logo.svg" : "shuidi_logo.svg"}`}
              alt=""
            />
            <div className="flex-start">
              <Link to="/?tabKey=index">
                <div
                  className="home_new_tab_item"
                  style={{
                    color: isFixed ? "#ffffff" : "#ff3333",
                    borderBottom: `2px solid ${isFixed ? "#ffffff" : "#ff3333"}`,
                  }}
                >
                  首页
                </div>
              </Link>
              <Link to="/all?tabKey=all">
                <div
                  style={{ color: isFixed ? "#ffffff" : "#333333" }}
                  className="home_new_tab_item"
                >
                  所有商品
                </div>
              </Link>
              <div
                onClick={(_) => this.handleChangeTab("/seller/account/home")}
                className="home_new_tab_item"
              >
                个人中心
              </div>
              <Popover
                placement="bottom"
                content={() => this.content("kfqw.png")}
                trigger="hover"
              >
                <div className="home_new_tab_item">联系客服</div>
              </Popover>
              <Popover
                placement="bottom"
                content={() => this.content("kfqw.png")}
                trigger="hover"
              >
                <div
                  className="home_new_tab_item"
                  style={{ marginRight: isLogin ? 120 : 250 }}
                >
                  供货商入驻
                </div>
              </Popover>
              {isLogin ? (
                <div
                  style={{
                    color: isFixed ? "#ffffff" : "#333333",
                    marginRight: 0,
                  }}
                  className="home_new_tab_item"
                >
                  欢迎您：{phone}
                  <span style={{ marginLeft: 10 }} onClick={this.handleLogout}>
                    退出
                  </span>
                </div>
              ) : (
                <div
                  style={{
                    color: isFixed ? "#ffffff" : "#333333",
                    marginRight: 0,
                  }}
                  className="home_new_tab_item"
                  onClick={e => console.log(LoginContext)}
                >
                  登录/注册
                </div>
              )}
            </div>
          </div>
        </div>*/}
        <div className="home-header">
          <div className="home-header_wrapper">
            <div
              className="home-header_left"
            >
              <img
                src={`${imgRoot}/banner1.png`}
                alt=""
                style={{ display: "block" }}
              />
              <div
                className="home-header_btn"
                onClick={(_) =>
                  window.location.href="/#/all?tabKey=all"
                }
              >
                <ShoppingCartOutlined />
                <span style={{ marginLeft: '2px' }}>购买商品</span>
              </div>
            </div>
            <div className="home-header_right">
              <div className="home-unLogin">
                <div className="home-unLogin_header">
                  <div className="home-login-wrapper">
                    <div className="head-portrait-wrapper">
                      <div className="head-portrait head-portrait_s"></div>
                      <div className="head-portrait head-portrait_l"></div>
                    </div>
                    {
                      !isLogin ? (
                        <div> 请先 <Link to="/login?tabKey=login"> <span className="login-btn">登录</span> </Link> 哦~ </div>
                      ) : (
                        <div>{phone}</div>
                      )
                    }
                  </div>
                </div>
                <div className="home-order-data">
                  <div className="order-data-item">
                    <div className="num">-</div>
                    <div className="label">待付款</div>
                  </div>
                  <div className="el-divider el-divider--vertical"></div>
                  <div className="order-data-item">
                    <div className="num">-</div>
                    <div className="label">待发货</div>
                  </div>
                  <div className="el-divider el-divider--vertical"></div>
                  <div className="order-data-item">
                    <div className="num">-</div>
                    <div className="label">待收货</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*<img
          src={`${imgRoot}/banner1.png`}
          alt=""
          style={{ display: "block", width: "100%" }}
        />*/}
        <div className="home-advantage-wrapper">
          <div className="home-advantage-item">
            <div className="home-advantage-icon-wrapper">
				<img src={`${imgRoot}/bcent/cspf.png`} />
			</div>
            <div className="home-advantage-text-wrapper">
              <p>超时赔付</p>
              <p><span>出单</span><span className="dat-shu">|</span><span>揽收超时都赔</span></p>
            </div>
          </div>
          <div className="vertical-divider"></div>
          <div className="home-advantage-item">
            <div className="home-advantage-icon-wrapper">
				<img src={`${imgRoot}/bcent/hlsj.png`} />
			</div>
            <div className="home-advantage-text-wrapper">
              <p>海量商家</p>
              <p><span>你想要的我们都有</span></p>
            </div>
          </div>
          <div className="vertical-divider"></div>
          <div className="home-advantage-item">
            <div className="home-advantage-icon-wrapper">
				<img src={`${imgRoot}/bcent/yjqf.png`} />
			</div>
            <div className="home-advantage-text-wrapper">
              <p>一件起发</p>
              <p><span>任意商家皆适用</span></p>
            </div>
          </div>
          <div className="vertical-divider"></div>
          <div className="home-advantage-item">
            <div className="home-advantage-icon-wrapper">
				<img src={`${imgRoot}/bcent/yzfw.png`} />
			</div>
            <div className="home-advantage-text-wrapper">
              <p>优质服务</p>
              <p><span>尽力提供最好的服务</span></p>
            </div>
          </div>
          <div className="vertical-divider"></div>
          <div className="home-advantage-item">
            <div className="home-advantage-icon-wrapper">
				<img src={`${imgRoot}/bcent/tjyl.png`} />
			</div>
            <div className="home-advantage-text-wrapper">
              <p>推荐有礼</p>
              <p><span>推荐好友还能赚钱哦</span></p>
            </div>
          </div>
        </div>
        <div className="home_new_hot flex-center">
          <img src={`${imgRoot}/hot.png`} alt="" />
          <div className="home_new_hot_box">
            {hotList.map((item) => this.renderItem(item))}
          </div>
        </div>
        <div className="home_new_hot flex-center">
          <img src={`${imgRoot}/new.png`} alt="" />
          <div className="home_new_hot_box" style={{ height: 380 }}>
            {newList.map((item) => this.renderItem(item))}
          </div>
        </div>
        {/*<div className="common_footer">
          <p>
            <a
              href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010602010964"
              target="_blank"
            >
              浙公网安备33010602010964号&nbsp;
              {prefix ? "浙ICP备17025649号-7" : "浙ICP备2024095682号-1"}
            </a>
            <br />
            <a href="http://www.beian.miit.gov.cn" target="_blank">
              增值电信业务经营许可证：
              {prefix ? "浙B2-20230964" : "浙B2-20230964"}
            </a>
          </p>
        </div>*/}
		{/*
        <Modal
          visible={isShow}
          footer={null}
          onCancel={(_) => this.setState({ isShow: false })}
          className="modal_first"
        >
          <div className="modal_code">
            <img src={`${imgRoot}/kfqw.png`} alt="" />
          </div>
          <div>扫码添加管理员</div>
          <div>礼品问题24小时在线回复</div>
        </Modal>*/}
      </div>
    );
  }
}
export default Home;
