/* 帮助中心-公告详情 */
import React, { Component } from "react";
import { message } from "antd";
import { API } from "../../../static/js/common";

class NoticeInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      articleId: this.props.location.search.slice(1),
      article_data: {},
    };
  }

  componentDidMount() {
    this.getArticleInfo();
  }

  getArticleInfo = () => {
    const reqData = { articleId: this.state.articleId };
    API.getArticleInfo(reqData).then((res) => {
      if (res.status * 1 === 0) {
        this.setState({ article_data: res.extData || {} });
      } else {
        message.error(res.message || "数据加载错误");
      }
    });
  };

  render() {
    const { article_data } = this.state;
    return (
      <div className="notice_wrapper">
        <div className="recharge">系统公告</div>
        <div style={{ fontSize: 16, fontWeight: 600, textAlign: "center" }}>
          {article_data.title}
        </div>
        <div
          style={{
            margin: "20px 0 30px",
          }}
          dangerouslySetInnerHTML={{ __html: article_data.content }}
        />
        <div style={{ textAlign: "right" }}>{article_data.publishTime}</div>
      </div>
    );
  }
}
export default NoticeInfo;
