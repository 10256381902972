/* 售后服务-售后查件 */
import React, { Component, Fragment } from "react";
import {
  Tabs,
  Button,
  message,
  Modal,
  Input,
  Radio,
  Alert,
  Upload,
  Table,
  Carousel,
  Tooltip,
  DatePicker
} from "antd";
import {API, IconFont, ROOT} from "../../../static/js/common";
import {
  PlusOutlined,
  QuestionCircleOutlined,
  DeleteOutlined,
  CopyOutlined,
  LeftOutlined,
  RightOutlined
} from '@ant-design/icons';
import TextCount from './TextareaCount';
import {CopyToClipboard} from "react-copy-to-clipboard";
import moment from "moment";
const { TabPane } = Tabs;
const { TextArea } = Input;
const { RangePicker } = DatePicker;

// 文件格式大小验证
function beforeUpload(file) {
  /*   const isFile = /csv|excel|sheet/g.test(file.type);
  if (!isFile) {
    message.error("仅支持CSV、XLSX、XLS格式的文件！");
  } */
  const isLt1M = file.size / 1024 / 1024 < 10;
  if (!isLt1M) {
    message.error("上传的文件不能大于10MB!");
  }
  return isLt1M;
}

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      pageSize: 10,
      activeKey: "1",
      fkData: "",
      withdraw_data: {},
      detail_data: {},
      detail_page: 1,
      type: 1,
      qaType: 1,
      showNumber: false,
      showNumberTitle: "",
      up_loading: false,
      question_desc: "",
      file_url: "",
      lintNumbers: [],
      previewVisible: false,
      previewImage: "",
      fileList: [],
      expressList: [],
      showResult: false,
      showMsg: false,
      content: "",
      msgList: [],
      sendId: "",
      resultSsid: "",
      qaSearchType: 0,
      status: 0,
      createStart: "",
      createEnd: "",

      dlStatus: -1,
      parcelSn: "",
      courierSn: "",
      createTimeStart: "",
      createTimeEnd: "",
      courierInfo: [],
      isShowInfo: false,
      isShowOrderResult: false,
      replay: ""
    };
  }

  componentDidMount() {
  }

  getServiceList = () => {
    const { page, pageSize, qaSearchType, status, createStart, createEnd  } = this.state;
    const reqData = { page, pageSize, qaType: qaSearchType, status };
    if(createStart){
      reqData.createTimeStart = createStart;
    }
    if(createEnd){
      reqData.createTimeEnd = createEnd;
    }
    API.getServiceList(reqData).then((res) => {
      if (res.status * 1 === 0) {
        this.setState({ withdraw_data: res.extData || {} });
      } else {
        message.error(res.message || "数据加载错误");
      }
    });
  };

  // 选择日期
  changeTime = (e) => {
    let { createStart, createEnd } = this.state;
    if (e) {
      createStart = moment(e[0]).format("YYYY-MM-DD HH:mm");
      createEnd = moment(e[1]).format("YYYY-MM-DD HH:mm");
    } else {
      createStart = "";
      createEnd = "";
    }
    this.setState({ createStart, createEnd },(_) => this.getServiceList());
  };

  // 选择日期
  changeDetailTime = (e) => {
    let { createTimeStart, createTimeEnd, resultSsid } = this.state;
    if (e) {
      createTimeStart = moment(e[0]).format("YYYY-MM-DD HH:mm");
      createTimeEnd = moment(e[1]).format("YYYY-MM-DD HH:mm");
    } else {
      createTimeStart = "";
      createTimeEnd = "";
    }
    this.setState({ createTimeStart, createTimeEnd },(_) => this.getServiceDetailList(resultSsid));
  };


  showDealResult = (info) => {
    if(info.fkStatus === 3){
      this.setState({isShowOrderResult: true, replay: info.replay})
    }else{
      this.getServiceDetailList(info.id)
    }
  };

  getServiceDetailList = (id) => {
    const { detail_page, pageSize, dlStatus, parcelSn, courierSn, createTimeStart, createTimeEnd } = this.state;
    const reqData = { page: detail_page, pageSize, ssId: id ,dlStatus};
    if(parcelSn){
      reqData.parcelSn = parcelSn;
    }
    if(courierSn){
      reqData.courierSn = courierSn;
    }
    if(createTimeStart){
      reqData.createTimeStart = createTimeStart;
    }
    if(createTimeEnd){
      reqData.createTimeEnd = createTimeEnd;
    }
    API.getServiceDetailList(reqData).then((res) => {
      if (res.status * 1 === 0) {
        this.setState({ detail_data: res.extData || {}, showResult: true, resultSsid: id });
      } else {
        message.error(res.message || "数据加载错误");
      }
    });
  };

  handleSubmit = () => {
    const { qaType, question_desc, type, fileList, fkData} = this.state;
    const self = this;
    if (!fkData) {
      message.error("请输入反馈单号");
      return;
    }
    if(fkData.split('\n').map(item => item.trim()).filter(item => item).length !== fkData.split('\n').length){
      message.error("反馈单号不允许有空行");
      return;
    }
    if (!question_desc) {
      message.error("请输入问题描述");
      return;
    }
    const reqData = {
      fkStatus: type,
      fkData: fkData.split('\n').map(item => item.trim()).filter(item => item).join(','),
      qaType,
      fkContent: question_desc,
      fkAttach: fileList.length ? fileList.map(item => item.url).join(',') : "",
    };
    this.onBlurPromise(fkData).then(()=>{
      API.postSaveServiceRequest(reqData).then((res) => {
        if (res.status * 1 === 0) {
          message.success("提交成功");
          self.setState({
            type: 1,
            fkData: "",
            qaType: 1,
            question_desc: "",
            fileList: []
          });
          self.childRef.clearText();
        } else {
          message.error(res.message || "提交失败！");
        }
      });
    },()=>{
      message.error( "请检查反馈单号！");
    })
  };

  // 上传文件
  handleChange = ({ fileList }) => {
    const file = fileList.map(iterator => {
      if (iterator.status === 'done' && iterator.response.extData) {
        iterator.url = iterator.response.extData.fileUrl;
      }
      return iterator;
    });
    this.setState({fileList: file})
  };

  handleChangeTab = (key) => {
    this.setState({activeKey: key});
    if(parseInt(key) === 2){
      this.getServiceList();
    }
  };

  handlePreview = file => {
    this.setState({previewImage: ''});
    if (!file.url && !file.preview) {
      file.preview = getBase64(file.originFileObj);
    }
    this.setState({
      previewImage: [file.url || file.response.extData.fileUrl || file.preview],
      previewVisible: true,
    });
  };

  handleShowSn = (sn,title) => {
    if(sn){
      this.setState({showNumber: true, expressList: sn.split(','), showNumberTitle: title});
    }
  };

  onTextChange = (val) => {
    this.setState({fkData: val});
  };

  onRadioChange = (value) => {
    this.setState({type: value}, ()=>{
      const {fkData} = this.state;
      if(fkData){
        this.onTextBlur(fkData)
      }
    });
  };

  onTextBlur = (val) => {
    this.onBlurPromise(val).then(()=>{},()=>{})
  };

  onBlurPromise = (val) => {
    return new Promise((resolve, reject) => {
      if (!val) {
        this.setState({lintNumbers: []});
        message.error("请填写单号");
        reject();
        return;
      }
      const numbers = val.split('\n').map(item => item.trim()).filter(item => item);
      if (numbers.length !== val.split('\n').length) {
        this.setState({lintNumbers: []});
        message.error("反馈单号不允许有空行");
        reject();
        return;
      }
      const {type} = this.state;
      API.getCheckNumbers({
        fkStatus: type,
        fkData: numbers.join(',')
      }).then((res) => {
        if (res.status * 1 === 0) {
          const checkList = res.extData.check;
          if (checkList && checkList.length) {
            let lintNumber = [];
            checkList.forEach((item, index) => {
              for (const number in item) {
                if (!item[number]) {
                  lintNumber.push(`第${index + 1}行单号不存在,请检查`)
                }
              }
            });
            this.setState({lintNumbers: lintNumber}, () => {
              if (lintNumber.length) {
                reject()
              } else {
                resolve()
              }
            })
          }
        }
      })
    });
  };

  handleChildEvent = (ref) => {
    this.childRef = ref;
  };

  getMsgList = (info) => {
    const reqData = { page: 1, pageSize: 99999, ssnoId: info.id};
    API.getMessageList(reqData).then((res) => {
      if (res.status * 1 === 0) {
        this.setState({ msgList: res.extData.list || {}, showMsg: true, sendId: info.id });
        this.handleTagMsg(info)
      } else {
        message.error(res.message || "数据加载错误");
      }
    });
  };

  handleSend = () => {
    const {sendId, content} = this.state;
    if(!content){
      message.error("请输入留言内容");
      return;
    }
    const reqData = {
      ssnoId: sendId,
      content: content
    };
    API.sendMessage(reqData).then(res => {
      if (res.status * 1 === 0) {
        message.success("发送成功");
        this.setState({ showMsg: false, content: "" });
      } else {
        message.error(res.message || "数据加载错误");
      }
    })
  };

  handleDelMsg = (info) => {
    API.getDelMessage({mid: info.id}).then(res => {
      if (res.status * 1 === 0) {
        message.success("删除成功");
        this.setState({ showMsg: false });
      } else {
        message.error(res.message || "数据加载错误");
      }
    })
  };

  handleTagMsg = (info) => {
    API.getTagMessage({ssnoId: info.id}).then(res => {
      if (res.status * 1 === 0) {

      } else {
        message.error(res.message || "数据加载错误");
      }
    })
  };

  handleSearchCourierInfo = (kno, parcelId) => {
    let k_no = kno;
    if(kno.indexOf('SF')>-1){
      k_no = k_no + ':6086';
    }
    API.getSearchCourierInfo({
      kno: k_no,
      parcelId,
    }).then((res) => {
      if (res.status * 1 === 0) {
        const data = res.extData || {};
        const courierInfo = data.wuliu ? JSON.parse(data.wuliu) : [];
        this.setState({ courierInfo, isShowInfo: true, courierSn: kno });
      } else {
        message.error(res.message || "物流信息加载错误");
      }
    });
  };

  content = (sn) => {
    return (
      <span>
        物流信息（{sn}
        <CopyToClipboard
          text={sn}
          onCopy={() =>
            message.success(`复制成功`)
          }>
         <CopyOutlined style={{marginLeft: 3, color: '#999', fontSize: 14, cursor: "pointer"}}/>
        </CopyToClipboard>
        ）
      </span>
    );
  };

  render() {
    const {
      withdraw_data,
      page,
      pageSize,
      question_desc,
      type,
      showNumber,
      activeKey,
      previewVisible,
      previewImage,
      fileList,
      expressList,
      showResult,
      lintNumbers,
      qaType,
      showNumberTitle,
      detail_page,
      detail_data,
      showMsg,
      content,
      msgList,
      resultSsid,
      qaSearchType,
      status,
      dlStatus,
      parcelSn,
      courierSn,
      isShowInfo,
      courierInfo,
      isShowOrderResult,
      replay
    } = this.state;

    const columns = [
      {
        title: "编号",
        dataIndex: "id",
        align: "center",
      },
      {
        title: "反馈类型",
        dataIndex: "fkStatus",
        align: "center",
        render: (e) => {
          return <div>{["", "快递单号", "平台编号", "订单批次号"][e]}</div>;
        },
      },
      {
        title: "问题类型",
        dataIndex: "qaType",
        align: "center",
        render: (e) => {
          return <div>{["", "催中转", "要底单", "代签收", "其它"][e]}</div>;
        },
      },
      {
        title: "问题描述",
        dataIndex: "fkContent",
        align: "center",
        width: 180,
        render: (e) => {
          return <div>{e}</div>;
        },
      },
      {
        title: "申请状态",
        dataIndex: "status",
        align: "center",
        render: (e) => {
          return <div style={{color: e === 1 ? '#f94a3d' : '#4BC600'}}>{["", "待审核", "已审核"][e]}</div>;
        },
      },
      {
        title: "图片",
        dataIndex: "fkAttach",
        align: "center",
        render: (e) => {
          return <div>
            {e ? (
              <div style={{position: "relative",width: '60px', height: '60px',margin: 'auto'}}>
                <img src={e.split(',')[0]}
                     style={{width: '100%', height: '100%', cursor: 'pointer', objectFit: 'cover'}}
                     onClick={() => {
                       this.setState({
                         previewImage: e.split(','),
                         previewVisible: true,
                       });
                     }}/>
                <div className="photo_num">{e.split(',').length}</div>
              </div>
              ) : ""}
          </div>;
        },
      },
      {
        title: "申请时间",
        dataIndex: "createTime",
        align: "center",
      },
      {
        title: "审核时间",
        dataIndex: "dealTime",
        align: "center",
      },
      {
        title: "操作",
        dataIndex: "operate",
        align: "center",
        render: (e,record) => (
          <Fragment>
            <div>
              <div style={{color: '#9A9A9A', fontSize: 13, cursor: 'pointer'}} onClick={() => this.handleShowSn(record.fkData, ["", "快递单号", "平台编号", "订单批次号"][record.fkStatus])}>查看单号</div>
              {record.status === 2 ? (
                <div style={{color: '#9A9A9A', fontSize: 13, cursor: 'pointer'}} onClick={() => this.showDealResult(record)}>查看处理结果</div>
              ) : ""}
            </div>
          </Fragment>
        )
      },
    ];

    const columns2 = [
      {
        title: "快递单号",
        dataIndex: "courierSn",
        align: "center",
        render: (e, record) => (
          <Fragment>
            {e ? (
              <div
                className="flex-start"
                style={{
                  wordBreak: "break-all",
                  wordWrap: "break-word",
                  cursor: "pointer",
                }}>
                <CopyToClipboard
                  text={e}
                  onCopy={() => message.success("成功复制物流单号")}>
                  <span>{e}</span>
                </CopyToClipboard>
                <IconFont
                  type="icon-search"
                  style={{ fontSize: 16, marginLeft: 4, cursor: "pointer" }}
                  onClick={() =>
                    this.handleSearchCourierInfo(e, record.parcelId)
                  }
                />
              </div>
            ) : null}
          </Fragment>
        ),
      },
      {
        title: "平台编号",
        dataIndex: "parcelSn",
        align: "center",
        render: (e) => {
          return <CopyToClipboard
            text={e}
            onCopy={() =>
              message.success(`复制成功`)
            }>
            <div style={{cursor: 'pointer'}}>{e}</div>
          </CopyToClipboard>
        }
      },
      {
        title: "订单id",
        dataIndex: "orderId",
        align: "center",
      },
      {
        title: () => (
          <span>
            {'处理结果'}
            <Tooltip title={'如有疑问，请联系右下角客服'}>
              <QuestionCircleOutlined style={{marginLeft: 4}}/>
            </Tooltip>
          </span>
        ),
        dataIndex: "dlContent",
        align: "center",
        render: (e, record) => {
          return <div style={{color: 'red'}}>
            {record.dlStatus ? (
              <div>
                {record.dlType === 3 ? (
                    <div style={{textAlign: 'left'}}>
                      处理结果： 退还款项{(record.totalFee / 100).toFixed(2)}元
                    </div>
                  ) : (
                    <div style={{textAlign: 'left'}}>处理结果：{["", "已补发", "已催快递", "已退款", "已取消", "其他"][record.dlType]}</div>
                  )}
              <div style={{textAlign: 'left', width: '150px'}}>
                说明：{e}
              </div>
              </div>
            ) : ""}
          </div>;
        },
      },
      {
        title: "处理状态",
        dataIndex: "dlStatus",
        align: "center",
        render: (e) => {
          return <div style={{color: e === 0 ? '#f94a3d' : '#4BC600'}}>{["待处理", "已处理", "处理中"][e]}</div>;
        },
      },
      {
        title: "处理图片",
        dataIndex: "dlAttach",
        render: (e) => {
          return <div>
            {e ? (
              <div style={{position: "relative",width: '60px', height: '60px',margin: 'auto'}}>
                <img src={e.split(',')[0]}
                     style={{width: '100%', height: '100%', cursor: 'pointer', objectFit: 'cover'}}
                     onClick={() => {
                       this.setState({
                         previewImage: e.split(','),
                         previewVisible: true,
                       });
                     }}/>
                <div className="photo_num">{e.split(',').length}</div>
              </div>
            ) : ""}
          </div>;
        },
      },
      {
        title: "处理时间",
        dataIndex: "dealTime",
        align: "center",
      },
      {
        title: "操作",
        dataIndex: "operation",
        align: "center",
        render: (e,record) => (
          <Fragment>
            <div>
              <div style={{color: '#f94a3d', fontSize: 13, cursor: 'pointer'}} onClick={() => this.getMsgList(record)}>
                留言
                <span style={{color: 'red', fontSize: '12px', marginRight: '3px', lineHeight: '22px'}}>
                  {parseInt(record.hasMsg) ? '(未读)' : ''}
                </span>
              </div>
            </div>
          </Fragment>
        )
      }
    ];

    const uploadButton = (
      <div>
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>上传图片</div>
      </div>
    );

    return (
      <div>
        <div className="service_title">售后查件</div>
        <div className="service_tip">
          <Alert message="如果包裹出现破损、快递丢件、漏发、错发等情况，您可以申请售后，我们核实情况后会进行补发或者退还部分款项。" type="info" showIcon />
        </div>
        <div className="service_tab">
          <Tabs activeKey={activeKey} onChange={(e)=>this.handleChangeTab(e)}>
            <TabPane tab="售后申请" key="1">
            </TabPane>
            <TabPane tab="申请记录" key="2">
            </TabPane>
          </Tabs>
        </div>
        {activeKey == 1 && (
          <div className="search_form">
            <div className="flex-start">
              <label className="label"><i style={{color: 'red'}}>*</i>反馈类型：</label>
              <Radio.Group
                value={type}
                onChange={(e) => this.onRadioChange(e.target.value)}>
                <Radio value={1}>快递单号</Radio>
                <Radio value={2}>平台编号</Radio>
                <Radio value={3}>订单批次号</Radio>
              </Radio.Group>
            </div>
            <div className="flex-start top_margin">
              <label className="label"><i style={{color: 'red'}}>*</i>反馈单号：</label>
              <div style={{width: '90%', display: 'flex'}}>
                <TextCount
                  type={this.state.type}
                  onTextAreaBlur={this.onTextBlur.bind(this)}
                  onTextAreaChange={this.onTextChange.bind(this)}
                  onChildEvent={this.handleChildEvent}/>
                {!!lintNumbers.length && (<div className="number_test">
                  {lintNumbers.map((item,idx) => (
                    <p key={idx}>{item}</p>
                  ))}
                </div>)}
              </div>
            </div>
            <div className="flex-start top_margin">
              <label className="label"><i style={{color: 'red'}}>*</i>问题类型：</label>
              <Radio.Group
                value={qaType}
                onChange={(e) => this.setState({qaType: e.target.value})}>
                <Radio value={1}>催中转</Radio>
                <Radio value={2}>要底单</Radio>
                <Radio value={3}>代签收</Radio>
                <Radio value={4}>其它</Radio>
              </Radio.Group>
            </div>
            <div className="flex-start top_margin">
              <label className="label"><i style={{color: 'red'}}>*</i>问题描述：</label>
              <TextArea
                className="input_desc"
                value={question_desc}
                max={200}
                onChange={(e) => this.setState({question_desc: e.target.value})}
                autoSize={{minRows: 4}}
                placeholder="请输入问题描述"/>
            </div>
            <div className="flex-start top_margin">
              <label className="label">添加图片：</label>
              <div style={{width: '80%'}}>
                <Upload
                  listType="picture-card"
                  className="avatar-uploader"
                  fileList={fileList}
                  beforeUpload={beforeUpload}
                  action={`${ROOT}/upload/filecos`}
                  onChange={this.handleChange}
                  onPreview={this.handlePreview}
                >
                  {fileList.length > 2 ? '' : uploadButton}
                </Upload>
                <div className="upload_tip">
                  <span style={{color: "#999"}}>提示：</span>如包裹破损一定要上传收货实物图，上传图片支持jpg,png，大小不超过5M
                </div>
              </div>
            </div>
          </div>
        )}

        {activeKey == 2 && (
          <div className="service_list">
            <div className="flex-space" style={{ marginBottom: 20 }}>
              <Radio.Group
                value={qaSearchType}
                buttonStyle="solid"
                onChange={(e) =>
                  this.setState({ qaSearchType	: e.target.value, page: 1 }, (_) =>
                    this.getServiceList()
                  )
                }>
                <Radio.Button value={0}>全部</Radio.Button>
                <Radio.Button value={1}>催中转</Radio.Button>
                <Radio.Button value={2}>要底单</Radio.Button>
                <Radio.Button value={3}>代签收</Radio.Button>
                <Radio.Button value={4}>其他</Radio.Button>
              </Radio.Group>
              <Radio.Group
                value={status}
                buttonStyle="solid"
                onChange={(e) =>
                  this.setState({ status	: e.target.value, page: 1 }, (_) =>
                    this.getServiceList()
                  )
                }>
                <Radio.Button value={0}>全部</Radio.Button>
                <Radio.Button value={1}>待审核</Radio.Button>
                <Radio.Button value={2}>已审核</Radio.Button>
              </Radio.Group>
              <div>
                <span style={{ marginRight: 10 }}>创建时间</span>
                <RangePicker onChange={(e) => this.changeTime(e)} showTime={{ format: 'HH:mm' }} format="YYYY-MM-DD HH:mm"/>
              </div>
            </div>
            <Table
              columns={columns}
              dataSource={withdraw_data.list || []}
              rowKey="id"
              pagination={{
                current: page,
                pageSize,
                total: withdraw_data.total * 1 || 0,
                showSizeChanger: false,
                onChange: (page) => {
                  this.setState({page}, (_) => {
                    this.getServiceList();
                  });
                },
              }}
            />
          </div>
        )}

        {activeKey == 1 && (
          <div style={{marginTop: "30px", marginLeft: "80px"}}>
            <Button
              type="primary"
              size="large"
              style={{width: 120}}
              onClick={()=>this.handleSubmit()}>
              提交
            </Button>
          </div>
        )}

        {/* 查看图片 */}
        <Modal
          className="image-wrapper"
          visible={previewVisible}
          title=""
          footer={null}
          onCancel={()=>{this.setState({previewVisible: false})}}
        >
          {previewImage.length > 1 && (
            <div>
              <div
                className="carousel-left"
                onClick={() => {
                  // 通过获取走马灯dom，调用Carousel的prev()方法
                  this.card.prev();
                }}>
                <LeftOutlined/>
              </div>
              <div
                className="carousel-right"
                onClick={() => {
                  // 通过获取走马灯dom，调用Carousel的next()方法
                  this.card.next();
                }}>
                <RightOutlined/>
              </div>
            </div>
          )}
          <Carousel autoplay ref={e => this.card = e}>
            {previewImage.length && previewImage.map((item,idx) => (
              <img key={idx} style={{ width: '100%' }} src={item} />
            ))}
          </Carousel>
        </Modal>

        {/* 查看单号 */}
        <Modal
          title={showNumberTitle}
          visible={showNumber}
          width="600px"
          onCancel={(_) =>
            this.setState({
              showNumber: false,
              verifyCode: "",
            })
          }
          footer={null}>
          <div style={{maxHeight: '300px', overflowY: 'auto'}}>
            <div className="flex-space-colstart" style={{flexWrap: 'wrap'}}>
              {expressList.map((item, idx) => (
                <CopyToClipboard
                  key={idx}
                  text={item}
                  onCopy={() =>
                    message.success(`复制成功`)
                  }>
                  <div
                    style={{
                      width: "49%",
                      border: "1px solid #e6e6e6",
                      padding: "8px 10px",
                      marginBottom: "10px",
                      cursor: "pointer"
                    }}>
                    {item}
                  </div>
                </CopyToClipboard>
              ))}
            </div>
          </div>
        </Modal>

        {/* 查看处理结果 */}
        <Modal
          className="search_result_modal order_table"
          title={"处理结果"}
          visible={showResult}
          width="1100px"
          onCancel={(_) =>
            this.setState({
              showResult: false,
            })
          }
          footer={null}>
          <div style={{maxHeight: '500px', overflowY: 'auto'}}>
            <div className="flex-space" style={{ marginBottom: 20 }}>
              <Radio.Group
                value={dlStatus}
                buttonStyle="solid"
                onChange={(e) =>
                  this.setState({ dlStatus	: e.target.value, page: 1 }, (_) =>
                    this.getServiceDetailList(resultSsid)
                  )
                }>
                <Radio.Button value={-1}>全部</Radio.Button>
                <Radio.Button value={0}>待处理</Radio.Button>
                <Radio.Button value={1}>已处理</Radio.Button>
                <Radio.Button value={2}>处理中</Radio.Button>
              </Radio.Group>
              <div>
                {/*<span style={{marginRight: 10}}>平台编号</span>*/}
                <Input
                  value={parcelSn}
                  placeholder="请输入平台编号"
                  style={{width: 180}}
                  onChange={(e) => this.setState({parcelSn: e.target.value})}
                  onBlur={()=>this.getServiceDetailList(resultSsid)}
                />
              </div>
              <div>
                {/*<span style={{marginRight: 10}}>物流单号</span>*/}
                <Input
                  value={courierSn}
                  placeholder="请输入物流单号"
                  style={{width: 180}}
                  onChange={(e) => this.setState({courierSn: e.target.value})}
                  onBlur={()=>this.getServiceDetailList(resultSsid)}
                />
              </div>
              <div>
                {/*<span style={{ marginRight: 10 }}>创建时间</span>*/}
                <RangePicker onChange={(e) => this.changeDetailTime(e)} showTime={{ format: 'HH:mm' }} format="YYYY-MM-DD HH:mm"/>
              </div>
            </div>
            <Table
              columns={columns2}
              dataSource={detail_data.list || []}
              rowKey="id"
              pagination={{
                current: detail_page,
                pageSize,
                total: detail_data.total * 1 || 0,
                showSizeChanger: false,
                onChange: (page) => {
                  this.setState({detail_page: page}, (_) => {
                    this.getServiceDetailList(resultSsid);
                  });
                },
              }}>
            </Table>
          </div>
        </Modal>

        {/* 留言 */}
        <Modal
          title={"留言"}
          visible={showMsg}
          width="500px"
          onCancel={(_) =>
            this.setState({
              showMsg: false,
            },()=>{this.getServiceDetailList(resultSsid)})
          }
          footer={null}>
          <TextArea
            rows={3}
            value={content}
            placeholder="请输入留言内容"
            onChange={(e) =>
              this.setState({content: e.target.value})
            }/>
          <Button
            type="primary"
            style={{width: 120, margin: '10px 0'}}
            onClick={() => this.handleSend()}>
            发送
          </Button>
          <div style={{minHeight: '200px',maxHeight: '300px', overflowY: 'auto', padding: '10px', border: '1px solid #a3a1a166'}}>
            {!msgList.length && '无数据'}
            {msgList.map((item, idx) => (
              <div key={idx} style={{marginBottom: '5px'}}>
                <div style={{color: 'rgb(154, 154, 154)'}}>{item.createTime}</div>
                <div className="flex-start">
                  <div style={{color: item.type === 1 ? 'rgb(154, 154, 154)' : '#f94a3d'}}>{item.type === 1 ? '商家' : '客服'}：</div>
                  <div style={{maxWidth: '370px', color: 'black'}} title={item.content}>{item.content}</div>
                  { item.type === 1 ? (
                    <div><DeleteOutlined style={{color: '#898e99', marginLeft: '3px', cursor: 'pointer'}} title="删除" onClick={() => this.handleDelMsg(item)}/></div>
                  ):''}
                </div>
              </div>
            ))}
          </div>
        </Modal>

        <Modal
          title={this.content(courierSn)}
          visible={isShowInfo}
          centered
          width="660px"
          onCancel={() => this.setState({ isShowInfo: false, courierInfo: [] })}
          footer={null}
          destroyOnClose
        >
          <div>
            {courierInfo.length > 0 ? (
              <div style={{ height: 600, overflowY: "scroll" }}>
                {courierInfo.map((item, idx) => (
                  <div
                    key={idx}
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      minHeight: 90,
                    }}
                  >
                    <div className="courier_line"></div>
                    <div>
                      <div style={{ color: "#000000" }}>{item.status}</div>
                      <div>{item.time}</div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div style={{ textAlign: "center", fontSize: 16 }}>
                暂无物流信息，请稍后查询
              </div>
            )}
            <div style={{ marginTop: 20, textAlign: "right" }}>
              <Button
                type="primary"
                onClick={() =>
                  this.setState({ isShowInfo: false, courierInfo: [] })
                }
              >
                关闭
              </Button>
            </div>
          </div>
        </Modal>

        {/* 查看处理结果 */}
        <Modal
          title="处理意见"
          visible={isShowOrderResult}
          width="400px"
          onCancel={(_) =>
            this.setState({
              isShowOrderResult: false,
              replay: "",
            })
          }
          footer={null}>
          <div style={{minHeight: '100px', maxHeight: '300px', overflowY: 'auto'}}>
            <div>
              处理意见：{replay}
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
export default Search;
