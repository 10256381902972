var android = {
  is(ua) {
    return !!ua.match(/android/gi)
  },
  version(ua) {
    const reg = /android.*?;/gi
    const str = reg.exec(ua)[0]
    return str
      .replace(/android/gi, '')
      .replace(/;/g, '')
      .trim()
  },
}

const arr = ['iphone', 'ipad', 'macintosh']
const len = arr.length

var ios = {
  is(ua) {
    for (let i = 0; i < len; i++) {
      let reg = new RegExp(arr[i], 'gi')
      let match = ua.match(reg)

      if (match) {
        return !!match
      }
    }

    return false
  },
  version(ua) {
    for (let i = 0; i < len; i++) {
      let reg = new RegExp(arr[i] + ';\\s.*?\\)', 'gi')
      let match = reg.exec(ua)

      if (match) {
        let str = match[0]
        let numReg = /\d[\d|_]*/.exec(str)

        return numReg[0].replace(/_/g, '.')
      }
    }
  },
}

const oses = {
  android,
  ios,
}

const info = {
  name: null,
  version: null,
}

let flag = false

const os = {
  detect() {
    if (!flag) {
      os.get = function () {
        return info
      }

      for (let name in oses) {
        const obj = oses[name]
        const ua = window.navigator.userAgent

        if (obj.is(ua)) {
          info.name = name
          info.version = obj.version(ua)
          break
        }
      }

      return info
    }
  },
}

module.exports = os.detect()
