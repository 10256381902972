/* 财务管理-资金明细 */
import React, { Component } from "react";
import {Table, message, Radio, DatePicker, Button} from "antd";
import moment from "moment";
import {API, ROOT} from "../../../static/js/common";
import {CopyToClipboard} from "react-copy-to-clipboard";

const { RangePicker } = DatePicker;

class Detail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      pageSize: 10,
      type: 0, // 0全部 1充值 2支出
      finance_data: {},
      createStart: "",
      createEnd: "",
    };
  }

  componentDidMount() {
    this.getFinanceList();
  }

  // 资金记录
  getFinanceList = () => {
    const { page, pageSize, type,  createStart, createEnd, } = this.state;
    const reqData = { page, pageSize };
    if (createStart && createEnd) {
      reqData.createStart = createStart;
      reqData.createEnd = createEnd;
    }
    if (type !== 12) {
      reqData.type = type;
      API.getFinanceList(reqData).then((res) => {
        if (res.status * 1 === 0) {
          this.setState({ finance_data: res.extData || {} });
        } else {
          message.error(res.message);
        }
      });
    } else {
      API.getShareList(reqData).then((res) => {
        if (res.status * 1 === 0) {
          this.setState({ finance_data: res.extData || {} });
        } else {
          message.error(res.message);
        }
      });
    }
  };

  // 选择日期
  changeTime = (e) => {
    let { createStart, createEnd } = this.state;
    if (e) {
      createStart = moment(e[0]).format("YYYY-MM-DD HH:mm");
      createEnd = moment(e[1]).format("YYYY-MM-DD HH:mm");
    } else {
      createStart = "";
      createEnd = "";
    }
    this.setState({ createStart, createEnd },(_) => this.getFinanceList());
  };

  handleDownload = () => {
    const {
      type,
      createStart,
      createEnd,
    } = this.state;
    let url = "";
    if (createStart && createEnd) {
      url = `&createStart=${createStart}&createEnd=${createEnd}`;
    }
    const token = window.localStorage.getItem("slpToken") || "";
    window.open(
      `${ROOT}/export/finance?_t=${new Date().valueOf()}&token=${token}&type=${type}${url}`
    );
  };

  render() {
    const { page, pageSize, type, finance_data } = this.state;

    const columns = [
      {
        title: "交易单号",
        dataIndex: "logSn",
        align: "center",
      },
      {
        title: "类型",
        dataIndex: "type",
        align: "center",
        render: (e, record) => {
          return (
            <div>
              {type === 12
                ? e * 1 === 1
                  ? "提现"
                  : "推广分成"
                : record.changeAmount * 1 > 0
                ? "充值"
                : "付款"}
            </div>
          );
        },
      },
      {
        title: "收支",
        dataIndex: `${type === 12 ? "changeCommission" : "changeAmount"}`,
        align: "center",
        render: (e) => {
          return <div>￥{type === 12 ? e : (e / 100).toFixed(2)}</div>;
        },
      },
      {
        title: `${type === 12 ? "分成余额" : "可用余额"}`,
        dataIndex: `${type === 12 ? "remainCommission" : "afterAmount"}`,
        align: "center",
        render: (e) => {
          return <div>￥{type === 12 ? e : (e / 100).toFixed(2)}</div>;
        },
      },
      {
        title: "时间",
        dataIndex: "createTime",
        align: "center",
      },
      {
        title: "备注",
        dataIndex: `${type === 12 ? "buyInfo" : "remark"}`,
        align: "center",
        render: (e, record) => {
          return <CopyToClipboard
            text={record.otherSn}
            onCopy={() => record.remark.includes("订单取消") ? message.success("成功复制平台编号") : (record.remark.includes("支付订单") ? message.success("成功复制订单批次号") : '')}
          >
            <div style={{ cursor: "pointer" }}>{e}{record.remark === '订单取消' ? record.otherSn : ''}</div>
          </CopyToClipboard>
        },
      },
    ];

    return (
      <div>
        <div style={{ fontSize: 16, fontWeight: 600, marginBottom: 20 }}>
          资金记录
        </div>
        <div className="flex-space" style={{ marginBottom: 20 }}>
          <Radio.Group
            value={type}
            buttonStyle="solid"
            onChange={(e) =>
              this.setState({ type: e.target.value, page: 1 }, (_) =>
                this.getFinanceList()
              )
            }
          >
            <Radio.Button value={0}>全部</Radio.Button>
            <Radio.Button value={1}>充值记录</Radio.Button>
            <Radio.Button value={2}>付款记录</Radio.Button>
            <Radio.Button value={11}>提现记录</Radio.Button>
            <Radio.Button value={12}>分成记录</Radio.Button>
          </Radio.Group>
          <div>
            <span style={{ marginRight: 10 }}>创建时间</span>
            <RangePicker onChange={(e) => this.changeTime(e)} showTime={{ format: 'HH:mm' }} format="YYYY-MM-DD HH:mm"/>
            {type !== 12 && (<Button
              type="primary"
              onClick={this.handleDownload}
              style={{ marginLeft: 12 }}>
              导出
            </Button>)}
          </div>
        </div>
        <Table
          columns={columns}
          dataSource={finance_data.list || []}
          rowKey="logSn"
          pagination={{
            current: page,
            pageSize,
            total: finance_data.total * 1 || 0,
            showSizeChanger: false,
            onChange: (page) => {
              this.setState({ page }, (_) => {
                this.getFinanceList();
              });
            },
          }}
        />
      </div>
    );
  }
}
export default Detail;
